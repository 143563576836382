

export type OrderType = 'asc' | 'desc';

export const ROWS_PER_PAGE = [
    // 1, 2, 5, // dlya testa
    8, 16, 24,
]

export const DOWNLOAD_ARCHIVE_LENGTH = 2 // 3

export const filtersActsTypes = [
    {
        label: 'filters.act_type_1',
        value: 1
    },
    {
        label: 'filters.act_type_2',
        value: 2
    },
    {
        label: 'filters.act_type_3',
        value: 3
    },
    {
        label: 'filters.act_type_4',
        value: 4
    },
]

export const filtersInvoicesTypes = [
    {
        label: 'filters.invoice_plan',
        value: 1
    },
    {
        label: 'filters.invoice_final',
        value: 2
    },
    {
        label: 'filters.invoice_additional',
        value: 13
    },
]
export const filtersElBalances = [
    {
        label: 'filters.balance_1',
        value: 1
    },
    {
        label: 'filters.balance_2',
        value: 2
    },
]
export const filtersGasBalances = [
    {
        label: 'filters.balance_3',
        value: 3
    },
    {
        label: 'filters.balance_4',
        value: 4
    },
    {
        label: 'filters.balance_5',
        value: 5
    },
    {
        label: 'filters.balance_5',
        value: 5
    },
]
export const filtersElActionTypes = [
    {
        label: 'filters.action_1',
        value: 1
    },
    {
        label: 'filters.action_2',
        value: 2
    },
    {
        label: 'filters.action_3',
        value: 3
    },
    {
        label: 'filters.action_4',
        value: 4
    },
    {
        label: 'filters.action_5',
        value: 5
    },
    {
        label: 'filters.action_6',
        value: 6
    },
    {
        label: 'filters.action_7',
        value: 7
    },
    {
        label: 'filters.action_8',
        value: 8
    },
    {
        label: 'filters.action_9',
        value: 9
    },
]
export const filtersPaymentsRowTypes = [
    {
        label: 'filters.row_type_1',
        value: 1
    },
    {
        label: 'filters.row_type_2',
        value: 2
    },
    {
        label: 'filters.row_type_3',
        value: 3
    }
]

export const getBalancesByService = (service_id: any) => (
    service_id === 1 ? filtersElBalances : service_id === 2 ? filtersGasBalances : []
)