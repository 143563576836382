import React from 'react'
import Header from '../components/header/Header'

type Props = {
    children: string | JSX.Element | JSX.Element[]
}

const LayoutPrivate = ({ children }: Props) => {
    return (
        // flex flex-col h-full
        <div>
            <Header />
            <main>
                {children}
            </main>
        </div>
    )
}

export default LayoutPrivate
