import { Spinner } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '../../../images/icons/SearchIcon'

const DELAY = 500 // врямя отложенного поиска



const FilterSearch = ({ onSearch }: { onSearch: any }) => {
    const { t } = useTranslation()
    const [debouncedSearch, setDebouncedSearch] = useState(""); // отложенное значение инпута

    const [searchValue, setSearchValue] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true)
        const timeoutId = setTimeout(() => {
            setDebouncedSearch(searchValue);
            setIsLoading(false)
        }, DELAY);
        return () => clearTimeout(timeoutId);
    }, [searchValue, DELAY]);


    useEffect(() => {
        onSearch(debouncedSearch)
    }, [debouncedSearch]);

    return (

        <div className='relative'>
            <input
                value={searchValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                className='border-2 border-gray-200 rounded w-full tab:w-[240px] h-[50px] px-[16px] text-gray-700
                    hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                    focus:bg-white focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                '
                placeholder={t('filters.search_input')}
            />
            <div className='absolute right-[16px] top-[50%] translate-y-[-50%] '>
                {isLoading ? <Spinner /> : <SearchIcon />}
            </div>
        </div>
    )
}

export default FilterSearch
