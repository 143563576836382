import React, { Fragment, useState } from 'react'
import { InvoiceRowType } from '../InvoicePage.types'
import { isIterableArray, toFormatPrice } from '../../../utils/utils'
import TableEmptyRows from '../../tableHelpers/TableEmptyRows'
import { Button, IconButton, Spinner, Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import MoreDotsIcon from '../../../images/icons/MoreDotsIcon'
import { Checkbox, Divider } from '@mui/material'
import moment from 'moment'
import DrawerCard from '../../../components/mobile/DrawerCard'
import { NavLink } from 'react-router-dom'
import DownloadBtn from '../../tableHelpers/buttons/DownloadBtn'
import DownloadIcon from '../../../images/icons/DownloadIcon'
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon'
import CopyButton from '../../tableHelpers/buttons/CopyButton'
import ArrowTopRight from '../../../images/icons/ArrowTopRight'

type propType = {
    visibleRows: InvoiceRowType[],
    handleClickCheckbox: any,
    isSelected: any,
    isLoadingTable: boolean,
    handleDownloadFile: any
}
const InvoicesCards = ({
    visibleRows,
    handleClickCheckbox,
    isSelected,
    isLoadingTable,
    handleDownloadFile
}: propType) => {

    const { t } = useTranslation()

    const [isOpenCard, setIsOpenCard] = useState<boolean>(false);
    const [modalValue, setModalValue] = useState<InvoiceRowType | null>(null);

    const closeDrawer = () => {
        setIsOpenCard(false)
        setModalValue(null)
    }
    const openDrawer = (row: InvoiceRowType) => {
        setIsOpenCard(true)
        setModalValue(row)
    }

    const dateParser = (date: string) => {
        const month = moment(date, 'MM-YYYY').format('MM')
        const year = moment(date, 'MM-YYYY').format('YYYY')
        return t(`month_${month}`) + ' ' + year
    }

    return (
        <div className='tab:hidden pb-[100px]'>
            <DrawerCard
                isOpen={isOpenCard}
                closeDrawer={closeDrawer}
            // modalValue={modalValue}
            >
                {
                    modalValue &&
                    <Fragment>
                        <div className="mb-6 flex items-center justify-between">
                            {
                                modalValue &&
                                <Typography variant='lead' className='text-[16px] font-bold text-gray-800'>
                                    {t('invoices_page.history.invoice_title')}: {modalValue.number}
                                </Typography>
                            }
                            <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={closeDrawer}>
                                <CloseDrawerIcon />
                            </IconButton>
                        </div>
                        <div className='flex flex-col gap-[10px] mb-6'>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.invoice_type`)}</span>
                                <span className='text-[14px] font-medium'> {t(`invoices_page.invoice_type_${modalValue.invoice_type}`)}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.balance_id`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.balance_id_table}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.period`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.period}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.date_payment`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.date_payment}</span>
                            </div>
                            <hr className='border-gray-100 my-3' />
                            <div className="grid grid-cols-2 mb-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.amount_with_nds`)}</span>
                                <span className='text-[16px] font-bold'> {toFormatPrice(Number(modalValue.amount_with_nds))}</span>
                            </div>
                            <div className="grid grid-cols-2 mb-2">
                                <span className='text-[14px] font-medium'> {t(`invoices_page.amount_for_payment`)}</span>
                                <span className='text-[16px] font-bold'> {toFormatPrice(Number(modalValue.amount_for_payment))}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='flex items-end gap-[4px] text-[14px] font-medium'>
                                    {t(`invoices_page.purpose`)}
                                    {
                                        modalValue?.purpose &&
                                        <CopyButton
                                            copyText={modalValue?.purpose || ''}
                                        />
                                    }
                                </span>
                                <span className='text-[12px] font-gray-600'> {modalValue.purpose}</span>
                            </div>
                        </div>
                        <div className='flex flex-col gap-[10px] my-6'>
                            <NavLink
                                to={`/invoice-history/${modalValue.id}`}
                                className='block'
                            >
                                <Button size='sm' color='white' className='w-full !rounded-full min-h-[50px] flex gap-[12px] items-center justify-center'>
                                    <ArrowTopRight className={` 
                                easy duration-300 transitions-all
                                w-[13px] h-[13px]
                                `}
                                    />
                                    {t('invoices_page.payment_history')}
                                </Button>
                            </NavLink>
                            <DownloadBtn
                                btnClass={'w-full !rounded-full min-h-[50px]'}
                                icon={<DownloadIcon />}
                                tooltipText={t('invoices_page.download_file')}
                                record={modalValue}
                                handleDownloadFile={handleDownloadFile}
                            />
                        </div>
                    </Fragment>
                }
            </DrawerCard>
            {
                isIterableArray(visibleRows) ?
                    visibleRows.map((item: InvoiceRowType, key: number) => (
                        <Fragment key={key}>

                            {
                                item.period !== visibleRows?.[key - 1]?.period &&
                                <Typography variant='lead' className='text-[14px] font-bold text-gray-800 mb-[6px] mt-[10px]'>
                                    {dateParser(item.period)}
                                </Typography>
                            }
                            <div onClick={() => openDrawer(item)} className="bg-white active:bg-gray-50 transition-all rounded border-2 border-gray-100 p-[12px] mb-[6px] animate-appear">
                                <div className='w-full flex flex-wrap items-center justify-between gap-[4px] mb-[8px]'>
                                    <Typography variant='lead' className='text-[14px] font-bold text-gray-800'>
                                        {t(`invoices_page.invoice_type_${item.invoice_type}`)}: {item.number}
                                    </Typography>
                                    <span className='text-[12px] p-[4px_8px] rounded-full bg-gray-50  font-bold'>{t(`balance_${item.balance_id}`)}</span>
                                    {/* <Checkbox
                                        disableRipple={true}
                                        color="default"
                                        className='svg-checkbox-dark hover:!bg-white'
                                        checked={isSelected(item.id)}
                                        onClick={(event: any) => {
                                            event.stopPropagation()
                                            handleClickCheckbox(event, item.id)
                                        }}
                                    /> */}
                                </div>
                                <div className='flex flex-wrap items-center justify-between gap-[4px]'>
                                    <div className="grid grid-cols-1">
                                        {/* <span className='text-[14px] font-medium'> {t(`invoices_page.amount_with_nds`)}</span> */}
                                        <span className='text-[16px] font-bold'> {toFormatPrice(Number(item.amount_with_nds))}</span>
                                    </div>
                                    <span className='whitespace-nowrap text-[12px] font-medium'>{t('invoices_page.date_payment')} {item.date_payment}</span>
                                </div>
                            </div>
                        </Fragment>
                    ))
                    :
                    isLoadingTable ?
                        <Spinner className='my-6 mx-auto'/>
                        :
                        <Typography variant='lead' className='text-[20px] font-medium text-center p-6 '>
                            {t('tables.nothing_to_show')}
                        </Typography>
            }
        </div>
    )
}

export default InvoicesCards