import { Button, Drawer, IconButton, Select, Option, Typography, Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react';
import { useEffect, useState } from 'react'
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import SelectIcon from '../../images/icons/SelectIcon';
import { useRequestGetContractsViewQuery } from '../../store/auth/authService';
import { setServiceId } from '../../store/service/contractSlice';
import { isIterableArray } from '../../utils/utils';
import { AppealType, ContractsArrType } from './types';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import SelectContract from './SelectContract';
import SelectService from './SelectService';
import axios from 'axios';
import { baseApi } from '../../ConfigApi';
import { errorToast, successToast } from '../../components/toasts/toasts';

const ViewAppeal = ({ open, handleClose, modalValue }: {
    open: boolean,
    handleClose: () => void,
    modalValue: AppealType | null
}) => {

    const { t } = useTranslation()
    return (
        <Drawer
            open={open}
            onClose={handleClose}
            className={`p-4 ${!open && '!left-0'}`}
            size={640}
        >
            <div className="mb-6 flex items-center justify-between">
                <Typography variant="lead" className='text-[24px] font-medium' >
                    {t('support.support_item_title')} {modalValue?.id ? ` №${modalValue.id}` : ''}
                </Typography>
                <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={handleClose}>
                    <CloseDrawerIcon />
                </IconButton>
            </div>
            {
                modalValue &&
                <div className="flex flex-col gap-[24px]">
                    <div className="bg-white rounded border-2 border-gray-100 grid grid-cols-2 gap-[24px] p-[24px]">
                        <div className="flex flex-col gap-[8px] border-r-1 border-gray-500">
                            <Typography variant='lead' className='text-gray-700 text-[14px]'>
                                {t('support.select_contract')}
                            </Typography>

                            <Typography variant='lead' className='text-gray-800 font-normal text-[16px]'>
                                {modalValue.or_number ? `#${modalValue.or_number}` : ''}
                            </Typography>

                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <Typography variant='lead' className='text-gray-700 text-[14px]'>
                                {t('support.select_service')}
                            </Typography>
                            <Typography variant='lead' className='text-gray-800 font-normal text-[16px]'>
                                {
                                    modalValue.service_id === 1 ? t('mainpage.electro_title')
                                        :
                                        modalValue.service_id === 2 ? t('mainpage.gas_title')
                                            :
                                            t('support.select_service')
                                }
                            </Typography>
                        </div>
                    </div>
                    <div className="bg-white rounded border-2 border-gray-100 p-[24px] flex flex-col gap-[16px]">
                        <div className="flex flex-col gap-[8px]">
                            <label>
                                {t('support.theme_input_label')}
                            </label>
                            <Typography variant='lead' className='text-gray-700 text-[14px]'>
                                {
                                    modalValue.theme ?
                                        modalValue.theme
                                        :
                                        ' - '
                                }
                            </Typography>
                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <label htmlFor='textInput'>
                                {t('support.text_input_label')}
                            </label>
                            <Typography variant='lead' className='text-gray-700 text-[14px]'>
                                {
                                    modalValue.text ?
                                        modalValue.text
                                        :
                                        ' - '
                                }
                            </Typography>
                        </div>
                    </div>

                    <div className='flex items-center justify-end'>
                        <Button color='white' onClick={handleClose}>
                            {t('close')}
                        </Button>
                    </div>
                </div>
            }
        </Drawer>
    )
}

export default ViewAppeal