import { Button, Typography } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import ArrowAccordionIcon from '../../images/icons/ArrowAccordionIcon'
import { NO_OVERLAY_CLASS } from '../../layouts/pagesLayouts/OverlayUnTouchable'
import { useGetContractPeriodQuery } from '../../store/auth/authService'
import { isIterableArray } from '../../utils/utils'
import FilterDateItem from '../tableHelpers/filters/FilterDateItem'
import FilterItem from '../tableHelpers/filters/FilterItem'
import FilterPeriod from '../tableHelpers/filters/FilterPeriod'
import FilterTkos from './FilterTkos'
import moment from 'moment'

// export const defaulPeriodFrom = '05-2023'
// export const defaulPeriodTo = '07-2023'
type ConsFormInput = {
    periodFrom: string,
    periodTo: string,
    tko: number[]
}

const Filters = (props: {
    value: ConsFormInput,
    isLoadingFetch: boolean,
    fetchPage: any,
    listTko: any,
    // defaulPeriodFrom: string,
    // defaulPeriodTo: string,
}) => {
    const {
        value,
        isLoadingFetch,
        fetchPage,
        listTko,
        // defaulPeriodFrom,
        // defaulPeriodTo
    } = props

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { pathname, search } = useLocation()
    const { param_contract_id } = useParams()

    const { data } = useGetContractPeriodQuery({
        contract_id: param_contract_id,
        endOffset: 3
    })
    // const defaultState: ConsFormInput = {
    //     periodFrom: defaulPeriodFrom,
    //     periodTo: defaulPeriodTo,
    //     tko: [],
    // }
    const {
        control,
        getValues,
        setValue,
    } = useForm<ConsFormInput>({
        defaultValues: {},
    })

    useEffect(() => {
        // setValue({
        //     periodFrom: value.periodFrom,
        //     periodTo: value.periodTo,
        //     tko: value.tko,
        // })
        setValue('periodFrom', value.periodFrom)
        setValue('periodTo', value.periodTo)
        setValue('tko', value.tko)

    }, [value]);


    const [filtersState, setFiltersState] = useState<ConsFormInput>(value);

    const handleCloseFilter = () => {
        const values = getValues()
        setFiltersState(values)
        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }
    // const allTkoData = useConsumptionViewAllTkoQuery({
    //     contract_id: param_contract_id,
    // })

    const [computedLink, setComputedLink] = useState<string | null>(null);


    useEffect(() => {
        const getHref = (): string | null => {
            const tkos = isIterableArray(filtersState.tko) ? filtersState.tko.join(',')
                :
                isIterableArray(listTko) ? listTko.map((i: any) => i.value).join(',')
                    :
                    false
            if (tkos) {
                const href = `/consumptions/${param_contract_id}/create-declared/` + '?&from=' + filtersState.periodFrom + '&to=' + filtersState.periodTo + '&tkos=' + tkos
                return href
            }
            return null
        }
        const href = getHref()
        setComputedLink(href)
    }, [filtersState, listTko, param_contract_id]);

    return (
        <div className='
            flex justify-between items-center mb-[32px] mt-[12px]
        '>
            <div className='flex items-center gap-[8px]'>
                <Typography className='text-gray-700'>
                    {t('filters.period')}
                </Typography>
                <FilterDateItem
                    isLoadingFetch={isLoadingFetch || !value.periodFrom}
                    placement={'bottom-start'}
                    control={control}
                    fieldName={'periodFrom'}
                    label={t("filters.period_from")}
                    datesData={data}
                    handleCloseFilter={handleCloseFilter}
                    isDisabled={(date: string) => {
                        return moment(value.periodTo, 'MM-YYYY').isBefore(moment(date, 'MM-YYYY'))
                    }}
                />
                <span className='rotate-90'>
                    <ArrowAccordionIcon />
                </span>
                <FilterDateItem
                    isLoadingFetch={isLoadingFetch || !value.periodTo}
                    placement={'bottom-end'}
                    control={control}
                    fieldName={'periodTo'}
                    label={t("filters.period_to")}
                    datesData={data}
                    handleCloseFilter={handleCloseFilter}
                    isDisabled={(date: string) => {
                        return moment(value.periodFrom, 'MM-YYYY').isAfter(moment(date, 'MM-YYYY'))
                    }}
                />
            </div>
            <div className='flex items-normal gap-[4px]'>
                <FilterItem
                    isLoadingFetch={isLoadingFetch}
                    control={control}
                    label={t('filters.all_tkos')}
                    fieldName={'tko'}
                    defaultValue={value.tko}
                    filtersData={listTko}
                    handleCloseFilter={handleCloseFilter}
                />
                {/* <FilterTkos
                    isLoadingFetch={isLoadingFetch}
                    control={control}
                    defaultState={defaultState.tko}
                    handleCloseFilter={handleCloseFilter}
                /> */}
                <NavLink state={{
                    pathname, search
                }}
                    to={computedLink ? computedLink : '#'}>
                    <Button
                        disabled={!computedLink}
                        color='black'
                        className={`${NO_OVERLAY_CLASS}`}
                    // onClick={() => {
                    //     if (computedLink) navigate(computedLink)
                    // }}
                    >
                        {t('consumption.create_consumption')}
                    </Button>
                </NavLink>
            </div>
        </div>
    )
}

export default Filters