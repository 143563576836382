import { IconProps } from "./Arrow"

const CloseDrawerIcon = (props: IconProps) => {
    return (
        <svg
            className={`h-5 w-5 ${props.className ? props.className : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
        >
            <path
                className={`stroke-gray-700 ${props.className ? props.className : ''}`}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
            />
        </svg>
    )
}

export default CloseDrawerIcon