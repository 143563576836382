import { Typography } from '@material-tailwind/react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import OverlayUnTouchable from '../../layouts/pagesLayouts/OverlayUnTouchable'
import PageLayout from '../../layouts/pagesLayouts/PageLayout'
import { useConsumptionViewAllTkoQuery, useConsumptionViewQuery } from '../../store/auth/authService'
import { setContractId, setServiceId } from '../../store/service/contractSlice'
import { AppDispatch } from '../../store/store'
import { isIterableArray } from '../../utils/utils'
import Filters from './Filters'
import SkeletonCons from './SkeletonCons'
import TkosConsumption from './TkosConsumption'
import { ConsunptionFormType } from './_types'
import { Helmet } from 'react-helmet'
import TableLayout from '../../layouts/pagesLayouts/TableLayout'

// export const defaulPeriodFrom = '05-2023'
// export const defaulPeriodTo = '07-2023'



const ConsumptionsPage = () => {

    const { t } = useTranslation()
    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()

    let [searchParams, setSearchParams] = useSearchParams();

    const from_param = searchParams.get('from')
    const to_param = searchParams.get('to')
    const tkos_param = searchParams.get('tkos')
    const tkosParamArr = tkos_param ? tkos_param.split(',').map(i => parseInt(i)) : []


    const defaulPeriodFrom = from_param ? from_param : moment().add(-2, 'month').format('MM-YYYY')
    const defaulPeriodTo = to_param ? to_param : moment().format('MM-YYYY')

    const form: ConsunptionFormType = {
        contract_id: param_contract_id || '',
        periodFrom: defaulPeriodFrom,
        periodTo: defaulPeriodTo,
        // month_from: defaultMonthFrom,
        // year_from: defaultYearFrom,
        // month_to: defaultMonthTo,
        // year_to: defaultYearTo,
        // month_from: '05',
        // year_from: 2023,
        // month_to: '07',
        // year_to: 2023,
        // tko: [],
        tko: isIterableArray(tkosParamArr) ? tkosParamArr : [],
        // tko: [1, 2, 3, 40, 41, 43, 48],

    }

    const [sendForm, setSendForm] = useState<ConsunptionFormType>(form);

    const dispatch = useDispatch<AppDispatch>()

    const { error, isLoading, isFetching, refetch, currentData } = useConsumptionViewQuery(sendForm)


    const listTkoQuery = useConsumptionViewAllTkoQuery({
        contract_id: param_contract_id
    })

    const tkosViewportRef = useRef<HTMLInputElement>(null) // контейнер со всеми тко, для определения высоты всех тко
    const [isBottomFilters, setIsBottomFilters] = useState<boolean>(false) // показывать нижние фильтры 


    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(true);

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (currentData?.service_id) dispatch(setServiceId(Number(currentData.service_id)))
    }, [currentData]);

    // console.log(isLoading, isFetching, isLoadingTable)

    useEffect(() => {
        if (!isLoading && !isFetching && isLoadingTable) {
            setIsLoadingTable(false)
        }
    }, [isLoading, isFetching, isLoadingTable]);

    const setFormForFetch = (values: any) => {
        setIsLoadingTable(true)
        setIsBottomFilters(false)
        setSendForm(prev => ({
            ...prev, ...{
                ...values,
                contract_id: param_contract_id
            }
        }))
        if (values) {
            const tkosParamArr = values.tko.join(',')
            const from = values.periodFrom
            const to = values.periodTo
            setSearchParams({
                from: from,
                to: to,
                tkos: tkosParamArr,
            })
        }
    }


    useEffect(() => {
        if (tkosViewportRef.current && tkosViewportRef.current.clientHeight) {
            const tkosHeight = tkosViewportRef?.current ? tkosViewportRef.current.clientHeight : 0
            const vieportHeight = window.innerHeight

            if (tkosHeight > vieportHeight * 1.5) {
                setIsBottomFilters(true)
            }
            else setIsBottomFilters(false)
        }
        else setIsBottomFilters(false)
    }, [currentData?.tkos]);

    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[64px]'>
                {t('consumption.consumption_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('consumption.consumption_title')}
                </title>
            </Helmet>
            <Filters
                listTko={listTkoQuery?.data}
                value={{
                    periodFrom: sendForm.periodFrom,
                    periodTo: sendForm.periodTo,
                    tko: sendForm.tko
                }}
                isLoadingFetch={isFetching}
                fetchPage={setFormForFetch}
            />
            <OverlayUnTouchable isOverlay={(isFetching || isLoading || !!error)}>
                <div ref={tkosViewportRef} className='tkos-viewport'>

                    {
                        <>
                            {

                                currentData ?
                                    <>
                                        <TkosConsumption data={currentData} isFetching={isFetching} refetch={refetch} refetchTkoList={listTkoQuery.refetch} />
                                    </>
                                    :
                                    isFetching ?
                                        <SkeletonCons />
                                        :
                                        error ?
                                            <TableLayout>
                                                <Typography variant='lead' className='text-[20px] font-medium text-center my-[40px]'>
                                                    {t('tables.error_on_loading')}
                                                </Typography>
                                            </TableLayout>
                                            :
                                            ''
                            }
                        </>
                    }
                </div>
                {
                    isBottomFilters && !isFetching &&
                    <Filters
                        listTko={listTkoQuery?.data}
                        value={{
                            periodFrom: sendForm.periodFrom,
                            periodTo: sendForm.periodTo,
                            tko: sendForm.tko
                        }}
                        isLoadingFetch={isLoadingTable}
                        fetchPage={setFormForFetch}
                    />
                }
            </OverlayUnTouchable>
        </PageLayout>
    )
}

export default ConsumptionsPage