import { Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import ModalEditDeclared from './ModalEditDeclared'
import PeriodsTkos from './PeriodsTkos'

const ItemLayout = ({ tkoData, isSummary, isFetching, handleEditDeclared, children }: any) => {

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const serviceBorder = isSummary ? (service_id === 1 ? 'border-orange-500 border-[3px]' : service_id === 2 ? 'border-blue-600 border-[3px]' : 'border-gray-100 border-2') : 'border-gray-100 border-2'
    // const serviceTitleText = service_id === 1 ? 'text-orange-500' : service_id === 2 ? 'text-blue-600' : 'text-gray-100'



    return (

        <div className={`bg-white ${serviceBorder} p-[48px] rounded-[24px] mb-[24px] animate-appear`}>
            <div className='grid grid-cols-[38%_62%]'>
                <div>
                    {children}
                </div>
                <div>
                    <PeriodsTkos
                        tkoDetailing={tkoData?.tko?.detailing}
                        data={tkoData?.data}
                        isFetching={isFetching}
                        handleEditDeclared={handleEditDeclared}
                    />
                </div>
            </div>
        </div>
    )
}

export default ItemLayout