import usePagination from '@mui/material/usePagination';
import { Button } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import ArrowAccordionIcon from '../../images/icons/ArrowAccordionIcon';

export default function PaginationEl({ count, page, onChange }:
    { count: number, page: number, onChange: any }) {

    const { t } = useTranslation()

    const { items } = usePagination({
        count: count,
        page: page,
        onChange: onChange,

        siblingCount: 3,
        boundaryCount: 3
    });

    return (
        <nav>
            <div className='flex'>
                {items.map(({ page, type, selected, ...item }, index) => {
                    let children = null;
                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = '…';
                    } else if (type === 'page') {
                        children = (
                            <Button
                                color='white'
                                className={`w-[40px] h-[40px] px-0 flex items-center justify-center rounded  mx-[2px] text-gray-700 font-normal text-[14px] ${selected ? '!bg-gray-800 border-gray-800 text-white cursor-default ' : ''}`}
                                // style={{
                                //     fontWeight: selected ? 'bold' : undefined,
                                // }}
                                {...item}
                            >
                                {page}
                            </Button>
                        );
                    } else {
                        children = (
                            <Button
                                color='white'
                                className={`h-[40px] px-[16px] flex items-center justify-center gap-[8px] rounded text-gray-700 font-normal text-[14px] disabled:bg-white disabled:text-gray-500 ${selected ? '!bg-gray-800 border-gray-800 text-white cursor-default ' : ''}`}
                                {...item}
                            >
                                {type === 'previous' && <span className='rotate-[-90deg] '><ArrowAccordionIcon width={10} /></span>}
                                {t(`pagination.${type}`)}
                                {type === 'next' && <span className='rotate-[90deg] '><ArrowAccordionIcon width={12} /></span>}
                            </Button>
                        );
                    }

                    return <div key={index}>{children}</div>;
                })}
            </div>
        </nav>
    );
}