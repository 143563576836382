import React from 'react'
import { isIterableArray } from '../../utils/utils'
import { Link, NavLink } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import { topMenu } from '../../menu/topMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const Menu = () => {
    const { t } = useTranslation()
    const { contract_id, service_id } = useSelector((state: RootState) => state.contract)

    const textService = service_id === 1 ? 'hover:text-orange-500 after:bg-orange-500 ' : service_id === 2 ? ' hover:text-blue-600 after:bg-blue-600 ' : ' hover:text-gray-800 after:bg-gray-800 '
    return (
        <div className='flex items-end gap-[30px] '>
            {
                Object.keys(topMenu).map((key) => {
                    const link = topMenu[key]
                    return (
                        <NavLink
                            className={'group font-bold text-gray-700 easy duration-300 transitions-all  hover:text-gray-700'}
                            to={link.menuUrl + '/' + contract_id}
                            key={key}
                        >
                            {
                                ({ isActive }) => (
                                    isActive ?
                                        <span className='block pb-[16px] pt-[6px] text-black border-b-4 border-gray-800 cursor-default'>
                                            {t(link.title)}
                                        </span>
                                        :
                                        <span className={`
                                        relative block pb-[20px] pt-[6px]
                                         transition-all 
                                        group-hover:after:opacity-100 group-hover:after:bottom-0 
                                        after:absolute after:bottom-[-4px] after:left-0 after:right-0 after:h-[4px] after:opacity-0 after:transition-all after:duration-3000
                                        ${textService}
                                        `}>
                                            {t(link.title)}
                                        </span>
                                )
                            }
                        </NavLink>
                    )
                })
            }
        </div>
    )
}

export default Menu
