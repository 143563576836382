import { Button, Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterDateItem from '../../tableHelpers/filters/FilterDateItem'
import { NO_OVERLAY_CLASS } from '../../../layouts/pagesLayouts/OverlayUnTouchable'
import ArrowAccordionIcon from '../../../images/icons/ArrowAccordionIcon'
import { useGetContractPeriodQuery } from '../../../store/auth/authService'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import moment from 'moment'


const defaulPeriodFrom = '05-2023'
const defaulPeriodTo = '07-2023'

type ConsFormInput = {
    periodFrom: string,
    periodTo: string,
}

const Filters = (props: {
    value: ConsFormInput,
    isLoadingFetch: boolean,
    isLoadingSave: boolean,
    fetchPage: any,
    onSubmit: any,
    handleNavigateBack: any
}) => {
    const {
        value,
        isLoadingFetch,
        fetchPage,
        onSubmit,
        isLoadingSave,
        handleNavigateBack
    } = props

    const { t } = useTranslation()

    const navigate = useNavigate()

    let [searchParams, setSearchParams] = useSearchParams();

    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()
    const from_param = searchParams.get('from')
    const to_param = searchParams.get('to')
    const tkos_param = searchParams.get('tkos')

    const { data } = useGetContractPeriodQuery({
        contract_id: param_contract_id,
        endOffset: 3
    })

    const defaultState: ConsFormInput = {
        periodFrom: from_param || defaulPeriodFrom,
        periodTo: to_param || defaulPeriodTo,
    }
    const {
        control,
        getValues,
        setValue,
    } = useForm<ConsFormInput>({
        defaultValues: defaultState,
    })

    const [filtersState, setFiltersState] = useState<ConsFormInput>(defaultState);

    const handleCloseFilter = () => {
        const values = getValues()
        setFiltersState(values)
        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }

    return (
        <div className={`
                sticky top-0 
                pt-[32px] pb-[32px] bg-gray-50
                flex justify-between items-center
                ${NO_OVERLAY_CLASS} 
            `}
        >
            <div className='flex items-center gap-[8px]'>
                <Typography className='text-gray-700'>
                    {t('filters.period')}
                </Typography>
                <FilterDateItem
                    isLoadingFetch={isLoadingFetch || !value.periodFrom}
                    placement={'bottom-start'}
                    control={control}
                    fieldName={'periodFrom'}
                    label={t("filters.period_from")}
                    datesData={data}
                    handleCloseFilter={handleCloseFilter}
                    isDisabled={(date: string) => {
                        return moment(filtersState.periodTo, 'MM-YYYY').isBefore(moment(date, 'MM-YYYY'))
                    }}
                />
                <span className='rotate-90'>
                    <ArrowAccordionIcon />
                </span>
                <FilterDateItem
                    isLoadingFetch={isLoadingFetch || !value.periodFrom}
                    placement={'bottom-end'}
                    control={control}
                    fieldName={'periodTo'}
                    label={t("filters.period_to")}
                    datesData={data}
                    handleCloseFilter={handleCloseFilter}
                    isDisabled={(date: string) => {
                        return moment(filtersState.periodFrom, 'MM-YYYY').isAfter(moment(date, 'MM-YYYY'))
                    }}
                />
            </div>
            <div className='flex items-normal gap-[4px]'>
                <Button
                    // disabled={!computedLink}
                    color='white'
                    className={`relative`}
                    onClick={handleNavigateBack}
                >
                    {t('cancel')}
                </Button>
                <Button
                    loading={isLoadingSave}
                    // disabled={!computedLink}
                    color='black'
                    onClick={() => {
                        onSubmit()
                    }}
                >
                    {t('consumption.save_button')}
                </Button>
            </div>
        </div>
    )
}

export default Filters