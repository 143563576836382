import { IconProps } from './Arrow'

const PaymentIventIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M0.868975 5.64586C1.42131 6.60253 3.41974 6.48258 5.33308 5.37791C7.24642 4.27324 8.34951 2.60253 7.79718 1.64586M0.868975 5.64586C0.316642 4.68919 1.41974 3.01848 3.33308 1.91381C5.24642 0.809143 7.24484 0.689191 7.79718 1.64586M0.868975 5.64586L2.20231 7.95526C2.75431 8.91135 4.75307 8.79198 6.66641 7.68731C8.57975 6.58265 9.68251 4.91135 9.13051 3.95526L7.79718 1.64586" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default PaymentIventIcon
