import { IconProps } from './Arrow'

const CopyIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.8 12.8005C4.36 12.8005 3.98333 12.6438 3.67 12.3305C3.35667 12.0172 3.2 11.6405 3.2 11.2005V1.60049C3.2 1.16049 3.35667 0.783822 3.67 0.470488C3.98333 0.157155 4.36 0.000488281 4.8 0.000488281H12C12.44 0.000488281 12.8167 0.157155 13.13 0.470488C13.4433 0.783822 13.6 1.16049 13.6 1.60049V11.2005C13.6 11.6405 13.4433 12.0172 13.13 12.3305C12.8167 12.6438 12.44 12.8005 12 12.8005H4.8ZM4.8 11.2005H12V1.60049H4.8V11.2005ZM1.6 16.0005C1.16 16.0005 0.783333 15.8438 0.47 15.5305C0.156667 15.2172 0 14.8405 0 14.4005V4.00049C0 3.55866 0.358172 3.20049 0.8 3.20049C1.24183 3.20049 1.6 3.55866 1.6 4.00049V14.4005H9.6C10.0418 14.4005 10.4 14.7587 10.4 15.2005C10.4 15.6423 10.0418 16.0005 9.6 16.0005H1.6Z" fill="#444248" />
        </svg>
    )
}

export default CopyIcon
