

export const topMenu: {
    [key: string]: any
} = {
    contract: {
        title: 'menu.contract',
        menuUrl: '/contract'
    },
    invoices: {
        title: 'menu.invoices',
        menuUrl: '/invoices'
    },
    payments: {
        title: 'menu.payments',
        menuUrl: '/payments'
    },
    consumptions: {
        title: 'menu.consumptions',
        menuUrl: '/consumptions'
    },
    acts: {
        title: 'menu.acts',
        menuUrl: '/acts'
    },
    analytics: {
        title: 'menu.analytics',
        menuUrl: '/analytics'
    }
}