import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import React from 'react'
import { ContractsArrType } from './types'
import { useController } from 'react-hook-form';
import SelectIcon from '../../images/icons/SelectIcon';
import { isIterableArray } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import CheckIcon from '../../images/icons/CheckIcon';

const SelectService = ({ fieldName, control, defaultValue, setValue }: any) => {

    const { t } = useTranslation()

    const {
        field: { value, onChange },
    } = useController({
        name: fieldName,
        control,
        defaultValue: defaultValue
    });

    return (

        <Menu placement="bottom-start" >
            <MenuHandler>
                <Button color='white' className=' px-[16px] rounded flex items-center justify-between'
                    onClick={(event: any) => {
                        event.stopPropagation()
                    }}>
                    {
                        value === 1 ? t('mainpage.electro_title')
                            :
                            value === 2 ? t('mainpage.gas_title')
                                :
                                t('support.select_service_placeholder')
                    }
                    < SelectIcon />
                </Button>
            </MenuHandler>
            <MenuList className='z-[10001] w-auto'>
                <MenuItem
                    onClick={() => {
                        onChange(1)
                        setValue('contract_id', 0)
                    }}
                    className={`flex items-center gap-[6px] py-[10px] px-[16px] text-gray-700 ${value === 1 ? 'bg-gray-100 font-medium' : ''}`}
                >
                    {
                        value === 1 ?
                            <CheckIcon className='!stroke-gray-700' />
                            :
                            ''
                    }
                    {t('mainpage.electro_title')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onChange(2)
                        setValue('contract_id', 0)
                    }}
                    className={`flex items-center gap-[6px] py-[10px] px-[16px] text-gray-700 ${value === 2 ? 'bg-gray-100 font-medium' : ''}`}
                >
                    {
                        value === 2 ?
                            <CheckIcon className='!stroke-gray-700' />
                            :
                            ''
                    }
                    {t('mainpage.gas_title')}
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

export default SelectService