import React from 'react'
import Arrow from '../../images/icons/Arrow'
import CheckFilledIcon from '../../images/icons/CheckFilledIcon'
import { isIterableArray } from '../../utils/utils'

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const Crumbs = () => {

    const { t } = useTranslation()

    const { crumbs } = useSelector((state: RootState) => state.header)
    const { service_id } = useSelector((state: RootState) => state.contract)

    const svgFillService = service_id === 1 ? 'fill-orange-500' : service_id === 2 ? 'fill-blue-600' : 'fill-gray-200'

    return (
        <div className='flex items-center gap-[24px] font-bold text-gray-800'>
            {
                isIterableArray(crumbs) &&
                crumbs.map((crumb, index) => (
                    <Link to={crumb.to} key={index} className={`${index > 0 ? 'hidden' : ''} lap:flex items-center gap-[24px]`}>
                        <div className={`text-[14px] ${index === 0 && 'des:text-20'} flex items-center gap-[5px]`}>
                            {
                                crumb.icon &&
                                <CheckFilledIcon className={`${svgFillService} w-[14px] h-[14px] `} />
                            }
                            {t(crumb.text)}
                        </div>
                        {
                            crumbs.length > 1 && index !== crumbs.length - 1 &&
                            <Arrow className={`hidden lap:!block`} />
                        }
                    </Link>
                ))
            }
        </div>
    )
}

export default Crumbs
