import { Button, Input, Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { sendRefresh, sendRefreshFinish } from '../../store/auth/authActions';
import { AppDispatch } from '../../store/store';


const ForgotForm = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const dispatch = useDispatch<AppDispatch>()

    const [isCodeEnterMode, setIsCodeEnterMode] = useState<boolean>(false);

    const [emailValue, setEmailValue] = useState('');
    const [codeValue, setCodeValue] = useState('');

    const handleSendCode = () => {
        dispatch(sendRefresh({
            email: emailValue,
            lang: 'ru'
        }))
            .then((res) => {
                if (res?.payload?.res === 'Success') setIsCodeEnterMode(true)
            });
    }

    const handleConfirmCode = () => {
        dispatch(sendRefreshFinish({
            code: codeValue,
            lang: 'ru'
        }))
            .then((res) => {
                if (res?.payload?.res === 'Success') navigate('/auth', { replace: true });
            });
    }

    return (
        <div className='w-full'>
            <Link to='/auth' className='block mb-[64px] text-[18px] font-bold text-blue'>
                {t('back')}
            </Link>
            <Typography variant='h3' className='mb-[64px] font-normal text-center' >
                {t('auth.forgot_text_title')}
            </Typography>
            <div className='w-full'>
                {
                    isCodeEnterMode ?
                        // ввод код подтверждения
                        <>
                            <div className='mt-[64px] mb-[30px]'>
                                <Input
                                    value={codeValue}
                                    onChange={(e) => setCodeValue(e.target.value)}
                                    variant='static'
                                    type={'text'}
                                    label={t('auth.input_code_placeholder')}
                                    placeholder={t('auth.input_code_placeholder')}
                                    // placeholder="1234"
                                />
                            </div>

                            <Button
                                variant='outlined'
                                className='font-normal rounded-full border-2 text-[18px] w-full py-[20px]'
                                onClick={handleConfirmCode}
                            >
                                {t('auth.send_code_btn')}
                            </Button>
                        </>
                        :
                        // ввод почты
                        <>
                            <div className='mt-[64px] mb-[30px]'>
                                <Input
                                    value={emailValue}
                                    onChange={(e) => setEmailValue(e.target.value)}
                                    variant='static'
                                    type={'email'}
                                    label={t('auth.input_email_placeholder')}
                                    placeholder={t('auth.input_email_label')}
                                />
                            </div>

                            <Button
                                variant='outlined'
                                className='font-normal rounded-full border-2 text-[18px] w-full py-[20px]'
                                onClick={handleSendCode}
                            >
                                {t('auth.send_code_btn')}
                            </Button>
                        </>
                }

            </div>
        </div>
    )
}

export default ForgotForm
