import { Typography } from '@material-tailwind/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import SwitchElectroIcon from '../../../images/icons/SwitchElectroIcon'
import SwitchGasIcon from '../../../images/icons/SwitchGasIcon'
import { TkosType } from '../ContractPage.types'
import { useTranslation } from 'react-i18next'
import { isIterableArray } from '../../../utils/utils'
import LightBulbIcon from '../../../images/icons/LightBulbIcon'

const PointsContract = ({ data }: { data: TkosType[] }) => {

    const { t } = useTranslation()
    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const bgService = service_id === 1 ? 'bg-orange-500' : service_id === 2 ? 'bg-blue-600' : 'bg-gray-300'

    return (

        <div className='mb-[96px] lap:mb-[128px]'>
            <Typography variant='h3' className='text-center text-gray-800 mb-[24px] tab:mb-[48px] lap:mb-[64px]'>
                {t('contract_page.points_title')}
            </Typography>
            <div className={`
                bg-white border-2 border-gray-100 rounded-[24px] px-[32px] py-[8px] mb-[96px] lap:mb-[128px]
            `}>
                {
                    isIterableArray(data) ?
                        data.map((item, key) => (
                            <div key={key} className='flex overflow-auto items-center py-[24px] gap-[24px] border-b border-gray-100 last:border-0'>
                                <div className={`${bgService} rounded-full flex items-center justify-center min-w-[32px] h-[32px] tab:min-w-[40px] tab:h-[40px]  `}>
                                    {
                                        service_id === 1 &&
                                        <LightBulbIcon className='h-[15px] tab:h-[20px]' />
                                    }
                                    {
                                        service_id === 2 &&
                                        <SwitchGasIcon className='h-[15px]  tab:h-[20px] tab:w-[20px]' />
                                    }
                                </div>
                                <div className={`flex lap:grow lap:grid ${service_id === 1 ? 'grid-cols-6' : 'grid-cols-5'} gap-[16px]`}>

                                    <div className='flex flex-col gap-[8px] min-w-[100px]'>
                                        <p className={` text-[16px] text-gray-700`}>
                                            {t('contract_page.points_name')}
                                        </p>
                                        <p className='font-medium text-[18px] text-gray-800'>
                                            {item.name ? item.name : ' - '}
                                        </p>
                                    </div>
                                    {
                                        service_id === 1 &&
                                        <div className='flex flex-col gap-[8px]'>
                                            <p className={` text-[16px] text-gray-700`}>
                                                {t('contract_page.points_eic')}
                                            </p>
                                            <p className='font-medium text-[18px] text-gray-800'>
                                                {item.eic ? item.eic : ' - '}
                                            </p>
                                        </div>
                                    }
                                    {
                                        service_id === 2 &&
                                        <div className='flex flex-col gap-[8px]'>
                                            <p className={` text-[16px] text-gray-700`}>
                                                {t('contract_page.xcode')}
                                            </p>
                                            <p className='font-medium text-[18px] text-gray-800'>
                                                {item.xcode ? item.xcode : ' - '}
                                            </p>
                                        </div>
                                    }
                                    {
                                        service_id === 2 &&
                                        <div className='flex flex-col gap-[8px]'>
                                            <p className={` text-[16px] text-gray-700`}>
                                                {t('contract_page.virtual_tko')}
                                            </p>
                                            <p className='font-medium text-[18px] text-gray-800'>
                                                {item.virtual_tko ? item.virtual_tko : ' - '}
                                            </p>
                                        </div>
                                    }
                                    {
                                        service_id === 1 &&
                                        <div className='flex flex-col gap-[8px]'>
                                            <p className={` text-[16px] text-gray-700`}>
                                                {t('contract_page.points_class')}
                                            </p>
                                            <p className='font-medium text-[18px] text-gray-800'>
                                                {item.class ? item.class : ' - '}
                                            </p>
                                        </div>
                                    }
                                    {
                                        service_id === 1 &&
                                        <div className='flex flex-col gap-[8px]'>
                                            <p className={` text-[16px] text-gray-700`}>
                                                {t('contract_page.points_group')}
                                            </p>
                                            <p className='font-medium text-[18px] text-gray-800'>
                                                {item.group ? t(`contract_page.group_${item.group}`) : ' - '}
                                            </p>
                                        </div>
                                    }
                                    <div className='flex flex-col gap-[8px]'>
                                        <p className={` text-[16px] text-gray-700`}>
                                            {t('contract_page.points_operator')}
                                        </p>
                                        <p className='font-medium text-[18px] text-gray-800'>
                                            {item.operator ? item.operator : ' - '}
                                        </p>
                                    </div>
                                    <div className='flex flex-col gap-[8px]'>
                                        <p className={` text-[16px] text-gray-700`}>
                                            {t('contract_page.points_date_start')}
                                        </p>
                                        <p className='font-medium text-[18px] text-gray-800'>
                                            {item.date_start ? item.date_start : ' - '}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        ))
                        :
                        <p className='text-[18px] text-gray-600 text-center my-[24px]'> {t('contract_page.points_no_data')}</p>
                }
            </div>
        </div >
    )
}

export default PointsContract