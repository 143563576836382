import { Button, Drawer, IconButton, Typography } from '@material-tailwind/react'
import React, { Fragment, ReactNode } from 'react'
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon'
import { useTranslation } from 'react-i18next'
import { InvoiceRowType } from '../../pages/invoices/InvoicePage.types'
import { toFormatPrice } from '../../utils/utils'
import CopyButton from '../../pages/tableHelpers/buttons/CopyButton'
import { NavLink } from 'react-router-dom'
import DownloadBtn from '../../pages/tableHelpers/buttons/DownloadBtn'
import DownloadIcon from '../../images/icons/DownloadIcon'

const DrawerCard = ({
    isOpen,
    closeDrawer,
    children,
    sizeDrawer
}: {
    children: ReactNode,
    isOpen: boolean,
    closeDrawer: any,
    sizeDrawer?: number
}) => {

    const { t } = useTranslation()

    const size = sizeDrawer ? sizeDrawer : window.innerHeight - 150

    return (
        <Drawer
            placement={'bottom'}
            open={isOpen}
            onClose={closeDrawer}
            className={`p-4 !pb-[48px] ${!isOpen && 'tab:!left-0'} !bottom-0  lap:!max-w-[500px]  flex flex-col overflow-auto no-scrollbar`}
            size={size}
        >
            {
                children &&
                children
            }
        </Drawer>
    )
}

export default DrawerCard