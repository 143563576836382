import React from 'react'

const EditDeclared = () => {
    return (
        <div>
            EditDeclared
        </div>
    )
}

export default EditDeclared
