import React from 'react'

const CreateFactual = () => {
    return (
        <div>
            CreateFactual
        </div>
    )
}

export default CreateFactual
