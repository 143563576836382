import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useCaViewQuery } from '../../store/auth/authService'

import { Button, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react';
import ArrowAccordionIcon from '../../images/icons/ArrowAccordionIcon';
import RequisitesContract from './blocks/RequisitesContract';
import InfoContract from './blocks/InfoContract';
import ContactsContract from './blocks/ContactsContract';
import PaymentPlanContract from './blocks/PaymentPlanContract';
import DocumentsContract from './blocks/DocumentsContract';
import PointsContract from './blocks/PointsContract';
import { DateSelectType } from './ContractPage.types'
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { setServiceId } from '../../store/service/contractSlice';

const ContractPageBlocks = ({ setDateSelect }: { setDateSelect: (value: DateSelectType) => void }) => {

    const { param_contract_id } = useParams()

    const { data, error, isLoading, isFetching } = useCaViewQuery({
        contract_id: Number(param_contract_id)
    })

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
    }, [data]);

    useEffect(() => {
        if (data?.dateSelect) setDateSelect(data.dateSelect)
    }, [data]);


    const ButtonMoveReq = () => (
        <Button
            color='white'
            className="flex items-center justify-center w-[72px] h-[40px] my-[24px]
            ease-in-out duration-200  animate-bounce	
            "
            onClick={() => {
                handleScroll('req')
            }}
        >
            <span className=' rotate-180'>
                <ArrowAccordionIcon />
            </span>
        </Button>
    )
    const ButtonMoveTop = () => (
        <Button
            color='white'
            className='group p-[14px] w-[48px] h-[48px] rounded-full lap:rounded-[8px] flex items-center justify-center border-0 shadow-lg hover:bg-black hover:border-black ease-in-out duration-200  transition-all'
            onClick={() => {
                handleScroll('top')
            }}
        >
            <ArrowAccordionIcon className='group-hover:fill-white transition-all' />
        </Button>
    )

    const handleScroll = (target: string = '') => {
        let top = document.getElementById(target)?.offsetTop ? document.getElementById(target)?.offsetTop : 0
        if (!top) top = 0
        window.scrollTo({
            top: top - 180,
            behavior: 'smooth'
        })
    }

    return (
        <div className='mt-[96px] lap:mt-0'>
            <div className='hidden lap:flex flex justify-center mt-[24px] mb-[120px]'>
                <ButtonMoveReq />
            </div>

            <div className='  sticky top-[0px] flex justify-end translate-y-[-48px] lap:translate-y-[48px] lap:translate-x-[72px]'>
                <ButtonMoveTop />
            </div>
            <div id='req' className='mb-[96px] lap:mb-[128px] lap:px-[48px] lap:py-[96px] lap:bg-white lap:rounded-[40px] lap:border-2 lap:border-gray-100'>
                <RequisitesContract data={data?.requisites} isFetching={isFetching} />
                <InfoContract data={data?.info} isFetching={isFetching} />
                {data?.contacts && <ContactsContract data={data.contacts} />}
            </div>
            {data?.pay_plan && <PaymentPlanContract data={data.pay_plan} />}
            {data?.files && <DocumentsContract data={data.files} />}
            {data?.tku && <PointsContract data={data.tku} />}
        </div >
    )
}

export default ContractPageBlocks
