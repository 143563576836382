import { isIterableArray } from '../../../utils/utils'
import ItemTkoInfo from './ItemTkoInfo'
import ItemLayout from './ItemLayout'

const TkosDeclared = ({ data, control, handleSetDeclared }: any) => {

   

    return (
        <div>
            {
                isIterableArray(data) && data?.map((tkoData: any, key: number) => (
                    <ItemLayout key={key} tkoData={tkoData} control={control}>
                        <ItemTkoInfo
                            handleSetDeclared={handleSetDeclared}
                            // handleChangeName={handleChangeName}
                            tko={tkoData.tko}
                        />
                    </ItemLayout>
                ))
            }
        </div>
    )
}

export default TkosDeclared