import PeriodsTkosInput from './PeriodsTkosInput'

const ItemLayout = ({ tkoData, handleEditDeclared, children, control }: any) => {

    return (
        <div className={`bg-white p-[48px] border-gray-100 border-2 rounded-[24px] mb-[24px] animate-appear`}>
            <div className='grid grid-cols-[20%_20%_60%]'>
                {children}
                <PeriodsTkosInput
                    tko_detailing={tkoData.tko.detailing}
                    tko_id={tkoData.tko.id}
                    control={control}
                    data={tkoData?.data}
                    handleEditDeclared={handleEditDeclared}
                />
            </div>
        </div>
    )
}

export default ItemLayout