import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const CRUMB_MAIN = {
    text: 'crumb_main',
    to: '/'
}
const CRUMB_COMPANY = {
    text: 'my_companies',
    to: '/my-companies'
}

type CrumbType = {
    text: string,
    to: string,
    icon?: boolean
}

export interface HeaderState {
    crumbs: any[]
}

const initialState: HeaderState = {
    crumbs: [CRUMB_MAIN]
}
export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setCrumbsContract: (state, action: PayloadAction<CrumbType[]>) => {
            state.crumbs = [
                CRUMB_MAIN,
                CRUMB_COMPANY,
                ...action.payload
            ]
        },
        setCrumbs: (state, action: PayloadAction<CrumbType[]>) => {
            state.crumbs = [
                CRUMB_MAIN,
                ...action.payload
            ]
        },
        resetCrumbs: (state) => {
            state.crumbs = [CRUMB_MAIN]
        },
    },
})

export const { setCrumbsContract, setCrumbs, resetCrumbs } = headerSlice.actions

export default headerSlice.reducer