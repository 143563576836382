const INDEX = 1
export const NO_OVERLAY_CLASS = `z-10`

const OverlayUnTouchable = ({ isOverlay, opacity = 100, bg, children }: { isOverlay: boolean, children: any, bg?: string, opacity?: number }) => {
    return (
        <div className='relative'>
            <div
                style={{ zIndex: INDEX, opacity: opacity }}
                className={`${isOverlay ? `
                    absolute top-0 left-0 right-0 bottom-0 point-events-none cursor-default
                      ${bg || ''}
                    `:
                    'overlay'
                    }`}
            />
            {children}
        </div>
    )
}

export default OverlayUnTouchable