import { ReactGrid, Column, Row, CellChange, NumberCell, TextCell } from '@silevis/reactgrid';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import "@silevis/reactgrid/styles.css";
import '../../../style/matrix.scss'
import { isIterableArray } from '../../../utils/utils';
import AmountTitle from '../AmountTitle';
import { Button } from '@material-tailwind/react';
import { useNavigate, useParams } from 'react-router-dom';
import MatrixAmountButtons from './MatrixAmountButtons';
// import { isIterableArray } from '../utils/utils';
// import { isIterableArray } from '../../utils/utils';


const MatrixMonth = (props: {
    isLoadingSave?: any,
    DownloadExcelButton?: any,
    saveMatrixMonth?: any,
    amount: number | boolean,
    fixedCount?: any,
    columnWidth?: any,
    heightCell?: any,
    overflowX?: any,
    detailing: any,
    isEditAccess: any,
    daysLength?: any
    handleNavigateBack: any
}) => {
    const {
        handleNavigateBack,
        isLoadingSave,
        DownloadExcelButton,
        saveMatrixMonth,
        amount,
        fixedCount = false,
        // isEditZero = true, // отключить редактирование ячейки в которой значение 0
        columnWidth = 64, // ширина ячейки
        heightCell = 32,
        // columnWidth = 70,
        // heightCell = 36,
        overflowX = 'auto',
        detailing,
        // matrixData, // detailing - матрица, 
        // detailing_boolean - матрица для окрашивания в цвет ячейку
        // type_detailing - тип детализации, 1 - дни, остальное 25, либо columnLength
        isEditAccess = true, // отлючить редактирование
        // isDisableCheck = false,
        // columnLength = 25, // кол-во столбцов (часов) в зависимости от типа matrixData.type_detailing
        // isTopSave = false, // кнопка сохранить вверху,
        // saveButtonText = 'save', //текст кнопки сохранить 
        daysLength = 25
    } = props

    // const { detailing, detailing_boolean, type_detailing } = matrixData

    // const daysLength = type_detailing === 1 ? 1 : columnLength // кол-во часов в зависимости от типа
    // const daysLength = 25

    const { param_contract_id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [rows, setRows] = useState<any>([]);

    // const [amount, setAmount] = useState(0);

    const getColumns = (max: number) => {
        const cols = [];
        for (let i = 0; i < max; i++) {
            cols.push({
                // columnId: `${i < 10 ? "0" + i : i}-${i < 9 ? "0" + (i + 1) : i + 1}`,
                columnId: `${i < 10 ? "0" + i : i}-${i < 9 ? "0" + (i + 1) : i + 1}`,
                // columnId: i,
                // width: '100%',
                width: columnWidth,
                // width: 100,
                // height: 32
            });
        }
        // if (daysLength !== 1) {
        //     cols.push({
        //         columnId: t("tables.total"),
        //         width: 120,
        //     });
        // }
        cols.unshift({
            columnId: '',
            width: 32,
        });
        return cols;
    };

    // const [isDisableSave, setIsDisableSave] = useState(isDisableCheck);

    const [summ, setSumm] = useState(amount || 0);

    const [columns, setColumns] = useState(getColumns(daysLength));

    const getColumnIndex = (colId: any): number => +colId.substr(0, 2);

    // const getSumm = (arr) => arr.reduce((p, s) => +p + +s, 0);

    // useEffect(() => {
    //     checkDisabled()
    // }, [summ, amount]);


    useEffect(() => {
        // setTimeout(() => {
        setMatrix(detailing)
        // });
    }, [detailing]);

    const setMatrix = (detailing: any) => {
        if (detailing) {
            setColumns(getColumns(daysLength))
            // setSumm(detailing.reduce((a: any, b: any) => a.concat(b)).reduce((a: any, b: any) => Number(a) + Number(b)));
        }
    }
    const rawToRow = (raw: any) => {
        const arr: any = [];
        if (raw.length > 0) {
            raw.forEach((r: any, i: any) => {

                const rawItem = [
                    {
                        type: "number",
                        width: columnWidth,
                        value: i + 1,
                        // type: "text",
                        // text: (i + 1).toString(),
                        nonEditable: true,
                        className: "asp-cell-date",
                        height: heightCell,
                    },
                    ...r.map((ri: any, ii: any): any => {
                        // const isEdited = daysLength === 1 ? detailing_boolean?.[i] : detailing_boolean?.[i]?.[ii]
                        // const classEdited = !!detailing_boolean ? isEdited ? '' : 'edited-cell' : ''
                        // ${'classEdited'}
                        return ({
                            type: "number",
                            width: columnWidth,
                            value: +ri,
                            // value: +ri,
                            height: heightCell,
                            nonEditable: !isEditAccess,
                            // nonEditable: isEditAccess ? (!isEditZero && ri === 0) : isEditAccess,
                            // text: ri.toString(),
                            // validator: (txt) => txt.match(/^[0-9]+$/), //целое
                            // validator: (txt) => txt.match(/^[0-9]*[.,]?[0-9]+$/), //дробное
                            className: `asp-cell ${ii % 2 === 0 ? 'stripped' : ''}`,
                        })
                    }),
                ]
                // if (daysLength !== 1) {
                //     rawItem.push(
                //         ...(
                //             r.length === daysLength
                //                 ? []
                //                 : [{ type: "text", text: "", className: "asp-cell" }]
                //         ),
                //         {
                //             type: "number",
                //             height: heightCell,
                //             value: getSumm(r),
                //             nonEditable: true,
                //             className: "asp-cell",
                //         },
                //     )
                // }
                arr.push({
                    height: heightCell,
                    rowId: i + 1,
                    cells: rawItem,
                });
            });
        }
        return arr;
    };

    const getRows = (raw: any) => {
        const headerRow = {
            rowId: "header",
            cells: getColumns(daysLength).map((item, index) => ({
                type: "header",
                text: item.columnId.toString(),
                className: `asp-cell-header ${index === 0 && "asp-cell-date"}`,
            })),
        };
        return [headerRow, ...rawToRow(raw)];
    };


    const [focusEl, setFocusEl] = useState({
        columnId: '00-01',
        rowId: 0
    });


    const handleChanges = (changes: any) => {
        if (isIterableArray(changes)) {
            // if (changes[0].columnId === focusEl?.columnId &&
            //     changes[0].rowId === focusEl.rowId) {
            if (isEditAccess) {
                let tmp = [...detailing];
                changes.forEach((c: any) => {
                    console.log(c)
                    if (!isNaN(c.newCell.value)) {
                        if (fixedCount) {
                            tmp[c.rowId][getColumnIndex(c.columnId)] = c.newCell.value.toFixed(fixedCount)
                        }
                        else {
                            try {
                                tmp[c.rowId][getColumnIndex(c.columnId)] = c.newCell.value
                            }
                            catch (err) {
                                console.log(err)
                            }
                            console.log(tmp)
                            // console.log(tmp[c.rowId][getColumnIndex(c.columnId)])
                            // if (tmp[c.rowId][getColumnIndex(c.columnId)] || tmp[c.rowId][getColumnIndex(c.columnId)] === 0) {
                            //     // console.log('cccc', tmp[c.rowId][0])
                            //     // const columnIndex = getColumnIndex(c.columnId)
                            //     // if (typeof columnIndex === 'number' && typeof c.rowId === 'number')
                            //     // tmp[c.rowId][columnIndex] = c.newCell.value
                            // }
                        }
                    }
                    else tmp[c.rowId][getColumnIndex(c.columnId)] = 0
                });
                setRows(
                    getRows(tmp)
                );
                // setSumm(
                //     tmp.reduce((a, b) => a.concat(b))
                //         .reduce((a, b) => Number(a) + Number(b))
                // );
            }

            // }
        }
    };

    // const [people, setPeople] = React.useState<Person[]>(getPeople());

    // const rows = getRows(people);
    // const columns = getColumns();

    const [gridData, setGridData] = React.useState<any[]>(detailing);

    // useEffect(() => {
    //     setGridData(detailing)
    // }, [detailing])

    const applyChangesToPeople = (
        changes: CellChange<NumberCell>[],
        prevPeople1: any[]
    ): any[] => {
        let prevPeople = [...prevPeople1]
        changes.forEach((change) => {
            const personIndex: any = change.rowId;
            // const fieldName = Number(change.columnId);
            const fieldName: any = getColumnIndex(change.columnId);
            let nrow = [...prevPeople[personIndex - 1]]
            nrow.splice(fieldName, 1, change.newCell.value)
            nrow[fieldName] = change.newCell.value || 0;


            prevPeople.splice(personIndex - 1, 1, nrow)

            // prevPeople[personIndex][fieldName] = change.newCell.value;
        });

        if (prevPeople) {
            setSumm(prevPeople.reduce((a: any, b: any) => a.concat(b)).reduce((a: any, b: any) => Number(a) + Number(b)));
        }
        setRows(
            getRows(prevPeople)
        );
        return [...prevPeople];

    };

    // const applyChangesToPeople = (
    //     changes: CellChange<NumberCell>[],
    //     prevPeople: number[][]
    // ): any[] => {
    //     const prev = prevPeople.slice()
    //     changes.forEach((change: any) => {
    //         const personIndex: number = change.rowId;
    //         const fieldName: number = getColumnIndex(change.columnId);
    //         // prev[personIndex][fieldName] = change.newCell.value;
    //         console.log(prev[personIndex][fieldName])
    //         console.log(change.newCell.value)
    //     });
    //     return [...prev];
    // };

    const handleChanges2 = (changes: any[]) => {
        setGridData((prevPeople) => applyChangesToPeople(changes, prevPeople));
    };

    useEffect(() => {
        console.log(gridData)
        if (!isIterableArray(rows)) setRows(
            getRows(gridData)
        );
    }, [gridData])

    const handleSave = () => {
        saveMatrixMonth(gridData)
    }


    useEffect(() => {
        handleScroll()
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setFocusEl({
            // columnId: '',
            columnId: '00-01',
            rowId: 1
        })
        handleChanges([])
    };


    return (
        <>
            <MatrixAmountButtons
                handleNavigateBack={handleNavigateBack}
                isLoadingSave={isLoadingSave}
                DownloadExcelButton={DownloadExcelButton}
                isEditAccess={isEditAccess}
                handleSave={handleSave}
                amount={summ}
            />
            <div
                className={`bg-white p-[24px] rounded-[16px] border-2 border-gray-100`}
                style={{ overflowX: 'auto' }}
            >
                {
                    isIterableArray(rows) && isIterableArray(columns) &&
                    <ReactGrid
                        rows={rows}
                        columns={columns}
                        stickyLeftColumns={1}
                        stickyTopRows={0}
                        enableFillHandle
                        enableRangeSelection
                        onCellsChanged={handleChanges2}
                        // onFocusLocationChanged={(location: any) => {
                        //     setFocusEl(location)
                        // }}
                        onFocusLocationChanging={(location: any) => {
                            setFocusEl(location)
                            return true
                        }}
                        focusLocation={focusEl}
                    />
                }
            </div>
        </>
    )
}

export default MatrixMonth
