import { Button, Typography, Popover, PopoverContent, PopoverHandler } from '@material-tailwind/react'
import { placement } from '@material-tailwind/react/types/components/menu';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form';
import SelectIcon from '../../../images/icons/SelectIcon';
import { isIterableArray } from '../../../utils/utils';
import { DateSelectType } from '../../contract/ContractPage.types';
import AirDatepickerReact from './AirPicker';
import BtnFilter from './BtnFilter';

export type CalendarDaysType = {
    active: boolean,
    day: string
}
type propTypes = {
    isLoadingFetch: boolean,
    placement?: placement,
    control: any,
    fieldName: string,
    defaultValue: any,
    label: string,
    filterStateValue: string[],
    datesData: DateSelectType,
    handleCloseFilter: any
}
const FilterPeriod = (props: propTypes) => {

    const {
        isLoadingFetch,
        control,
        fieldName,
        defaultValue,
        label,
        handleCloseFilter,
        filterStateValue,
        placement = "bottom-end" } = props

    const {
        field: { ref, value, onChange, ...inputProps },
        formState: { errors }
    } = useController({
        name: fieldName,
        control,
        defaultValue: defaultValue
    });

    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (!isLoadingFetch) setIsLoading(false)
    }, [isLoadingFetch]);

    const clearFilter = () => {
        onChange([])
        setIsOpenDrawer(false)
        handleCloseFilter()
        setIsLoading(true)
    }
    const confirmFilter = () => {
        setIsLoading(true)
        setIsOpenDrawer(false)
        handleCloseFilter()
    }
    // const defaultYear = Number(moment().format('YYYY'))
    // const defaultMonth = moment().format('MM')
    // const defaultDay = moment().format('D')

    // const [activeYear, setActiveYear] = useState<number>(defaultYear);
    // const [activeMonth, setActiveMonth] = useState<string>(defaultMonth);
    // const [activeDay, setActiveDay] = useState<string>(defaultDay);

    // const [daysData, setDaysData] = useState<CalendarDaysType[]>([]);


    // useEffect(() => {
    //     if (activeYear && activeMonth) {
    //         const days = getCalendarByMonth(moment(`${activeYear}-${activeMonth}`, 'YYYY-MM'))
    //         setDaysData(days)
    //     }
    // }, [activeYear, activeMonth]);

    // const handleCloseDrawer = () => {
    //     setIsOpenDrawer(false)
    //     handleCloseFilter()
    // }

    // const handleClickDay = (event: React.MouseEvent<unknown>, data: CalendarDaysType) => {
    //     console.log(data)
    //     setActiveDay(data.day)
    // }
    // console.log(value)
 
    return (
        <>
            <Popover
                placement={placement}
                open={isOpenDrawer}
                handler={(e) => {
                    setIsOpenDrawer(e)
                }}
            >
                <PopoverHandler>
                    <div className='w-[240px]'>
                        <BtnFilter
                            allowClear={isIterableArray(filterStateValue) && filterStateValue?.length === 2}
                            clearFilter={clearFilter}
                            onClick={() => setIsOpenDrawer(true)}
                            icon={<SelectIcon />}
                            isLoading={isLoading}
                        >
                            {isIterableArray(filterStateValue) && filterStateValue?.length === 2 ?
                                <span className='whitespace-nowrap text-gray-700 text-[14px] font-medium'>
                                    {/* {t('filters.from')}  */}
                                    {filterStateValue[0]}
                                    {' - '}
                                    {/* {t('filters.to')}  */}
                                    {filterStateValue[1]}
                                </span>
                                :
                                <span className='whitespace-nowrap text-gray-700'>
                                    {label}
                                </span>
                            }
                        </BtnFilter>
                    </div>
                </PopoverHandler>
                <PopoverContent className='w-[600px] bg-gray-50 p-0'>
                    <div className='p-[24px] rounded'>
                        <div className="mb-3 flex items-start justify-between">
                            <div className='flex flex-col gap-[16px]'>
                                <Typography variant="lead" className='text-[20px] text-gray-800 font-medium' >
                                    {label}
                                </Typography>
                                <Typography variant="lead" className='text-[18px] font-medium h-[24px]' >
                                    {isIterableArray(value) && value?.length === 2 ?
                                        <span className='whitespace-nowrap text-gray-700 text-[16px] font-medium'>
                                            {t('filters.from')} {value[0]}
                                            {' - '}
                                            {t('filters.to')}  {value[1]}
                                        </span>
                                        :
                                        ''
                                    }
                                </Typography>
                            </div>
                            <div>
                                {
                                    isIterableArray(value) &&
                                    <div className='font-medium text-gray-600 hover:text-gray-500 transition-color easy duration-200 cursor-pointer'
                                        onClick={clearFilter}
                                    >
                                        {t('filters.clear')}
                                    </div>
                                }
                            </div>
                        </div>

                        <AirDatepickerReact onChange={onChange} selectedDates={value} />
                        <Button
                            disabled={value?.length < 2}
                            color='white'
                            className=' w-full mt-[24px] font-normal'
                            onClick={confirmFilter}
                        >
                            {t('filters.confirm')}
                        </Button>
                        {
                            // <div className='h-[150px] flex items-center justify-center'><Spinner /></div>
                        }
                    </div>

                </PopoverContent>
            </Popover>
        </>
    )
}

export default FilterPeriod
