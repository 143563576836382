import { Spinner, Typography } from '@material-tailwind/react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { toFormatPrice, toStringPrice } from '../../utils/utils'

const AmountTitle = ({ text, amount, amountTitle, isFetching }: { text: any, amount: any, amountTitle: string, isFetching: boolean }) => {

    const service_id = useSelector((state: RootState) => state.contract.service_id)
    const textService = service_id === 1 ? 'text-orange-500' : service_id === 2 ? 'text-blue-600' : 'text-gray-800'
    const spinnerColor = service_id === 1 ? 'orange' : service_id === 2 ? 'blue' : 'gray'

    return (
        <div className="flex flex-col gap-[4px]">
            <Typography variant='lead' className='text-gray-700 font-normal'>
                {text}
            </Typography>
            <Typography variant='lead' className={`${textService} font-bold text-[24px]`}>
                {
                    isFetching ?
                        <Spinner color={spinnerColor} className='min-h-[1.5em]' />
                        :
                        // typeof amount === 'number' ?
                        <span className='flex align-baseline gap-[4px]'>
                            {toStringPrice(amount || 0)} {amountTitle}
                        </span>
                    // :
                    // ' - '
                }
            </Typography>
        </div>
    )
}

export default AmountTitle
