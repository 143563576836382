import { Typography } from '@material-tailwind/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Graph from './Graph.jsx'

const ItemDayGraph = ({ item, month, year, openDrawer, isWeekend }: { item: any, month: any, year: any, openDrawer: any, isWeekend: boolean}) => {

    const { t } = useTranslation()

    return (
        // ${item?.active ? 'border-gray-100' : 'border-gray-50'}
        <div className={`rounded-[16px] p-[24px] flex flex-col gap-[8px] border-2 border-gray-100 animate-appear transition-all cursor-pointer hover:bg-gray-50 ${isWeekend ? 'bg-gray-50 hover:bg-gray-100': ''}`}
            onClick={() => {
                openDrawer({
                    ...item,
                    month: month,
                    year: year
                })
            }}
        >
            {/* ${item?.active ? 'text-gray-700 font-normal' : 'text-gray-500 font-medium'}   */}
            <Typography
                variant='lead'
                className={`text-[14px] text-gray-700 font-normal
           `}
            >
                {item.day}
            </Typography>
            {/* ${item?.active ? 'text-gray-700 font-normal' : 'text-gray-500 font-medium'} */}
            <Typography
                variant='lead'
                className={`text-gray-700 font-normal`}
            >
                {item?.total}
                {' '}
                <span className={`text-gray-600 font-medium text-[14px] `}>
                    {t('kvHour')}
                </span>
            </Typography>
            <Graph
                data_declared={item.data_declared}
                data_cons={item.data_cons}
            />
        </div>
    )
}

export default ItemDayGraph
