import { IconProps } from './Arrow'

const CheckIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M14.8333 1L5.66667 11L1.5 6.45455" stroke="#FDFDFD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CheckIcon
