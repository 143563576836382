import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { setContractId } from '../../store/service/contractSlice';
import { setCrumbs } from '../../store/service/headerSlice';
import PageLayout from '../../layouts/pagesLayouts/PageLayout';
import { Typography } from '@material-tailwind/react';
import { Helmet } from 'react-helmet';
import { isIterableArray } from '../../utils/utils';
import moment from 'moment';
import SupportFilters from './SupportFilters';
import CreateAppeal from './CreateAppeal';
import { useProfileRequestsViewQuery } from '../../store/auth/authService';
import { AppealType } from './types';
import ViewAppeal from './ViewAppeal';

const datesData = () => {
    const end = moment();
    const start = moment().add(-12, 'month');

    const result: any = {}
    const allMonthYears: any[] = []
    const allYears = []

    for (let cur = start; cur.isSameOrBefore(end); cur.add(1, 'month')) {
        allMonthYears.push({
            month: cur.format('MM'),
            year: cur.format('YYYY')
        })
        allYears.push(cur.format('YYYY'))
    }
    const uniqueYears = new Set(allYears)
    Array.from(uniqueYears).map(year => {
        result[year] = allMonthYears.filter(i => i.year == year)
    })
    return result
}

const SupportPage = () => {

    const { t } = useTranslation()

    const dispatch = useDispatch<AppDispatch>()

    const [search, setSearch] = useState<string>('');

    const [isOpenCreate, setIsOpenCreate] = useState(false)
    const [isOpenView, setIsOpenView] = useState(false);
    const [modalValue, setModalValue] = useState<AppealType | null>(null)

    const form: {
        periodFrom: string,
        periodTo: string,
    } = {
        periodFrom: '',
        periodTo: ''
    }

    const [sendForm, setSendForm] = useState<{
        periodFrom: string,
        periodTo: string,
    }>(form);

    const { currentData, isFetching, isLoading } = useProfileRequestsViewQuery(sendForm)

    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(true);

    // array all rows
    // const [rows, setRows] = useState<AppealType[]>([]);

    // only visible rows
    const [tableRows, setTableRows] = useState<AppealType[]>([]);


    useEffect(() => {
        // dispatch(setContractId(0))
        dispatch(setCrumbs([{
            text: t('support.support_title'),
            to: '#'
        }]))
    }, []);

    useEffect(() => {
        if (currentData) setTableRows(currentData)
    }, [currentData])

    useEffect(() => {
        if (
            (!isLoading && !isFetching && isIterableArray(currentData)) ||
            (!isLoading && !isFetching && !isIterableArray(currentData))
        ) {
            setIsLoadingTable(false)
        }
    }, [isLoading, isFetching, currentData]);

    const setFormForFetch = (values: any) => {
        setIsLoadingTable(true)
        setSendForm(prev => ({
            ...prev, ...{
                ...values
            }
        }))
    }


    const onSearch = (searchValue: string) => {
        setSearch(searchValue);
        const reg = new RegExp(searchValue.replaceAll(' ', ''), "gi");
        if (searchValue === "") {
            setTableRows(currentData || [])
            return
        }

        if (currentData && isIterableArray(currentData)) {
            const filteredData: AppealType[] = currentData.filter((item: any) => {
                const keys = Object.keys(item);
                let matches = 0;
                keys.forEach((key) => {
                    let val = String(item[key]).replaceAll(' ', '')
                    if (reg.test(val)) matches++;
                });
                return matches > 0;
            });
            if (searchValue !== "" && filteredData.length > 0) {
                setTableRows(filteredData);
            }
            if (searchValue !== "" && filteredData.length === 0) {
                setTableRows([]);
            }
        }
    };

    const handleCreate = () => {
        setIsOpenCreate(true)
    }
    const handleCloseCreate = () => {
        setIsOpenCreate(false)
    }
    const handleView = (item: AppealType) => {
        setIsOpenView(true)
        setModalValue(item)
    }
    const handleCloseView = () => {
        setIsOpenView(false)
        setModalValue(null)
    }

    const dateParser = (date: string) => {
        const day = moment(date, 'DD-MM-YYYY').format('DD')
        const month = moment(date, 'DD-MM-YYYY').format('MM')
        const year = moment(date, 'DD-MM-YYYY').format('YYYY')
        return day + ' ' + t(`r_month_${month}`) + ' ' + year
    }

    const statusParser = (status: number) => {
        switch (status) {
            case 1:
                return (
                    <div className='rounded-full px-[16px] py-[4px] text-[14px] font-normal bg-warning-100 text-warning-600'>
                        {t(`support.status_${status}`)}
                    </div>
                )
            case 2:
                return (
                    <div className='rounded-full px-[16px] py-[4px] text-[14px] font-normal bg-red-100 text-red-500'>
                        {t(`support.status_${status}`)}
                    </div>
                )

            default:
                break;
        }
    }

    return (
        <PageLayout
            title={
                <Typography variant='h3' className='text-gray-800 text-center mb-[64px]'>
                    {t('support.support_title')}
                </Typography>
            }
        >
            <ViewAppeal
                open={isOpenView}
                handleClose={handleCloseView}
                modalValue={modalValue}
            />
            <CreateAppeal
                open={isOpenCreate}
                handleClose={handleCloseCreate}
            />
            <Helmet>
                <title>
                    {t('support.support_title')}
                </title>
            </Helmet>

            <SupportFilters
                isLoadingFetch={isLoadingTable}
                fetchPage={setFormForFetch}
                datesData={datesData()}
                onSearch={onSearch}
                handleCreate={handleCreate}
            />

            <div className='flex flex-col'>
                {
                    isIterableArray(tableRows) &&
                    tableRows.map((item: any, key: number) => (
                        <div key={key}>
                            {
                                item.date !== currentData?.[key - 1]?.date &&
                                <Typography variant='lead' className='text-[20px] text-gray-800 font-normal mb-[24px] mt-[64px]'>
                                    {dateParser(item.date)}
                                </Typography>
                            }

                            <div onClick={() => handleView(item)} className='grid grid-cols-2 gap-[24px] bg-white rounded-[24px] border-2 border-gray-200 p-[48px] mb-[24px] cursor-pointer transition-all hover:bg-gray-50'>
                                <div className='flex flex-col gap-[24px]'>
                                    <Typography variant='lead' className='text-[24px] text-gray-800 font-normal'>
                                        {t('support.support_item_title')} №{item.id}
                                    </Typography>
                                    <div className='flex flex-col gap-[8px]'>
                                        <div className='flex item-baseline gap-[8px]'>
                                            <Typography variant='lead' className='text-[16px] text-gray-700'>
                                                {t('support.type_title')}
                                            </Typography>
                                            <Typography variant='lead' className='text-[16px] text-gray-800'>
                                                {
                                                    item.service_id === 1 ? t('mainpage.electro_title')
                                                        :
                                                        item.service_id === 2 ? t('mainpage.gas_title')
                                                            :
                                                            ''
                                                }
                                            </Typography>
                                        </div>
                                        <div className='flex item-baseline gap-[8px]'>
                                            <Typography variant='lead' className='text-[16px] text-gray-700'>
                                                {t('support.or_number')}
                                            </Typography>
                                            <Typography variant='lead' className='text-[16px] text-gray-800'>
                                                #{item.or_number}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-start flex-col gap-[24px]'>
                                    <div className='flex flex-col gap-[8px]'>
                                        <Typography variant='lead' className='text-[16px] text-gray-700'>
                                            {t('support.theme_title')}
                                        </Typography>
                                        <Typography variant='lead' className='text-[20px] text-gray-800 font-normal'>
                                            {item.theme}
                                        </Typography>
                                    </div>
                                    {statusParser(item.status)}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </PageLayout>
    )
}

export default SupportPage