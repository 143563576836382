import { IconProps } from "./Arrow"

const ArrowAccordionIcon = (props: IconProps) => {
    return (
        <svg width={props.width || "16"} height={props.height || "10"} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M15.508 8.84993C15.9163 8.44159 15.9163 7.78326 15.508 7.37493L8.58301 0.449928C8.25801 0.124928 7.73301 0.124928 7.40801 0.449928L0.483008 7.37493C0.0746745 7.78326 0.0746745 8.44159 0.483008 8.84993C0.891341 9.25826 1.54967 9.25826 1.95801 8.84993L7.99967 2.81659L14.0413 8.85826C14.4413 9.25826 15.108 9.25826 15.508 8.84993Z" fill="#2D2B32" />
        </svg>
    )
}

export default ArrowAccordionIcon