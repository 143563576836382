import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
// import { pageApi } from './api/pageApi'
import { authApi } from './auth/authService'
import authSlice from './auth/authSlice'
import contractReducer from './service/contractSlice'
import headerReducer from './service/headerSlice'

export type TypeRootState = ReturnType<typeof store.getState>

export const store = configureStore({
	reducer: {
		auth: authSlice,
		contract: contractReducer,
		header: headerReducer,
		// [pageApi.reducerPath]: pageApi.reducer,
		[authApi.reducerPath]: authApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(authApi.middleware),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch