import React, { useState } from 'react'
import { isIterableArray, toDate, toFormatPrice } from '../../../utils/utils';
import { ContractRowType } from '../types';
import { useTranslation } from 'react-i18next';
import { IconButton, List, ListItem, Typography } from '@material-tailwind/react';
import Checkbox from '@mui/material/Checkbox';
import MoreDotsIcon from '../../../images/icons/MoreDotsIcon';
import { NavLink } from 'react-router-dom';
import DrawerCard from '../../../components/mobile/DrawerCard';
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon';
import BalanceBadges from '../../../components/BalanceBadges';
import { topMenu } from '../../../menu/topMenu';
import CheckFilledIcon from '../../../images/icons/CheckFilledIcon';
import DrawerCompanyMenu from '../../../components/mobile/DrawerCompanyMenu';

const CompanyCard = ({ rows, handleClick, isSelected }: {
    rows: ContractRowType[],
    handleClick: any,
    isSelected: any
}) => {

    const { t } = useTranslation()

    const [isOpenCard, setIsOpenCard] = useState<boolean>(false);
    const [modalValue, setModalValue] = useState<ContractRowType | null>(null);

    const closeDrawer = () => {
        setIsOpenCard(false)
        setModalValue(null)
    }
    const openDrawer = (row: ContractRowType) => {
        setIsOpenCard(true)
        setModalValue({
            ...row, ...{ contract_id: row?.id }
        })
    }
    const BalanceCard = ({ balance, status, title }: { balance: number, status: number, title: string }) => (
        <div className="flex flex-col items-center justify-center py-[8px] w-full">
            <span className='text-[12px] des:text-base font-normal !text-gray-600'>
                {title}
            </span>
            <span className={`
                    ${Number(balance) >= 0 ?
                    (status === 1) ? 'text-gray-700' : 'text-gray-500'
                    :
                    (status === 1) ? 'text-red-500' : 'text-red-300'} 
                    text-[14px] font-bold `}
            >
                {toFormatPrice(balance)}
            </span>
        </div>
    )

    return (
        <div className='tab:hidden mt-[28px]'>
            <DrawerCompanyMenu
                isOpen={isOpenCard}
                closeDrawer={closeDrawer}
                modalValue={modalValue} 
                isShowMenu={modalValue?.status === 1}
            />
            <>
                {
                    isIterableArray(rows) &&
                    rows.map((row, index) => {
                        // const isItemSelected = isSelected(row.id);
                        return (
                            <NavLink
                                to={row?.status === 1 ? `/contract/${row.id}` : '#'}
                                className={`${row?.status === 1 ? ' bg-white   active:!bg-gray-50' : 'bg-gray-50'} rounded border-[1px] border-gray-100 block my-[12px]`}
                            >
                                <div className="flex flex-col gap-[12px] p-[12px]">
                                    <div className='flex items-center justify-between'>
                                        <div className={`
                                                    ${row.status === 1 && row.service_id === 1 && 'bg-orange-50 text-orange-500'}
                                                    ${row.status === 1 && row.service_id === 2 && 'bg-blue-100 text-blue-600'}
                                                    ${row.status !== 1 && 'bg-gray-100 text-gray-500'}
                                                    ${row.status === 1 && 'table-row-number '} service_id_${row.service_id}
                                                    ease transition-all duration-250 
                                                    text-[14px] des:text-[16px] font-bold whitespace-nowrap text-center
                                                    py-[5px] px-[32px] min-w-[160px] rounded-full
                                                `}>
                                            {row.number}
                                        </div>
                                        <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full'
                                            onClick={(event: any) => {
                                                event.stopPropagation()
                                                event.preventDefault()
                                                openDrawer(row)
                                            }}>
                                            <MoreDotsIcon />
                                        </IconButton>
                                    </div>
                                    <div className='flex items-center justify-between'>
                                        <div className={` 
                                                    ${row.status === 1 && row.service_id === 1 && 'bg-orange-50 text-orange-500'}
                                                    ${row.status === 1 && row.service_id === 2 && 'bg-blue-100 text-blue-600'}
                                                    ${row.status !== 1 && 'text-gray-500'}
                                                    ${row.status === 1 && 'table-row-number '} service_id_${row.service_id}
                                                    
                                                    ease transition-all duration-250 
                                                    text-[14px] des:text-[16px] font-bold whitespace-nowrap text-center
                                                    py-[5px] px-[16px]  rounded-full
                                                `}>
                                            {t('or')} {row.or_number}
                                        </div>
                                        {/* <Typography variant='lead' className='text-[16px] font-normal text-gray-800 text-center'>
                                                    {t('or')} {row.or_number}
                                                </Typography> */}
                                        <span className='text-[12px] font-normal text-gray-800 text-center'>
                                            {/* {t('companies.contract_until')} */}
                                            {/* {row.legal_address}, */}
                                            {t('companies.contract_until')} {toDate(row.contract_end)}
                                        </span>
                                    </div>
                                </div>
                            </NavLink>
                        );
                    })
                }
            </>

        </div >
    )
}

export default CompanyCard