import { Button, Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FilterDateItem from '../tableHelpers/filters/FilterDateItem'
import ArrowAccordionIcon from '../../images/icons/ArrowAccordionIcon'
import FilterSearch from '../tableHelpers/filters/FilterSearch'
import moment from 'moment'

const SupportFilters = (props: any) => {

    const { fetchPage, isLoadingFetch, onSearch, datesData, handleCreate } = props

    const { t } = useTranslation()


    const defaultState: {
        periodFrom: string,
        periodTo: string,
    } = {
        periodFrom: '',
        periodTo: '',
    }
    const {
        control,
        getValues,
    } = useForm<{
        periodFrom: string,
        periodTo: string,
    }>({
        defaultValues: defaultState,
    })

    const [filtersState, setFiltersState] = useState<{
        periodFrom: string,
        periodTo: string,
    }>(defaultState);

    const handleCloseFilter = () => {
        const values = getValues()
        setFiltersState(values)
        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }

    return (
        <div className='
            flex justify-between items-center
        '>
            <div className='flex items-center gap-[8px]'>
                <Typography className='text-gray-700'>
                    {t('filters.period')}
                </Typography>
                <FilterDateItem
                    isLoadingFetch={isLoadingFetch}
                    placement={'bottom-start'}
                    control={control}
                    fieldName={'periodFrom'}
                    label={t("filters.period_from")}
                    datesData={datesData}
                    handleCloseFilter={handleCloseFilter}
                    isDisabled={(date: string) => {
                        return moment(filtersState.periodTo, 'MM-YYYY').isBefore(moment(date, 'MM-YYYY'))
                    }}
                />
                <span className='rotate-90'>
                    <ArrowAccordionIcon />
                </span>
                <FilterDateItem
                    isLoadingFetch={isLoadingFetch}
                    placement={'bottom-end'}
                    control={control}
                    fieldName={'periodTo'}
                    label={t("filters.period_to")}
                    datesData={datesData}
                    handleCloseFilter={handleCloseFilter}
                    isDisabled={(date: string) => {
                        return moment(filtersState.periodFrom, 'MM-YYYY').isAfter(moment(date, 'MM-YYYY'))
                    }}
                />
            </div>
            <div className='flex items-center gap-[8px]'>
                <FilterSearch
                    onSearch={onSearch}
                />
                <Button color='black' onClick={handleCreate}>
                    {t('support.new_appeal')}
                </Button>
            </div>
        </div>
    )
}

export default SupportFilters