import { Typography } from '@material-tailwind/react'
import { Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

const SkeletonCons = () => {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const serviceBorder = service_id === 1 ? 'border-orange-500 border-[3px]' : service_id === 2 ? 'border-blue-600 border-[3px]' : 'border-gray-100 border-2'
    const serviceTitleText = service_id === 1 ? 'text-orange-500' : service_id === 2 ? 'text-blue-600' : 'text-gray-100'

    return (

        <div className={`bg-white ${serviceBorder} p-[48px] rounded-[24px] mb-[24px]`}>
            <div className='grid grid-cols-[38%_62%]'>
                <div>
                    <Typography variant='lead' className='text-gray-700 font-[400] mb-[8px]'>
                        {t('consumption.tko_text_title')}
                    </Typography>
                    <Typography variant='lead' className={`${serviceTitleText} font-bold text-[32px]`}>
                        {t('consumption.all_tko_text_title')}
                    </Typography>
                </div>
                <div>
                    <div className='flex flex-col gap-[32px]'>
                        <div className='grid grid-cols-4 gap-[32px] text-gray-600'>
                            <div>{t('consumption.period')}</div>
                            <div>{t('consumption.declared')}</div>
                            <div>{t('consumption.detailing')}</div>
                            <div>{t('consumption.fact')}</div>
                        </div>
                        {
                            Array.from(Array(3).keys()).map(key => (
                                <div key={key} className='grid grid-cols-4 gap-[32px] text-gray-600'>
                                    <Skeleton className='max-w-[120px]' />
                                    <Skeleton className='max-w-[120px]' />
                                    <Skeleton className='max-w-[120px]' />
                                    <Skeleton className='max-w-[120px]' />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonCons
