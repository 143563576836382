import React, { useState } from 'react'
import DrawerCompanyMenu from '../mobile/DrawerCompanyMenu'
import { Button } from '@material-tailwind/react';

const HeaderContractMobile = ({ data }: { data: any }) => {

    const [isOpenCard, setIsOpenCard] = useState<boolean>(false);

    const closeDrawer = () => {
        setIsOpenCard(false)
    }
    const openDrawer = () => {
        setIsOpenCard(true)
    }

    return (
        data ?
            <div className='lap:hidden'>
                <Button size='sm' color='white' className='bg-gray-100 border-0 w-[32px] h-[32px] !p-[8px] flex flex-col justify-center gap-[3px]' onClick={openDrawer}>
                    <span className="block w-full h-[2px] bg-gray-800 rounded"></span>
                    <span className="block w-full h-[2px] bg-gray-800 rounded"></span>
                    <span className="block w-full h-[2px] bg-gray-800 rounded"></span>
                </Button>
                <DrawerCompanyMenu
                    isOpen={isOpenCard}
                    closeDrawer={closeDrawer}
                    modalValue={data}
                />
            </div>
            :
            <></>
    )
}

export default HeaderContractMobile