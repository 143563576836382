import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./style/index.css";
import './i18n';
import { ThemeProvider } from "@material-tailwind/react";
import theme from "./theme";
import { Provider } from "react-redux";
import { store } from "./store/store";

import AppRouter from "./AppRouter";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider value={theme}>
        <Router>
          <AppRouter />
        </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);
