import { Drawer, IconButton, Typography } from '@material-tailwind/react'
import { Divider } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon'
import { toStringPrice } from '../../../utils/utils'
import { DrawerDataType } from './DaysGraphs'
import MonthGraph from './MonthGraph'

const DrawerGraph = ({ open, closeDrawer, drawerValue }: {
    open: boolean,
    closeDrawer: any,
    drawerValue: DrawerDataType | null
}) => {
    const { t } = useTranslation()

    const TableHead = () => (
        <div className='grid grid-cols-5'>
            <div className='flex flex-col items-center gap-[4px]'>
                <Typography variant="lead" className='text-[14px] font-normal text-gray-800'>
                    {t(`factual.hour_title`)}
                </Typography>
                <Typography variant="lead" className='text-[14px] font-normal text-gray-500'>
                    {t(`factual.hour_val`)}
                </Typography>
            </div>
            <div className='flex flex-col items-center gap-[4px]'>
                <Typography variant="lead" className='text-[14px] font-normal text-gray-800'>
                    {t(`factual.declared_title`)}
                </Typography>
                <Typography variant="lead" className='text-[14px] font-normal text-gray-500'>
                    {t(`kvHour`)}
                </Typography>
            </div>
            <div className='flex flex-col items-center gap-[4px]'>
                <Typography variant="lead" className='text-[14px] font-normal text-gray-800'>
                    {t(`factual.cons_title`)}
                </Typography>
                <Typography variant="lead" className='text-[14px] font-normal text-gray-500'>
                    {t(`kvHour`)}
                </Typography>
            </div>
            <div className='flex flex-col items-center gap-[4px]'>
                <Typography variant="lead" className='text-[14px] font-normal text-gray-800'>
                    {t(`factual.tarif_title`)}
                </Typography>
                <Typography variant="lead" className='text-[14px] font-normal text-gray-500'>
                    {t(`grnKvtGod`)}
                </Typography>
            </div>
            <div className='flex flex-col items-center gap-[4px]'>
                <Typography variant="lead" className='text-[14px] font-normal text-gray-800'>
                    {t(`factual.amount_title`)}
                </Typography>
                <Typography variant="lead" className='text-[14px] font-normal text-gray-500'>
                    {t(`grn`)}
                </Typography>
            </div>
        </div>
    )
    const TableRows = () => {
        const rowsLength = Math.max(
            drawerValue?.data_declared?.length || 0,
            drawerValue?.data_cons?.length || 0,
            drawerValue?.price?.length || 0,
            drawerValue?.amount?.length || 0,
        )
        return (
            rowsLength > 0 ?
                <>
                    {
                        Array.from(Array(rowsLength).keys()).map(index => (
                            <div key={index} className='grid grid-cols-5 text-center mb-[16px] text-gray-800 font-normal text-[16px]'>
                                <div>
                                    {`${index < 10 ? "0" + index : index}-${index < 9 ? "0" + (index + 1) : index + 1}`}
                                </div>
                                <div>
                                    {toStringPrice(drawerValue?.data_declared?.[index] || 0)}
                                </div>
                                <div>
                                    {toStringPrice(drawerValue?.data_cons?.[index] || 0)}
                                </div>
                                <div>
                                    {toStringPrice(drawerValue?.price?.[index] || 0)}
                                </div>
                                <div>
                                    {toStringPrice(drawerValue?.amount?.[index] || 0)}
                                </div>
                            </div>
                        ))
                    }
                </>
                :
                <div className='grid grid-cols-5 text-center'>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                </div>
        )
    }
    return (
        <Drawer
            open={open}
            onClose={closeDrawer}
            className={`!p-[30px] ${!open && '!left-0'}`}
            // size={width / 2}
            size={1200}
        >
            <div className="mb-[30px] flex items-center justify-between">
                <Typography variant="h5" className='text-[24px] font-medium text-gray-800'>
                    {t('factual.view_factual_title')}
                </Typography>
                <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={closeDrawer}>
                    <CloseDrawerIcon />
                </IconButton>
            </div>
            {
                drawerValue && (
                    <div className='overflow-auto max-h-[calc(100vh-130px)] pr-[30px] pb-[30px]'>
                        <div className='bg-white border-2 border-gray-100 rounded-[16px] p-[24px] mb-[32px]'>
                            <Typography variant="h5" className='text-[20px] font-medium text-gray-800 mb-[32px]'>
                                {drawerValue.day} {t(`r_month_${drawerValue.month}`)} {drawerValue.year}
                            </Typography>
                            <MonthGraph
                                tooltipDate={false}
                                data_cons={drawerValue.data_cons}
                                data_declared={drawerValue.data_declared}
                            />
                        </div>
                        <div className='bg-white border-2 border-gray-100 rounded-[16px] p-[24px]'>
                            <TableHead />
                            <hr className='my-[16px] border-gray-100' />

                            <TableRows />

                            <hr className='my-[16px] border-gray-100' />
                            <TableHead />
                        </div>
                    </div>
                )
            }
        </Drawer>
    )
}

export default DrawerGraph
