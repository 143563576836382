import { Button, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import MoreDotsIcon from '../../../images/icons/MoreDotsIcon'
import { TypeTkoInfo } from '../_types'

const ItemTkoInfo = ({ tko, handleSetDeclared }: { tko: TypeTkoInfo, handleSetDeclared: any }) => {

    const { t } = useTranslation()

    return (
        <>
            <div className="flex flex-col">
                <div className='flex items-center gap-[24px] mb-[24px]'>
                    <Menu placement="bottom-end">
                        <MenuHandler>
                            <Button
                                color="white"
                                size='sm'
                                className='w-[40px] h-[40px] flex items-center justify-center'
                                onClick={(event: any) => {
                                    event.stopPropagation()
                                }}
                            >
                                <MoreDotsIcon />
                            </Button>
                        </MenuHandler>
                        <MenuList>
                            <MenuItem className='block px-[12px] py-[9px]' onClick={() => handleSetDeclared('declared', tko)}>
                                {t('declared.declared_last_year')}
                            </MenuItem>
                            <MenuItem className='block px-[12px] py-[9px] ' onClick={() => handleSetDeclared('fact', tko)}>
                                {t('declared.fact_last_year')}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    {/* <Button
                        color="white"
                        size='sm'
                        className={`w-[40px] h-[40px] flex items-center justify-center`}
                    // onClick={() => handleChangeName(tko)}
                    // onClick={(event: any) => {
                    //     event.stopPropagation()
                    //     handleCopy(copyText)
                    // }}
                    >
                        <MoreDotsIcon />
                    </Button> */}
                    <div className='flex flex-col gap-[4px]'>
                        <Typography variant='lead' className='text-gray-700 font-[400]'>
                            {t('consumption.tko_text_title')}
                        </Typography>
                        <Typography variant='lead' className='text-gray-800 font-medium text-[24px]'>
                            {tko.name}
                        </Typography>
                    </div>
                </div>
                <div className='flex flex-col gap-[8px]'>
                    <div className='flex items-baseline gap-[8px]'>
                        <Typography variant='lead' className='text-gray-700 font-[400] mb-[4px]'>
                            {t('consumption.eic')}
                        </Typography>
                        <Typography variant='lead' className='text-gray-800 font-medium'>
                            {tko.eic}
                        </Typography>
                    </div>
                    <div className='flex items-baseline gap-[8px]'>
                        <Typography variant='lead' className='text-gray-700 font-[400] mb-[4px]'>
                            {t('consumption.class')}
                        </Typography>
                        <Typography variant='lead' className='text-gray-800 font-medium'>
                            {tko.class}
                        </Typography>
                    </div>
                    <div className='flex items-baseline gap-[8px]'>
                        <Typography variant='lead' className='text-gray-700 font-[400] mb-[4px]'>
                            {t('consumption.group')}
                        </Typography>
                        <Typography variant='lead' className='text-gray-800 font-medium'>
                            {tko.group ? t(`contract_page.group_${tko.group}`) : ''}
                        </Typography>
                    </div>
                    {/* <div className='flex items-baseline gap-[8px]'>
                        <Typography variant='lead' className='text-gray-700 font-[400] mb-[4px]'>
                            {t('consumption.osr_name')}
                        </Typography>
                        <Typography variant='lead' className='text-gray-800 font-medium'>
                            {tko.osr_name}
                        </Typography>
                    </div> */}
                </div>
            </div>
            <div className="flex flex-col ml-[96px]">
                <Typography variant='lead' className='text-gray-700 font-[400]'>
                    {t('declared.middle_3_month')}
                </Typography>
                <Typography variant='lead' className='text-gray-800 font-medium text-[24px]'>
                    {typeof tko?.amount_3_month === 'number' ? (tko.amount_3_month + " " + t('kvHour')) : ' - '}
                </Typography>
            </div>


        </>
    )
}

export default ItemTkoInfo
