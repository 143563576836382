import { useTranslation } from "react-i18next"
import UserIcon from "../../images/icons/UserIcon"
import { Typography } from "@material-tailwind/react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"

const UserManager = ({ data }: any) => {

    const { t } = useTranslation()

    const { service_id } = useSelector((state: RootState) => state.contract)

    const borderService = service_id === 1 ? 'border-orange-500' : 'border-blue-600'

    return (
        <div className={`flex gap-[24px] rounded-[16px] p-[24px] border-2 ${borderService}`}>
            {
                data.avatar ?
                    <div className='w-[32px] h-[32px] des:w-[40px] des:h-[40px] rounded-full border-2 border-gray-200'>
                        <img src={data.avatar} alt='' className='w-full rounded-full' />
                    </div>
                    :
                    <div className='flex items-center justify-center bg-gray-800 w-[32px] h-[32px] des:w-[40px] des:h-[40px] rounded-full'>
                        <UserIcon />
                    </div>
            }
            <div className='flex flex-col '>
                <Typography variant='lead' className='text-[18px] text-gray-700 mb-[4px]'>
                    {t('profile_menu.your_manager')}
                </Typography>
                <Typography variant='lead' className='text-[24px] text-gray-800 font-normal mb-[24px]'>
                    {data.name ? data.name : ''}
                </Typography>
                <NavLink to={data.phone ? `tel:${data.phone}` : '#'}>
                    <Typography variant='lead' className='text-[18px] text-gray-700 mb-[4px]'>
                        {data.phone ? data.phone : ''}
                    </Typography>
                </NavLink>
                <NavLink to={data.email ? `mailto:${data.email}` : '#'}>
                    <Typography variant='lead' className='text-[18px] text-gray-700 mb-[4px]'>
                        {data.email ? data.email : ''}
                    </Typography>
                </NavLink>
            </div>
        </div>
    )
}

export default UserManager