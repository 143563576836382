import { IconProps } from './Arrow'

const AuthCheckIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path className={props.className ? props.className : ''} d="M9.94995 11.7L8.49995 10.275C8.31662 10.0917 8.08745 10 7.81245 10C7.53745 10 7.29995 10.1 7.09995 10.3C6.91662 10.4833 6.82495 10.7167 6.82495 11C6.82495 11.2833 6.91662 11.5167 7.09995 11.7L9.24995 13.85C9.44995 14.05 9.68328 14.15 9.94995 14.15C10.2166 14.15 10.45 14.05 10.65 13.85L14.9 9.6C15.1 9.4 15.1958 9.16666 15.1875 8.9C15.1791 8.63333 15.0833 8.4 14.9 8.2C14.7 8 14.4625 7.89583 14.1875 7.8875C13.9125 7.87916 13.675 7.975 13.475 8.175L9.94995 11.7ZM7.14995 20.75L5.69995 18.3L2.94995 17.7C2.69995 17.65 2.49995 17.5208 2.34995 17.3125C2.19995 17.1042 2.14162 16.875 2.17495 16.625L2.44995 13.8L0.574951 11.65C0.408285 11.4667 0.324951 11.25 0.324951 11C0.324951 10.75 0.408285 10.5333 0.574951 10.35L2.44995 8.2L2.17495 5.375C2.14162 5.125 2.19995 4.89583 2.34995 4.6875C2.49995 4.47916 2.69995 4.35 2.94995 4.3L5.69995 3.7L7.14995 1.25C7.28328 1.03333 7.46662 0.887497 7.69995 0.812497C7.93328 0.737497 8.16662 0.749997 8.39995 0.849997L11 1.95L13.6 0.849997C13.8333 0.749997 14.0666 0.737497 14.3 0.812497C14.5333 0.887497 14.7166 1.03333 14.85 1.25L16.3 3.7L19.05 4.3C19.3 4.35 19.5 4.47916 19.65 4.6875C19.8 4.89583 19.8583 5.125 19.825 5.375L19.55 8.2L21.425 10.35C21.5916 10.5333 21.675 10.75 21.675 11C21.675 11.25 21.5916 11.4667 21.425 11.65L19.55 13.8L19.825 16.625C19.8583 16.875 19.8 17.1042 19.65 17.3125C19.5 17.5208 19.3 17.65 19.05 17.7L16.3 18.3L14.85 20.75C14.7166 20.9667 14.5333 21.1125 14.3 21.1875C14.0666 21.2625 13.8333 21.25 13.6 21.15L11 20.05L8.39995 21.15C8.16662 21.25 7.93328 21.2625 7.69995 21.1875C7.46662 21.1125 7.28328 20.9667 7.14995 20.75ZM8.44995 18.95L11 17.85L13.6 18.95L15 16.55L17.75 15.9L17.5 13.1L19.35 11L17.5 8.85L17.75 6.05L15 5.45L13.55 3.05L11 4.15L8.39995 3.05L6.99995 5.45L4.24995 6.05L4.49995 8.85L2.64995 11L4.49995 13.1L4.24995 15.95L6.99995 16.55L8.44995 18.95Z" fill="#2D2B32" />
        </svg >


    )
}

export default AuthCheckIcon
