import { Typography } from '@material-tailwind/react'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useInvoicesViewQuery } from '../../store/auth/authService'
import { setContractId, setServiceId } from '../../store/service/contractSlice'
import { AppDispatch } from '../../store/store'
import { isIterableArray } from '../../utils/utils'
import InvoiceFilters from './InvoiceFilters'
import { InvoiceRowType } from './InvoicePage.types'
import InvoiceTableDumb from './InvoiceTableDumb'

import TableSortingCreator from '../tableHelpers/TableSortingCreator'
import PageLayout from '../../layouts/pagesLayouts/PageLayout'
import OverlayUnTouchable from '../../layouts/pagesLayouts/OverlayUnTouchable'
import TableLayout from '../../layouts/pagesLayouts/TableLayout'
import { Helmet } from 'react-helmet'


type FilterFormType = {
    contract_id: string | undefined,
    periodFrom?: string,
    periodTo?: string,
    type?: number[],
    balance?: number[],
    date_payment?: string[],
}


const InvoicesPage = () => {

    const { param_contract_id } = useParams()
    const { t } = useTranslation()

    const form: FilterFormType = {
        contract_id: param_contract_id,
        periodFrom: '',
        periodTo: '',
        type: [],
        balance: [],
        date_payment: [],
    }

    const [sendForm, setSendForm] = useState<FilterFormType>(form);
    const dispatch = useDispatch<AppDispatch>()


    const { currentData, error, isLoading, isFetching } = useInvoicesViewQuery(sendForm)

    // array all rows
    const [rows, setRows] = useState<InvoiceRowType[]>([]);

    // only visible rows
    const [tableRows, setTableRows] = useState<InvoiceRowType[]>([]);
    const [search, setSearch] = useState<string>('');

    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(true);

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (currentData?.service_id) dispatch(setServiceId(Number(currentData.service_id)))
    }, [currentData]);

    const setFormForFetch = (values: any) => {
        setIsLoadingTable(true)
        setTableRows([])
        setSendForm(prev => ({
            ...prev, ...{
                ...values,
                contract_id: param_contract_id
            }
        }))
    }


    useEffect(() => {
        if (
            (!isLoading && !isFetching && isIterableArray(tableRows)) ||
            (!isLoading && !isFetching && Array.isArray(currentData?.rows) && !isIterableArray(currentData?.rows))
        ) {
            setIsLoadingTable(false)
        }
    }, [isLoading, isFetching, tableRows, currentData?.rows, sendForm]);

    // ______CREATE_DATA
    useEffect(() => {
        if (isIterableArray(currentData?.rows)) {
            const res: InvoiceRowType[] = currentData.rows.map((item: InvoiceRowType) => createData(item))
            setRows(res)
        }
        else setRows([])
    }, [currentData]);

    useEffect(() => {
        onSearch(search)
    }, [rows]);


    const onSearch = (searchValue: string) => {
        setSearch(searchValue);
        const reg = new RegExp(searchValue.replaceAll(' ', ''), "gi");
        if (searchValue === "") {
            setTableRows(rows)
            return
        }

        if (isIterableArray(rows)) {
            const filteredData: InvoiceRowType[] = rows.filter((item: any) => {
                const keys = Object.keys(item);
                let matches = 0;
                keys.forEach((key) => {
                    let val = String(item[key]).replaceAll(' ', '')
                    if (reg.test(val)) matches++;
                });
                return matches > 0;
            });
            if (searchValue !== "" && filteredData.length > 0) {
                setTableRows(filteredData);
            }
            if (searchValue !== "" && filteredData.length === 0) {
                setTableRows([]);
            }
        }


    };


    function createData(data: InvoiceRowType) {
        return {
            key: data.key,
            id: data.id,
            period: data.period,
            period_sorter: data.period_sorter,
            number: data.number,
            invoice_type_table: t(`invoices_page.invoice_type_${data.invoice_type}`),
            invoice_type: data.invoice_type,
            balance_id_table: t(`balance_${data.balance_id}`),
            balance_id: data.balance_id,
            date_payment: data.date_payment,
            date_payment_sorter: data.date_payment_sorter,
            amount_with_nds: data.amount_with_nds,
            amount_for_payment: data.amount_for_payment,
            purpose: data.purpose,

            // for buttons
            version: data.version,
            filename: data.filename,
        };
    }

    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[64px]'>
                {t('invoices_page.invoices_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('invoices_page.invoices_title')}
                </title>
            </Helmet>
            <InvoiceFilters
                isLoadingFetch={isFetching}
                fetchPage={setFormForFetch}
                onSearch={onSearch}
            />
            <OverlayUnTouchable isOverlay={(isFetching || isLoading || !!error)}>
                {
                    error ?
                        <TableLayout>
                            <Typography variant='lead' className='text-[20px] font-medium text-center my-[40px]'>
                                {t('tables.error_on_loading')}
                            </Typography>
                        </TableLayout>
                        :
                        <TableSortingCreator
                            isLoadingTable={isLoadingTable}
                            Component={InvoiceTableDumb}
                            rows={tableRows}
                        />
                }
            </OverlayUnTouchable>
        </PageLayout>
    )
}

export default InvoicesPage
