import { IconProps } from './Arrow'

const ChangeIventIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="2" height="9" viewBox="0 0 2 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M1 0.799988V3.99999M1 7.19999L1 7.51999" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default ChangeIventIcon
