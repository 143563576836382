import { useState } from 'react'
import { Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { filtersInvoicesTypes, filtersPaymentsRowTypes, getBalancesByService } from '../tableHelpers/tableConfig'
import { useGetContractPeriodQuery } from '../../store/auth/authService'
import { useParams } from 'react-router-dom'
import { PaymentFormInput } from './PaymentsPage.types'
import FilterItem from '../tableHelpers/filters/FilterItem'
import FilterSearch from '../tableHelpers/filters/FilterSearch'
import FilterPeriod from '../tableHelpers/filters/FilterPeriod'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'


const PaymentsFilters = (props: any) => {

    const { fetchPage, isLoadingFetch, onSearch } = props

    const { t } = useTranslation()

    const { param_contract_id } = useParams()
    const { data } = useGetContractPeriodQuery({
        contract_id: param_contract_id,
        endOffset: 3
    })

    const defaultState: PaymentFormInput = {
        type: [],
        type_invoice: [],
        balance: [],
        date_payment: [],
    }
    const {
        control,
        getValues,
    } = useForm<PaymentFormInput>({
        defaultValues: defaultState,
    })

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const [filtersState, setFiltersState] = useState<PaymentFormInput>(defaultState);

    const handleCloseFilter = () => {
        const values = getValues()

        setFiltersState(values)

        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }


    return (

        <div className='
            flex justify-between items-center mb-[32px]
        '>
            <div className='flex items-center gap-[8px]'>
                <Typography className='text-gray-700'>
                    {t('filters.date_operation')}
                </Typography>
                <FilterPeriod
                    isLoadingFetch={isLoadingFetch}
                    control={control}
                    label={t('filters.date_operation')}
                    fieldName={'date_payment'}
                    defaultValue={defaultState.date_payment}
                    filterStateValue={filtersState.date_payment}
                    handleCloseFilter={handleCloseFilter}
                    datesData={data}
                />
            </div>
            <div className='flex items-center gap-[4px]'>
                <FilterItem
                    isLoadingFetch={isLoadingFetch}
                    control={control}
                    label={t('filters.invoice_type')}
                    fieldName={'type_invoice'}
                    defaultValue={defaultState.type_invoice}
                    filtersData={filtersInvoicesTypes}
                    handleCloseFilter={handleCloseFilter}
                />
                <FilterItem
                    isLoadingFetch={isLoadingFetch}
                    control={control}
                    label={t('filters.balance_type')}
                    fieldName={'balance'}
                    defaultValue={defaultState.balance}
                    filtersData={getBalancesByService(service_id)}
                    handleCloseFilter={handleCloseFilter}
                />
                <FilterItem
                    isLoadingFetch={isLoadingFetch}
                    control={control}
                    label={t('filters.action')}
                    fieldName={'type'}
                    defaultValue={defaultState.type}
                    filtersData={filtersPaymentsRowTypes}
                    handleCloseFilter={handleCloseFilter}
                />
                <FilterSearch
                    onSearch={onSearch}
                />
            </div>
        </div>
    )
}

export default PaymentsFilters
