import { Typography } from '@material-tailwind/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { RootState } from '../../../store/store'
import { isIterableArray } from '../../../utils/utils'
import { ContactsType } from '../ContractPage.types'

const ContactsContract = ({ data }: { data: ContactsType[] | undefined }) => {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const textService = service_id === 1 ? 'text-orange-600' : service_id === 2 ? 'text-blue-700' : 'text-gray-600'

    const nameParser = (name: string) => {
        const arr = name.split(' ')
        if (isIterableArray(arr)) {
            return <>
                {
                    arr.map((i: string, index: number) => (
                        index === 0 ?
                            <>{i} <br /> </>
                            :
                            i + ' '
                    ))
                }
            </>
        }
        return name
    }
    return (
        <>
            <Typography variant='h3' className='text-center text-gray-800 mb-[24px] tab:mb-[48px] lap:mb-[64px]'>
                {t('contract_page.contact_title')}
            </Typography>
            <div className='grid mob:grid-cols-2 tab:grid-cols-3 lap:grid-cols-4 gap-[8px] lap:gap-[24px] des:gap-[48px]'>
                {
                    data?.length &&
                    data.map((item, key) => (
                        <div key={key} className='bg-white border-2 border-gray-100 p-[24px] tab:p-[32px] rounded-[24px]  flex flex-col '>
                            <p className={`${textService} mb-[16px] text-[14px] tab:text-[16px]`}>
                                {item.job ? item.job : ''}
                            </p>
                            <p className='text-[18px] tab:text-[24px] text-gray-800 mb-[16px]'>
                                {nameParser(item.name)}
                            </p>
                            {
                                item?.emails?.length ?
                                    item.emails.map((email, key) => (
                                        <NavLink key={key} to={`mailto:${email}`} className='text-[14px] tab:text-[18px] text-gray-700 mb-[8px]'>
                                            {email}
                                        </NavLink>
                                    ))
                                    :
                                    ''
                            }
                            {
                                item?.phones?.length ?
                                    item.phones.map((phone, key) => (
                                        <NavLink key={key} to={`tel:${phone}`} className='text-[14px] tab:text-[18px] text-gray-700 mb-[8px]'>
                                            {phone}
                                        </NavLink>
                                    ))
                                    :
                                    ''
                            }
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default ContactsContract