import { IconProps } from './Arrow'

const SwitchElectroIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''}  viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.38676 10.5379H4.0496L3.26766 14.8703C3.22539 15.0816 3.25709 15.2824 3.36276 15.4726C3.46843 15.6628 3.61988 15.8072 3.81713 15.9059C4.01438 16.0045 4.21515 16.0256 4.41944 15.9693C4.62373 15.9129 4.79633 15.8002 4.93722 15.6311L11.1928 7.706C11.39 7.4524 11.4217 7.1847 11.2879 6.90292C11.154 6.62114 10.9251 6.48025 10.601 6.48025H7.60006L8.23407 1.07003C8.26225 0.844605 8.21646 0.636791 8.0967 0.446588C7.97694 0.256385 7.81139 0.12606 7.60006 0.0556149C7.38872 -0.0148306 7.17738 -0.0183529 6.96605 0.0450481C6.75471 0.108449 6.59269 0.238773 6.47997 0.436021L0.752752 9.37556C0.597771 9.62916 0.590727 9.88629 0.731618 10.1469C0.872509 10.4076 1.09089 10.5379 1.38676 10.5379Z" fill="#FDFDFD" />
        </svg>
    )
}

export default SwitchElectroIcon
