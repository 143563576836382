import { Spinner, Typography } from '@material-tailwind/react'
import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TableEmptyRows = ({ isLoadingTable }: { isLoadingTable: boolean }) => {
    const { t } = useTranslation()
    return (
        <TableRow>
            <TableCell colSpan={100} className='!border-0'>
                <div className='flex items-center justify-center min-h-[200px]'>
                    {
                        isLoadingTable ?
                            <Spinner />
                            :
                            <Typography variant='lead' className='text-[20px] font-medium text-center '>
                                {t('tables.nothing_to_show')}
                            </Typography>
                    }
                </div>
            </TableCell>
        </TableRow>
    )
}

export default TableEmptyRows