import { Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

const TkoInfoCard = ({ tko }: { tko: any }) => {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const serviceBorder = service_id === 1 ? 'border-orange-500 border-[2px]' : service_id === 2 ? 'border-blue-600 border-[2px]' : 'border-gray-100 border-2'

    const ItemText = ({ title, text }: any) => (
        <div className="flex flex-col gap-[4px]">
            <Typography variant='lead' className='text-gray-700 font-[400]'>
                {title}
            </Typography>
            <Typography variant='lead' className='text-gray-800 font-medium text-[24px]'>
                {text}
            </Typography>
        </div>
    )

    return (
        <div className={`${serviceBorder} rounded-[24px] p-[48px] flex items-start justify-between `}>
            {
                tko.month && tko.year &&
                <ItemText title={t('consumption.period')} text={t(`month_${tko.month}`) + ' ' + tko.year} />
            }
            {
                tko.name &&
                <ItemText title={t('consumption.tko_text_title')} text={tko.name} />
            }
            {
                tko.eic &&
                <ItemText title={t('consumption.eic')} text={tko.eic} />
            }
            {
                tko.class &&
                <ItemText title={t('consumption.class')} text={tko.class} />
            }
            {
                tko.group &&
                <ItemText title={t('consumption.group')} text={tko.group ? t(`contract_page.group_${tko.group}`) : ''} />
            }
            {
                tko.osr_name &&
                <ItemText title={t('consumption.osr_name')} text={tko.osr_name} />
            }
        </div>
    )
}

export default TkoInfoCard
