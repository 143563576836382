import { Button, Typography } from '@material-tailwind/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PageLayout from '../../../layouts/pagesLayouts/PageLayout'
import AmountTitle from '../AmountTitle'
import TkoInfoCard from '../TkoInfoCard'
import { TypeTkoInfo } from '../_types'
import { Helmet } from 'react-helmet'


type FactualTkoInfo = {
    month: string,
    year: number,
    name: string,
    eic: string,
    class: number,
    group: number,
    osr_name: string
}
type FactualPageSample = {
    service_id: number,
    contract_id: string,
    total: number,
    tko?: FactualTkoInfo
}


type FactualDataSample = {
    active: boolean,
    day: string,
    total: number,
    data_cons: number[],
    data_declared: number[]
}

const tko: FactualTkoInfo = {
    month: '05',
    year: 2023,
    "name": "New name 2131",
    "group": 1,
    "class": 1,
    "eic": "55555R2271309341К",
    "osr_name": "Оператор1"
}

const samplePageData: FactualPageSample = {
    service_id: 1,
    contract_id: '2',
    total: 1000,
    tko: tko
}


const sampleData: FactualDataSample = {
    active: true,
    "day": '15',
    "total": 5250,
    data_declared:
        [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
    data_cons:
        [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],

}
const EditFactual = () => {
    const { t } = useTranslation()
    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[32px]'>
                {t('factual.view_factual_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('factual.view_factual_title')}
                </title>
            </Helmet>
            {tko && <TkoInfoCard tko={tko} />}

            <div className="flex justify-between items-center my-[32px]">
                <AmountTitle
                    text={t('consumption.amount_total_month')}
                    amount={samplePageData.total}
                    amountTitle={t('kvHour')}
                    isFetching={false}
                />
                <Button color='black'>
                    {t('consumption.download_excel')}
                </Button>
            </div>
            <div className={`rounded-[24px] p-[48px] bg-white border-2 border-gray-100`}>
                <div className='mb-[32px]'>
                    <Typography variant='lead' className='text-gray-800 font-medium text-[24px]'>
                        {t('factual.view_factual_title')}
                    </Typography>
                </div>
                <div className='grid grid-cols-7 gap-[16px]'>
                    <div className={`rounded-[16px] p-[24px] flex flex-col gap-[8px] 
                        border-2 ${sampleData.active ? 'border-gray-100' : 'border-gray-50'} `}
                    >
                        <Typography
                            variant='lead'
                            className={`text-[14px]
                                ${sampleData.active ? 'text-gray-700 font-normal' : 'text-gray-500 font-medium'}  
                            `}
                        >
                            {sampleData.day}
                        </Typography>
                        <Typography
                            variant='lead'
                            className={`${sampleData.active ? 'text-gray-700 font-normal' : 'text-gray-500 font-medium'}AppRouter`}
                        >
                            {sampleData.total}
                            {' '}
                            <span className={`text-gray-600 font-medium text-[14px] `}>
                                {t('kvHour')}
                            </span>
                        </Typography>
                    </div>
                </div>
            </div>

        </PageLayout>
    )
}

export default EditFactual