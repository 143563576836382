import { IconProps } from './Arrow'

const FileIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M4.5 14.4005H9.9C10.155 14.4005 10.3687 14.3142 10.5412 14.1417C10.7137 13.9692 10.8 13.7555 10.8 13.5005C10.8 13.2455 10.7137 13.0317 10.5412 12.8592C10.3687 12.6867 10.155 12.6005 9.9 12.6005H4.5C4.245 12.6005 4.03125 12.6867 3.85875 12.8592C3.68625 13.0317 3.6 13.2455 3.6 13.5005C3.6 13.7555 3.68625 13.9692 3.85875 14.1417C4.03125 14.3142 4.245 14.4005 4.5 14.4005ZM4.5 10.8005H9.9C10.155 10.8005 10.3687 10.7142 10.5412 10.5417C10.7137 10.3692 10.8 10.1555 10.8 9.90049C10.8 9.64549 10.7137 9.43174 10.5412 9.25924C10.3687 9.08674 10.155 9.00049 9.9 9.00049H4.5C4.245 9.00049 4.03125 9.08674 3.85875 9.25924C3.68625 9.43174 3.6 9.64549 3.6 9.90049C3.6 10.1555 3.68625 10.3692 3.85875 10.5417C4.03125 10.7142 4.245 10.8005 4.5 10.8005ZM1.8 18.0005C1.305 18.0005 0.88125 17.8242 0.52875 17.4717C0.17625 17.1192 0 16.6955 0 16.2005V1.80049C0 1.30549 0.17625 0.881738 0.52875 0.529238C0.88125 0.176738 1.305 0.000488281 1.8 0.000488281H8.2575C8.4975 0.000488281 8.72625 0.0454883 8.94375 0.135488C9.16125 0.225488 9.3525 0.352988 9.5175 0.517988L13.8825 4.88299C14.0475 5.04799 14.175 5.23924 14.265 5.45674C14.355 5.67424 14.4 5.90299 14.4 6.14299V16.2005C14.4 16.6955 14.2237 17.1192 13.8712 17.4717C13.5187 17.8242 13.095 18.0005 12.6 18.0005H1.8ZM8.1 5.40049V1.80049H1.8V16.2005H12.6V6.30049H9C8.745 6.30049 8.53125 6.21424 8.35875 6.04174C8.18625 5.86924 8.1 5.65549 8.1 5.40049Z" fill="#444248" />
        </svg>
    )
}

export default FileIcon
