import { Drawer, IconButton, Typography } from '@material-tailwind/react'
import React from 'react'
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon'
import { useTranslation } from 'react-i18next'

const DrawerNotification = ({ open, handleClose }: any) => {

    const { t } = useTranslation()

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            className={`p-4 ${!open && '!left-0'}`}
            size={500}
        >
            <div className="mb-6 flex items-center justify-between">
                <Typography variant="lead" className='text-[24px] font-medium' >
                    {t('notification')}
                </Typography>
                <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={handleClose}>
                    <CloseDrawerIcon />
                </IconButton>
            </div>
        </Drawer>
    )
}

export default DrawerNotification