
import Menu from './Menu'
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import BalanceBadges from '../BalanceBadges';

const HeaderContract = ({isFetching}: any) => {

    const { service_id, contract_id, total } = useSelector((state: RootState) => state.contract)

    return (
        contract_id ?
            <div className='hidden lap:flex justify-between items-end  border-t border-gray-100'>
                <Menu />
                {
                    <BalanceBadges
                        isFetching={isFetching}
                        service_id={service_id}
                        items={total ? total : {}}
                        className={' !px-[16px] !py-[10px] !bg-gray-100'}
                        contClassName='my-[12px] grid grid-cols-2 lap2:flex'
                    />
                }
            </div>
            :
            <></>

    )
}

export default HeaderContract