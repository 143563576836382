
import { Button, Menu, MenuHandler, MenuItem, MenuList, Spinner, Typography } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BalanceBadges from '../../components/BalanceBadges'
import MoreDotsIcon from '../../images/icons/MoreDotsIcon'
import { useIndexContractsQuery } from '../../store/auth/authService'
import { setContractId, setServiceId } from '../../store/service/contractSlice'
import { AppDispatch, RootState } from '../../store/store'
import { isIterableArray, numWord } from '../../utils/utils'
import CompaniesSkeleton from './CompaniesSkeleton'
import CompanyTable from './CompanyTable'
import Filters from './Filters'
import TableToolbar from '../tableHelpers/TableToolbar'
import { setCrumbsContract } from '../../store/service/headerSlice'
import { Helmet } from 'react-helmet'
import PageLayout from '../../layouts/pagesLayouts/PageLayout'
import TableLayout from '../../layouts/pagesLayouts/TableLayout'

const CompaniesPage = () => {

    const { t } = useTranslation()

    const dispatch = useDispatch<AppDispatch>()
    const { service_id } = useSelector((state: RootState) => state.contract)

    const [filterStatus, setFilterStatus] = useState<number[]>([]);
    const [filterContract, setFilterContract] = useState<number[]>([]);

    const { data, error, isLoading, isFetching, refetch } = useIndexContractsQuery<any>({
        service_id: service_id || 1
    })

    const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false); // drawer 

    const [selectedContracts, setSelectedContracts] = useState<{ [key: string]: number[] }>({}); // объект выбранных контрактов 
    const [formattedSelected, setFormattedSelected] = useState<number[]>([]); // массив выбранных id всех контрактов


    useEffect(() => {
        dispatch(setContractId(0))
        dispatch(setCrumbsContract([]))
    }, []);

    useEffect(() => {
        // при изменении сервиса - чистим фильтры
        // console.log('service_id clear', service_id)
        setFilterContract([])
        setFilterStatus([])
        if (!service_id) dispatch(setServiceId(1))
    }, [service_id]);

    useEffect(() => {
        const formatterIds = () => {
            let res: number[] = []
            Object.keys(selectedContracts).map(key => {
                if (selectedContracts[key]) res = [...res, ...selectedContracts[key]]
            })

            setFormattedSelected(res)
        }
        if (isIterableArray(Object.keys(selectedContracts))) formatterIds()
        else setFormattedSelected([])
    }, [selectedContracts]);

    const handleSetSelected = (contractSel: { [key: string]: number[] }) => {
        setSelectedContracts(prev => ({
            ...prev,
            ...contractSel
        }))
    }
    const handleResetSelected = (contractSel?: { [key: string]: number[] }) => {
        setSelectedContracts({})
    }

    const handleUpdatePage = (data: any) => {
        // console.log('UPDATE PAGE', data) 
        setSelectedContracts({})
        setFilterContract(isIterableArray(data.contract_id) ? data.contract_id : [])
        setFilterStatus(isIterableArray(data.status) ? data.status : [])
    }


    return (
        // px-[48px]
        <PageLayout>
            <div className='pb-[128px]'>
                <Helmet>
                    <title>
                        {t('companies.title_page')}
                    </title>
                </Helmet>
                <TableToolbar isOpen={formattedSelected?.length > 0} >
                    {/* <Button color='white' className='w-[50px] h-[50px] px-0 border-0' onClick={() => handleResetSelected()}>X</Button> */}
                    <Typography
                        color="inherit"
                    // component="div" 
                    >
                        {t('selected')}
                        <span className='font-bold'> {formattedSelected.length}  {t(`mainpage.active_count_${numWord(formattedSelected.length)}`)}</span>
                    </Typography>
                    <Menu placement="top" >
                        <MenuHandler>
                            <Button color='light-blue' className='w-60 flex items-center justify-between'>
                                {t('action')}
                                <MoreDotsIcon />
                            </Button>
                        </MenuHandler>
                        <MenuList className='w-60'>
                            <MenuItem>Menu Item 1</MenuItem>
                            <MenuItem>Menu Item 2</MenuItem>
                            <MenuItem>Menu Item 3</MenuItem>
                        </MenuList>
                    </Menu>
                </TableToolbar>

                <div className='w-full flex flex-col  tab:flex-row items-center justify-between '>
                    <Typography variant='h3' className='text-center tab:text-left  mb-[24px] tab:mb-0 grow'>
                        {t('companies.title_page')}
                    </Typography>
                    <BalanceBadges
                        service_id={service_id}
                        isFetching={isFetching}
                        className='flex-col w-full tab:!w-[auto]'
                        contClassName={'!grid grid-cols-2 tab:!flex w-full tab:!w-[auto]'}
                        items={data?.total}
                    />
                </div>

                <Filters
                    isFetching={isFetching}
                    isOpenFilters={isOpenFilters}
                    setIsOpenFilters={setIsOpenFilters}

                    defaultValue={{
                        status: filterStatus,
                        contracts: filterContract
                    }}
                    legalFilters={data?.legalFilters}
                    handleUpdatePage={handleUpdatePage}
                />

                {
                    isFetching ?
                        <div>
                            <div className='hidden tab:block'>
                                <CompaniesSkeleton service_id={service_id} />
                            </div>
                            <Spinner className='tab:hidden mx-auto my-[30px]' />
                        </div>
                        :
                        data ?
                            isIterableArray(data.contracts) &&
                            data.contracts.map((contract: any, key: number) => {
                                const isShow = isIterableArray(filterContract) ? filterContract.find(item => item === contract.id) : true
                                return isShow ? (
                                    <div key={key}
                                        className='bg-white border-2 border-gray-100
                                        rounded-[16px] p-[16px] mb-[24px]
                                        lap:rounded-24 lap:p-[24px] des:mb-[32px]
                                        '
                                    >
                                        <CompanyTable
                                            filterStatus={filterStatus}
                                            contract={contract}
                                            service_id={service_id}
                                            handleSetSelected={handleSetSelected}
                                            handleResetSelected={handleResetSelected}
                                        />
                                    </div>
                                )
                                    :
                                    ''
                            })
                            :
                            error ?
                                <TableLayout>
                                    <div className="flex flex-col items-center gap-[40px] my-[40px]">
                                        <Typography variant='lead' className='des:text-[20px] font-medium text-center '>
                                            {t('tables.error_on_loading')}
                                        </Typography>
                                        <Button onClick={refetch} color='black'>
                                            {t('reload')}
                                        </Button>
                                    </div>
                                </TableLayout>
                                :
                                ''
                }
            </div>
        </PageLayout>
    )
}

export default CompaniesPage
