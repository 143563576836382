import { Button, Drawer, IconButton, Spinner, Typography } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon'
import FilterIcon from '../../images/icons/FilterIcon'
import { isIterableArray } from '../../utils/utils'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { createPortal } from 'react-dom'
import AccordionFilterTable from '../companies/AccordionFilterTable'
import { filtersInvoicesTypes, getBalancesByService } from '../tableHelpers/tableConfig'
import FilterSearch from '../tableHelpers/filters/FilterSearch'
import FilterPeriodMobile from '../tableHelpers/filters/FilterPeriodMobile'
import FilterDateItemMobile from '../tableHelpers/filters/FilterDateItemMobile'
import moment from 'moment'

const statusesFilters = [
    {
        name: 'filters.status.archive',
        id: 2,
    },
    {
        name: 'filters.status.active',
        id: 1,
    },
]

const MobileFilters = ({
    defaultState,
    handleCloseFilters,
    handleClearFilter,
    control,
    onSearch,
    datesData,
    filtersState
}: {
    defaultState: any,
    handleCloseFilters: any,
    handleClearFilter: any,
    control: any,
    onSearch: any,
    datesData: any,
    filtersState: any
}) => {

    const { t } = useTranslation()


    const { service_id } = useSelector((state: RootState) => state.contract)

    const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false); // drawer 

    const size = window.innerHeight - 150


    const handleOpenFilters = () => {
        setIsOpenFilters(true)
        document.body.style.overflowY = 'hidden'
        document.body.style.paddingRight = '6px'
    }

    const closeDrawer = () => {
        document.body.style.overflowY = 'auto'
        document.body.style.paddingRight = "0px"
        setIsOpenFilters(false)
        handleCloseFilters()
    }
    const clearFilter = () => {
        document.body.style.overflowY = 'auto'
        document.body.style.paddingRight = "0px"
        setIsOpenFilters(false)
        handleClearFilter()
    }
    return (
        <div className='tab:hidden '>
            {
                createPortal(
                    <Drawer
                        placement={'bottom'}
                        open={isOpenFilters}
                        onClose={closeDrawer}
                        className={`tab:hidden p-4 !pb-[48px] ${!isOpenFilters && 'tab:!left-0'} !bottom-0  lap:!max-w-[500px]  flex flex-col overflow-auto no-scrollbar`}
                        size={size}
                    >
                        <div className="mb-6 flex items-center justify-between">
                            <div className='text-[13px] font-medium text-gray-600 hover:text-gray-500 transition-color easy duration-200 cursor-pointer'
                                onClick={clearFilter}
                            >
                                {t('filters.clear_title')}
                            </div>
                            {/* <Typography variant="lead" className='text-[18px] tab:text-[24px] font-medium' >
                                {t('filters.filters_title')}
                            </Typography> */}
                            <div className="flex items-center gap-[40px]">

                                <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={closeDrawer}>
                                    <CloseDrawerIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className='grow flex flex-col gap-[8px]'>

                            <FilterDateItemMobile
                                control={control}
                                fieldName={'periodFrom'}
                                label={t("filters.period_from")}
                                datesData={datesData}
                                isDisabled={(date: string) => {
                                    return moment(filtersState.periodTo, 'MM-YYYY').isBefore(moment(date, 'MM-YYYY'))
                                }}
                                isOpenDefault={false}
                            />
                            <FilterDateItemMobile
                                control={control}
                                fieldName={'periodTo'}
                                label={t("filters.period_to")}
                                datesData={datesData}
                                isDisabled={(date: string) => {
                                    return moment(filtersState.periodFrom, 'MM-YYYY').isAfter(moment(date, 'MM-YYYY'))
                                }}
                                isOpenDefault={false}
                            />
                            <AccordionFilterTable
                                control={control}
                                items={filtersInvoicesTypes}
                                filterName={'invoice_type'}
                                fieldName={'type'}
                                isOpenDefault={false}
                            />
                            <AccordionFilterTable
                                control={control}
                                items={getBalancesByService(service_id)}
                                filterName={'balance_type'}
                                fieldName={'balance'}
                                isOpenDefault={false}
                            />

                            <FilterPeriodMobile
                                control={control}
                                label={t('filters.payment_due')}
                                fieldName={'date_payment'}
                                defaultValue={defaultState.date_payment}
                                handleCloseFilter={handleCloseFilters}
                                datesData={datesData}
                                closeDrawer={closeDrawer}
                                isOpenDefault={false}
                            />

                            <Button
                                color='black'
                                className=' w-full mt-auto font-normal'
                                onClick={closeDrawer}
                            >
                                {t('filters.confirm')}
                            </Button>
                        </div>
                    </Drawer>,
                    document.getElementById('root')!
                )
            }
            <div className='grid grid-cols-2 gap-[8px] items-center pb-[24px] '>
                <Button
                    onClick={handleOpenFilters}
                    color='white'
                    className='flex justify-center items-center gap-[8px] text-center text-[14px]'
                >
                    <FilterIcon />
                    <span>{t('filters.filters_title')}</span>
                </Button>
                <FilterSearch
                    onSearch={onSearch}
                />
            </div>
        </div>
    )
}

export default MobileFilters