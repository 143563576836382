import { IconProps } from './Arrow'

const DownloadIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.37949 11.9236C7.25641 11.9236 7.14103 11.9022 7.03333 11.8595C6.92564 11.8167 6.82906 11.7526 6.74359 11.6672L3.10256 8.02613C2.91453 7.83963 2.82479 7.62211 2.83333 7.37357C2.84188 7.12485 2.93607 6.90647 3.1159 6.71844C3.31214 6.5304 3.53419 6.43639 3.78205 6.43639C4.02991 6.43639 4.24786 6.5304 4.4359 6.71844L6.46154 8.76972V0.923565C6.46154 0.662027 6.54949 0.442795 6.72538 0.265872C6.90128 0.0889492 7.11923 0.000488281 7.37923 0.000488281C7.63923 0.000488281 7.85897 0.0889492 8.03846 0.265872C8.21795 0.442795 8.30769 0.662027 8.30769 0.923565V8.76972L10.359 6.71844C10.5414 6.5304 10.7579 6.44066 11.0085 6.44921C11.2592 6.45775 11.4827 6.55604 11.679 6.74408C11.8588 6.93211 11.9487 7.15006 11.9487 7.39792C11.9487 7.64579 11.8547 7.86374 11.6667 8.05177L8.02564 11.6672C7.93333 11.7526 7.83333 11.8167 7.72564 11.8595C7.61795 11.9022 7.50256 11.9236 7.37949 11.9236ZM1.83897 16.0005C1.33094 16.0005 0.897436 15.8197 0.538462 15.4582C0.179487 15.0966 0 14.662 0 14.1543V13.2313C0 12.9697 0.0879485 12.7505 0.263846 12.5736C0.439743 12.3966 0.657692 12.3082 0.917692 12.3082C1.17769 12.3082 1.39744 12.3966 1.57692 12.5736C1.75641 12.7505 1.84615 12.9697 1.84615 13.2313V14.1543H12.9231V13.2313C12.9231 12.9697 13.011 12.7505 13.1869 12.5736C13.3628 12.3966 13.5808 12.3082 13.8408 12.3082C14.1008 12.3082 14.3205 12.3966 14.5 12.5736C14.6795 12.7505 14.7692 12.9697 14.7692 13.2313V14.1543C14.7692 14.662 14.5884 15.0966 14.2267 15.4582C13.865 15.8197 13.4301 16.0005 12.9221 16.0005H1.83897Z" fill="#444248" />
        </svg>

    )
}

export default DownloadIcon
