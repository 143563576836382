import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseApi } from '../../ConfigApi'
import { ConsunptionFormType, TypeConsumptionPage, TypeDetailingViewMatrix } from '../../pages/consumptions/_types'
import { ContractPagePeriodType, ContractPageType } from '../../pages/contract/ContractPage.types'
import { InvoiceRowType } from '../../pages/invoices/InvoicePage.types'
import { MainPageType } from '../../pages/main/MainPage.types'
import { isIterableArray } from '../../utils/utils'
import { AppealType, ContractsArrType } from '../../pages/support/types'
import { UserProfileType } from '../../pages/userProfile/types'

const CACHE_DEFAULT_TIME = 0
export const authApi = createApi({
    reducerPath: 'authApi',
    keepUnusedDataFor: 0,
    // keepUnusedDataFor: 120,
    baseQuery: fetchBaseQuery({
        baseUrl: baseApi,
        prepareHeaders: (headers, { getState }: any) => {
            const token = getState().auth.token
            if (token) {
                // include token in req header
                headers.set('authorization', `${token}`)
                return headers
            }
            else {
                window.location.href = '/auth'
            }
        },
    }),
    endpoints: (builder) => ({
        contractBalancesView: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
            }) => ({
                url: '/api/ca/view-balance',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                return { ...response, ...{ legal_address: response.address } }
            }
        }),
        invoiceHistoryView: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                invoiceId: number,
            }) => ({
                url: '/api/invoice/view-saldo',
                method: 'POST',
                body
            })
        }),
        invoicesView: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                type: number[],
                periodFrom: string,
                periodTo: string,
                balance: number[],
                date_payment: string[]
            }) => ({
                url: '/api/invoice/view-filter',
                method: 'POST',
                body
            })
        }),
        actsView: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                type: number[],
                periodFrom: string,
                periodTo: string,
                balance: number[],
                date_payment: string[]
            }) => ({
                url: '/api/acts/view-filter',
                method: 'POST',
                body
            })
        }),
        paymentsView: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                type: number[],
                type_invoice: number[],
                balance: number[],
                date_payment: string[]
            }) => ({
                url: '/api/payments/view-filter',
                method: 'POST',
                body
            })
        }),
        consumptionView: builder.query<TypeConsumptionPage, ConsunptionFormType>({
            query: (body) => ({
                url: '/api/ca/consumption/view-all',
                method: 'POST',
                body
            })
        }),
        consumptionViewAllTko: builder.query<any, {}>({
            query: (body: {
                contract_id: number
            }) => ({
                url: '/api/ca/consumption/all-tko',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                const replaced = isIterableArray(response) ? response.map((i: any) => ({
                    value: i.id,
                    label: i.name
                })) : []
                return replaced
            }
        }),
        viewDeclared: builder.query<any, {}>({
            query: (body: {
                contract_id: number
            }) => ({
                url: '/api/ca/consumption/view-declared',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                const formData = isIterableArray(response?.data) ? response.data.reduce((acc: any, item: any) => {
                    if (isIterableArray(item.data)) {
                        item.data.map((tko: any) => {
                            acc = {
                                ...acc,
                                ... { [`${item.tko.id}-${tko.month}-${tko.year}`]: typeof tko.declared?.amount === 'number' ? tko.declared?.amount : '' }
                            }
                        })
                    }
                    return acc
                }, {})
                    :
                    []
                return { ...response, ...{ formData: formData } }
            }
        }),
        viewDetailingInfo: builder.query<any, {
            id: number,
            contract_id: number
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-detailing/view',
                method: 'POST',
                body
            }),
        }),
        viewDetailingMatrix: builder.query<any, {
            tko_id: number,
            month: string,
            year: number,
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-detailing/view-matrix',
                method: 'POST',
                body
            }),
        }),
        viewDetailingAll: builder.query<any, {
            arr_id: number[],
            contract_id: number,
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-detailing/view-matrix-all',
                method: 'POST',
                body
            }),
        }),
        viewFactAll: builder.query<any, {
            fact_id: number[],
            contract_id: number,
            type: number,
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-fact/view-matrix-all',
                method: 'POST',
                body
            }),
        }),
        viewFactual: builder.query<any, {
            // fact_id: number[],
            id: number,
            contract_id: number,
            type: number,
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-fact/view-matrix',
                method: 'POST',
                body
            }),
        }),
        viewFactInfoTko: builder.query<any, {
            id?: number,
            contract_id: number
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-fact/info_tko',
                method: 'POST',
                body
            }),
        }),
        getContractPeriod: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { contract_id: number, endOffset?: number }) => ({
                url: '/api/ca/get-date-select',
                method: 'POST',
                body
            }),
        }),
        caView: builder.query<ContractPageType, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { contract_id: number }) => ({
                url: '/api/ca/view',
                method: 'POST',
                body
            }),
        }),
        caViewPeriod: builder.query<ContractPagePeriodType, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { contract_id: number, month: string, year: string }) => ({
                url: '/api/ca/view-period',
                method: 'POST',
                body
            })
        }),
        requestGetContractsView: builder.query<ContractsArrType[], {
            service_id: number,
        }>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { service_id: number }) => ({
                url: '/api/cabinet/profile-request-getcontracts',
                method: 'POST',
                body
            })
        }),
        profileRequestsView: builder.query<AppealType[], {
            periodFrom: string,
            periodTo: string,
        }>({
            query: (body) => ({
                url: '/api/cabinet/profile-requests',
                method: 'POST',
                body
            })
        }),
        profileInfoView: builder.query<UserProfileType, any>({
            query: () => ({
                url: '/api/cabinet/profile-getinfo',
                method: 'GET'
            })
        }),
        getInfoManagerView: builder.query<{
            name: string,
            phone: string,
            email: string,
            avatar: string,
        }, {
            contract_id: number
        }>({
            keepUnusedDataFor: 120,
            query: (body) => ({
                url: `/api/cabinet/profile-getinfomanager/${body.contract_id}`,
                method: 'GET'
            })
        }),
        indexContracts: builder.query({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { service_id: number }) => ({
                url: '/api/cabinet/index-contracts',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                const legalFilters = isIterableArray(response.contracts) ? response.contracts.reduce((acc: any, item: any) => {
                    acc.push({
                        id: Number(item.id),
                        name: item.name
                    })
                    return acc
                }, []) : []
                return { ...response, ...{ legalFilters: legalFilters } }
            }

        }),
        // main
        indexAll: builder.query<MainPageType, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (props = '') => `/api/cabinet/index-all`,
        }),
    }),
})

export const {
    useIndexAllQuery,
    useIndexContractsQuery,
    useCaViewQuery,
    useCaViewPeriodQuery,
    useInvoicesViewQuery,
    useInvoiceHistoryViewQuery,
    useGetContractPeriodQuery,
    useActsViewQuery,
    usePaymentsViewQuery,
    useConsumptionViewQuery,
    useConsumptionViewAllTkoQuery,
    useViewDeclaredQuery,
    useViewDetailingInfoQuery,
    useViewDetailingMatrixQuery,
    useViewFactAllQuery,
    useViewFactInfoTkoQuery,
    useViewFactualQuery,
    useViewDetailingAllQuery,
    useContractBalancesViewQuery,
    useProfileInfoViewQuery,
    useRequestGetContractsViewQuery,
    useProfileRequestsViewQuery,
    useGetInfoManagerViewQuery
} = authApi
