import { Typography } from '@material-tailwind/react'
import { Skeleton, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import BalanceBadges from '../../components/BalanceBadges'

const CompaniesSkeleton = ({ service_id }: { service_id: number }) => {
    return (
        <div
            className='bg-white border-2 border-gray-100 rounded-24 p-[24px] mb-[32px]'
        >

            <div className='flex flex-col items-start lap:flex-row lap:items-center justify-between py-[15px] min-h-[80px]'>
                <Typography variant='h5' className='lap:px-[16px] font-normal text-[24px] w-[200px] mb-[12px] lap:mb-0'>
                    <Skeleton animation="wave" variant="text" />
                </Typography>
                <div>
                    <BalanceBadges
                        isFetching={true}
                        service_id={service_id}
                        className=' w-full des:w-auto !px-[16px] !py-[10px] !gap-[8px] !bg-gray-100 text-[16px]'
                        items={{
                            balance_1: 0,
                        }}
                    />
                </div>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableCell
                            sx={{
                                border: 'none', lineHeight: '120%',
                                borderTopLeftRadius: 16,
                                borderBottomLeftRadius: 16,

                            }} component="th" scope="row" className='w-[16px]'>
                            <Skeleton animation="wave" className='w-[16px] !h-[26px] ' />
                        </TableCell>
                        <TableCell
                            sx={{
                                border: 'none', lineHeight: '120%',
                                borderTopLeftRadius: 16,
                                borderBottomLeftRadius: 16,

                            }} component="th" scope="row" className='w-[25%]'>
                            <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell
                            sx={{
                                border: 'none', lineHeight: '120%',
                                borderTopLeftRadius: 16,
                                borderBottomLeftRadius: 16,

                            }} className='w-[10%]'>
                            <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell
                            sx={{
                                border: 'none', lineHeight: '120%',
                                borderTopLeftRadius: 16,
                                borderBottomLeftRadius: 16,

                            }}  >
                            <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell
                            sx={{
                                border: 'none', lineHeight: '120%',
                                borderTopLeftRadius: 16,
                                borderBottomLeftRadius: 16,

                            }}  >
                            <Skeleton animation="wave" variant="text" />
                        </TableCell>
                    </TableHead>
                    {
                        [1, 2, 3].map(i => (
                            <TableRow key={i}>
                                {/* className='table-row-border' */}
                                <TableCell
                                    sx={{
                                        border: 'none', lineHeight: '120%',
                                        borderTopLeftRadius: 16,
                                        borderBottomLeftRadius: 16,

                                    }} component="th" scope="row">
                                    <Skeleton animation="wave" className='w-[16px] !h-[26px] ' />
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: 'none', lineHeight: '120%',
                                        borderTopLeftRadius: 16,
                                        borderBottomLeftRadius: 16,

                                    }} component="th" scope="row">
                                    <Skeleton animation="wave" variant="text" />
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: 'none', lineHeight: '120%',
                                        borderTopLeftRadius: 16,
                                        borderBottomLeftRadius: 16,

                                    }}>
                                    <Skeleton animation="wave" variant="text" />
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: 'none', lineHeight: '120%',
                                        borderTopLeftRadius: 16,
                                        borderBottomLeftRadius: 16,

                                    }}>
                                    <Skeleton animation="wave" variant="text" />
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: 'none', lineHeight: '120%',
                                        borderTopLeftRadius: 16,
                                        borderBottomLeftRadius: 16,

                                    }}>
                                    <Skeleton animation="wave" variant="text" />
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </Table>
            </TableContainer>

        </div>
    )
}

export default CompaniesSkeleton