import React, { useState } from 'react'
import { isIterableArray } from '../../../utils/utils'
import DrawerGraph from './DrawerGraph';
import ItemDayGraph from './ItemDayGraph'

export type DrawerDataType = {
    data_cons: number[],
    data_declared: number[],
    price: number[],
    amount: number[],
    total: number,
    day: string,
    month: string,
    year: number
}

const DaysGraphs = ({ array_cons, month, year }: { array_cons: any, month: any, year: any }) => {

    const [isDrawer, setIsDrawer] = useState<boolean>(false);
    const [drawerValue, setDrawerValue] = useState<DrawerDataType | null>(null);

    const closeDrawer = () => {
        setIsDrawer(false)
        setDrawerValue(null)
    }

    const openDrawer = (data: DrawerDataType) => {
        setIsDrawer(true)
        setDrawerValue(data)
    }


    return (
        <>
            <DrawerGraph
                open={isDrawer}
                closeDrawer={closeDrawer}
                drawerValue={drawerValue}
            />
            {
                isIterableArray(array_cons) ?
                    <div className='grid grid-cols-7 gap-[16px]'>
                        {
                            array_cons.map((item: any, key: number) => (
                                <ItemDayGraph
                                    key={key}
                                    isWeekend={(key + 1) % 7 === 0 }
                                    item={item}
                                    month={month}
                                    year={year}
                                    openDrawer={openDrawer}
                                />
                            ))
                        }
                    </div>
                    :
                    <></>
            }
        </>
    )
}

export default DaysGraphs
