import React, { useState } from 'react'
import ModalNewConnnection from './ModalNewConnnection'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import bgGas from '../../images/gas.png'
import bgElectro from '../../images/electro.png'
import { Button, Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'

const BannerNewConnection = () => {

  const { t } = useTranslation()

  const { service_id } = useSelector((state: RootState) => state.contract)

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }
  const handleOpenModal = () => {
    setIsOpenDrawer(false)
    setIsOpenModal(true)
  }



  return (
    <div>
      <ModalNewConnnection
        open={isOpenModal}
        handleClose={handleCloseModal}
      // defaultService={service_id === 1 ? 2 : 1}
      />
      <div
        className='des:min-h-[512px] py-[24px]  lap:py-[96px] des:py-[150px] px-[24px] tab:px-[130px] lap:px-[240px] des:px-[370px] rounded-[24px_24px_0_0] lap:rounded-[96px_96px_0_0]'
        style={{
          backgroundImage: `url(${service_id === 1 ? bgGas : bgElectro})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          zIndex: -1
        }}
      >
        <div className='flex flex-col lap:flex-row justify-center items-center gap-[24px] lap:gap-[96px]'>
          <div className='flex flex-col gap-[24px]'>
            <Typography variant='lead' className='text-[28px] leading-[1.2em] lap:text-[48px] des:text-[72px] text-black text-center lap:text-left'>
              {t('contract_page.banner_connection')}
              <br />
              <span className='font-bold'>{t(`contract_page.banner_connection_${service_id}`)}</span>
            </Typography>
            <Typography variant='lead' className='text-[16px] des:text-[28px] text-gray-800  text-center lap:text-left'>
              {t('contract_page.banner_connection_best_condition')}
            </Typography>
          </div>
          <Button
            onClick={handleOpenModal}
            color='white'
            className='text-[14px] tab:text-[20px] h-[40px] tab:h-[50px] w-full font-normal lap:w-auto lap:px-[100px] py-0 border-black hover:bg-black hover:text-white'
          >
            {t('contract_page.btn_banner_connection')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BannerNewConnection