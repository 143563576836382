import { IconProps } from './Arrow'

const CheckFilledIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9.003 14L15.5807 7.24733L14.1667 5.83333L9.003 11.172L6.174 8.343L4.76 9.757L9.003 14Z" fill="#4ADE80" />
        </svg>

    )
}

export default CheckFilledIcon
