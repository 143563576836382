import { Drawer, IconButton, Spinner, Typography } from '@material-tailwind/react'
import React from 'react'
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import UserIcon from '../../images/icons/UserIcon'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store/store'
import { removeToken } from '../../store/auth/authSlice'
import { useGetInfoManagerViewQuery } from '../../store/auth/authService'
import UserManager from './UserManager'

const DrawerUserMenu = ({ open, handleClose, handleNewConnection }: any) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()

    const { service_id, contract_id } = useSelector((state: RootState) => state.contract)

    const { currentData, isFetching } = useGetInfoManagerViewQuery({
        contract_id: contract_id || 0
    }, {
        skip: !open || !contract_id
    })

    const handleLogout = () => {
        localStorage.removeItem('userToken')
        navigate('/auth')
        dispatch(removeToken())
    }

    const textService = service_id === 1 ? 'hover:bg-blue-500' : 'hover:bg-orange-600'

    const placement = window.innerWidth > 1280 ? 'right' : 'bottom'
    const size = window.innerWidth > 1280 ? 500 : window.innerHeight - 150

    return (
        <Drawer
            placement={placement}
            size={size}
            open={open}
            onClose={handleClose}
            className={`p-4 ${open ? 'tab:!right-[12px]' : 'tab:!right-0'} left-0 right-0  tab:!left-auto !bottom-0  tab:!max-w-[500px]  flex flex-col overflow-auto`}
        // className={`p-4 !left-auto !right-[12px] ${!open && '!right-0'}`} 
        >
            <div className='flex flex-col h-[100%]'>
                <div className="mb-6 flex items-center justify-between">
                    <Typography variant="lead" className='text-[24px] font-medium' >
                        {t('profile_menu.profile_menu_title')}
                    </Typography>
                    <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={handleClose}>
                        <CloseDrawerIcon />
                    </IconButton>
                </div>
                <div className="flex flex-col items-start gap-[16px] my-[24px]">
                    <NavLink to='/profile' onClick={handleClose} className={'block leading-5 px-[16px] py-[5px] rounded-full bg-gray-700 text-white text-[18px] hover:bg-gray-800 transition-all'}>
                        {t('profile_menu.my_profile')}
                    </NavLink>
                    <NavLink to='/support' onClick={handleClose} className={'group flex items-center gap-[4px]'}>
                        <div className={'block leading-5 px-[16px] py-[5px] rounded-full bg-gray-700 text-white text-[18px] group-hover:bg-gray-800 transition-all'}>
                            {t('profile_menu.my_appeals')}
                        </div>
                    </NavLink>
                    <NavLink to='#' onClick={handleNewConnection} className={`${textService} block leading-5 px-[16px] py-[5px] rounded-full bg-gray-700 text-white text-[18px] transition-all`}>
                        {t('profile_menu.new_connection')}
                    </NavLink>
                    <NavLink to='#' onClick={handleLogout} className={'block leading-5 px-[16px] py-[5px] rounded-full bg-gray-700 text-white text-[18px] hover:bg-gray-800 transition-all'}>
                        {t('profile_menu.logout')}
                    </NavLink>
                </div>
                <div className='mt-auto'>
                    {
                        isFetching ?
                            <div className='flex items-center justify-center w-full min-h-200'>
                                <Spinner />
                            </div>
                            :
                            currentData ? <UserManager data={currentData} />
                                :
                                ''
                    }
                </div>
            </div>

        </Drawer>
    )
}

export default DrawerUserMenu