import React, { useState } from 'react'
import UserIcon from '../../images/icons/UserIcon'
import { Button, Spinner } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import ChangeAvatarModal from './ChangeAvatarModal'
import DeleteAvatarModal from './DeleteAvatarModal'



const UserAvatar = ({ user_avatar, isFetching, refetchPage }: { user_avatar: string, refetchPage: any, isFetching: boolean }) => {

    const { t } = useTranslation()

    const [isOpenChange, setIsOpenChange] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)

    // const [src, setSrc] = useState('')
    // const [preview, setPreview] = useState(null)
    // const onClose = () => {
    //     setPreview(null)
    // }

    // const onCrop = (preview: any) => {
    //     setPreview(preview)
    // }

    // const onBeforeFileLoad = (elem: any) => {
    //     if (elem.target.files[0].size > 71680) {
    //         alert("File is too big!");
    //         elem.target.value = "";
    //     };
    // }

    return (
        <div>
            <ChangeAvatarModal
                open={isOpenChange}
                handleClose={() => {
                    setIsOpenChange(false)
                }}
                handleConfirm={refetchPage}
            />
            <DeleteAvatarModal
                open={isOpenDelete}
                handleClose={() => {
                    setIsOpenDelete(false)
                }}
                handleConfirm={refetchPage}
            />
            {
                isFetching ?
                    <div className='flex items-center justify-center w-[96px] h-[96px] mx-auto'>
                        <Spinner />
                    </div>
                    :
                    user_avatar ?
                        <div className='w-[96px] h-[96px] rounded-full border-2 border-gray-200 mx-auto'>
                            <img src={user_avatar} alt='' className='w-full rounded-full' />
                        </div>
                        :
                        <div className='flex items-center justify-center w-[96px] h-[96px] rounded-full border-2 border-orange-600 mx-auto'>
                            <UserIcon className='stroke-orange-600 w-[32px] h-[32px]' />
                        </div>
            }

            <div className="flex items-center justify-center mt-[24px] gap-[8px]">

                <Button
                    color='black'
                    onClick={() => {
                        setIsOpenChange(true)
                    }}
                >
                    {t('profile.change_avatar')}
                </Button>
                {
                    user_avatar &&
                    <Button
                        color='white'
                        onClick={() => {
                            setIsOpenDelete(true)
                        }}
                    >
                        {t('profile.delete_avatar')}
                    </Button>
                }
            </div>
        </div>
    )
}

export default UserAvatar