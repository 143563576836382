import { IconProps } from './Arrow'

const ActIventIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M4.60039 0.799988V7.19999M1.40039 3.99999L1.40039 7.19999" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default ActIventIcon
