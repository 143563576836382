import { IconProps } from './Arrow'

const UserIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M1.64746 13.0001C3.29625 11.2513 5.53477 10.1766 8.0004 10.1766C10.466 10.1766 12.7045 11.2513 14.3533 13.0001M11.1769 4.17656C11.1769 5.93088 9.75473 7.35303 8.0004 7.35303C6.24609 7.35303 4.82393 5.93088 4.82393 4.17656C4.82393 2.42225 6.24609 1.00009 8.0004 1.00009C9.75473 1.00009 11.1769 2.42225 11.1769 4.17656Z" stroke="#FDFDFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default UserIcon
