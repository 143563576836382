import { useEffect, useState } from 'react'
import { Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import SelectIcon from '../../../images/icons/SelectIcon'
import { useController, useWatch } from 'react-hook-form'
import { isIterableArray } from '../../../utils/utils'
import CheckIcon from '../../../images/icons/CheckIcon'
import BtnFilter from './BtnFilter'

const FilterItem = (props: any) => {

    const { defaultValue, control, fieldName, label, filtersData, handleCloseFilter, isLoadingFetch } = props

    const { t } = useTranslation()
    const [open, setOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (!isLoadingFetch) setIsLoading(false)
    }, [isLoadingFetch]);

    const {
        field: { value, onChange },
    } = useController({
        name: fieldName,
        control,
        defaultValue: defaultValue
    });
    const checkboxIds = useWatch({ control, name: fieldName });

    const handleChange = (value: any) => {

        const newArray = [...checkboxIds];
        const item = value;

        if (newArray.length > 0) {
            const index = newArray.findIndex((x) => x === item);

            if (index === -1) {
                newArray.push(item);
            } else {
                newArray.splice(index, 1);
            }
        } else {
            newArray.push(item);
        }
        onChange(newArray);

        setIsLoading(true)
        handleCloseFilter()

    };

    const isChecked = (val: any) => isIterableArray(value) && value?.some((checked: any) => checked === val)

    const getVisibleName = () => {
        const firstLabel = isIterableArray(filtersData) ? filtersData.find((item: any) => item.value === value[0]).label : ''
        const selectedCount = value.length > 1 ? `(+${value.length - 1})` : ''

        return `${t(firstLabel)} ${selectedCount}`
    }

    const clearFilter = () => {
        onChange([])
        setOpen(false)
        handleCloseFilter()
        setIsLoading(true)
    }

    return (
        <div className='w-[240px]'>
            <Menu
                dismiss={{
                    itemPress: false,
                }}
                placement="bottom"
                open={open}
                handler={(e) => {
                    setOpen(e)
                }}
            >
                <MenuHandler>
                    <div className='w-[240px]'>
                        <BtnFilter
                            icon={<SelectIcon />}
                            isLoading={(isLoadingFetch && isLoading) || !isIterableArray(filtersData)}
                        >
                            {isIterableArray(value)
                                ?
                                getVisibleName()
                                :
                                label
                            }
                        </BtnFilter>
                    </div>
                </MenuHandler>
                <MenuList className='w-[240px]'>
                    <MenuItem
                        onClick={() => isIterableArray(value) && clearFilter()}
                        className={`flex items-center gap-[6px] py-[10px] px-[16px] text-gray-700`}
                    >
                        {!isIterableArray(value) && <CheckIcon className='!stroke-gray-700' />}
                        {t('all')}
                    </MenuItem>
                    {
                        isIterableArray(filtersData) && filtersData.map((item: any) => (
                            <MenuItem
                                key={item.value}
                                onClick={() => handleChange(item.value)}
                                className={`flex items-center gap-[6px] py-[10px] px-[16px] text-gray-700 ${isChecked(item.value) ? 'bg-gray-100 font-medium' : ''}`}
                            >
                                {
                                    isChecked(item.value) ?
                                        <CheckIcon className='!stroke-gray-700' />
                                        :
                                        ''
                                }
                                {t(item.label)}
                            </MenuItem>
                        ))
                    }
                </MenuList>
            </Menu>
        </div>
    )
}

export default FilterItem
