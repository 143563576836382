import React, { useEffect } from 'react'
// import { ReactComponent as Logo } from '../../images/logo.svg'
import logo from '../../images/logo.svg'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import { Button, Input, Typography } from '@material-tailwind/react';

import bgAuth from '../../images/auth-bg.jpg'
import AuthCheckIcon from '../../images/icons/AuthCheckIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

import { Helmet } from "react-helmet";

const AuthPage = ({ children }: { children: JSX.Element }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { token } = useSelector((state: RootState) => state.auth)

    // const bg = '#0F1522'
    useEffect(() => {
        if (token) navigate('/', { replace: true })
        // document.body.style.background = bg
        // return () => {
        //     document.body.style.background = 'inherit'
        // };
    }, [token]);

    return (
        <div>
            <Helmet>
                <title>{t('crumb_main')}</title>
            </Helmet>
            {
                !token &&
                <>
                    <div className='fixed t-0 r-0 b-0 l-0  min-h-[100vh] w-screen'
                        style={{
                            backgroundImage: `url(${bgAuth})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            zIndex: -1
                        }}
                    />
                    <div className='p-[32px] lap:p-[16px] min-h-[100vh] w-screen flex flex-col tab:flex-row' >
                        <div className='p-[24px] lap:p-[48px] des:p-[96px] flex flex-col lap:grow-[3] lap:w-[60%]  justify-between flex-wrap items-center lap:items-start'>
                            <Link
                                to={"#"}
                                className={'hidden tab:block py-[9px] px-[24px] bg-white text-gray-700 rounded-full hover:bg-blue-100 easy duration-300 '}
                            >
                                {t('auth.site_link')}
                            </Link>
                            <div>
                                <Typography variant='h4' className='text-white mb-[16px] tab:mb-[48px]  text-center tab:text-left' >
                                    <span dangerouslySetInnerHTML={{ __html: t('auth.name_provider') }} />
                                </Typography>
                                <Typography variant='h1' className='text-black font-medium mb-0 tab:mb-[48px] text-center tab:text-left' >
                                    <span dangerouslySetInnerHTML={{ __html: t('auth.title') }} />
                                </Typography>
                                <ul className='hidden lap:grid grid-cols-2 gap-[8px] text-gray-800 font-medium text-18 '>
                                    <li className='flex gap-[8px] items-center'>
                                        <AuthCheckIcon />
                                        {t('auth.title_list_text_1')}
                                    </li>
                                    <li className='flex gap-[8px] items-center'>
                                        <AuthCheckIcon />
                                        {t('auth.title_list_text_2')}
                                    </li>
                                    <li className='flex gap-[8px] items-center'>
                                        <AuthCheckIcon />
                                        {t('auth.title_list_text_3')}
                                    </li>
                                    <li className='flex gap-[8px] items-center'>
                                        <AuthCheckIcon />
                                        {t('auth.title_list_text_4')}
                                    </li>
                                </ul>
                            </div>
                            <p className='hidden tab:block text-gray-700 text-xs'>{t('auth.copyright_text')}</p>
                        </div>
                        <div className='p-[24px] lap:p-[48px] des:p-[96px] rounded-24 flex flex-col grow lap:grow-[2] lap:w-[40%] justify-center items-center bg-white'>
                            {children}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default AuthPage
