import { IconProps } from './Arrow'

const NotificationIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 17.0001C0.716667 17.0001 0.479167 16.9043 0.2875 16.7126C0.0958333 16.5209 0 16.2834 0 16.0001C0 15.7168 0.0958333 15.4793 0.2875 15.2876C0.479167 15.0959 0.716667 15.0001 1 15.0001H2V8.00009C2 6.61676 2.41667 5.38759 3.25 4.31259C4.08333 3.23759 5.16667 2.53342 6.5 2.20009V1.50009C6.5 1.08342 6.64583 0.729258 6.9375 0.437592C7.22917 0.145925 7.58333 9.15527e-05 8 9.15527e-05C8.41667 9.15527e-05 8.77083 0.145925 9.0625 0.437592C9.35417 0.729258 9.5 1.08342 9.5 1.50009V2.20009C10.8333 2.53342 11.9167 3.23759 12.75 4.31259C13.5833 5.38759 14 6.61676 14 8.00009V15.0001H15C15.2833 15.0001 15.5208 15.0959 15.7125 15.2876C15.9042 15.4793 16 15.7168 16 16.0001C16 16.2834 15.9042 16.5209 15.7125 16.7126C15.5208 16.9043 15.2833 17.0001 15 17.0001H1ZM8 20.0001C7.45 20.0001 6.97917 19.8043 6.5875 19.4126C6.19583 19.0209 6 18.5501 6 18.0001H10C10 18.5501 9.80417 19.0209 9.4125 19.4126C9.02083 19.8043 8.55 20.0001 8 20.0001ZM4 15.0001H12V8.00009C12 6.90009 11.6083 5.95843 10.825 5.17509C10.0417 4.39176 9.1 4.00009 8 4.00009C6.9 4.00009 5.95833 4.39176 5.175 5.17509C4.39167 5.95843 4 6.90009 4 8.00009V15.0001Z" fill="#444248" />
        </svg>
    )
}

export default NotificationIcon
