import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import localeEn from 'air-datepicker/locale/en';
import localeUa from 'air-datepicker/locale/uk';

import '../../../style/airpicker.scss'

import React, { useEffect, useRef } from "react";
import moment from "moment";
import { isIterableArray } from "../../../utils/utils";

function AirDatepickerReact(props: any) {

    const { onChange, selectedDates } = props
    let $input = useRef<any>();
    let dp = useRef<any>();

    useEffect(() => {
        if (!dp.current && $input.current) dp.current = new AirDatepicker($input.current, {
            inline: true,
            range: true,
            dynamicRange: true,
            locale: localeUa,
            selectedDates: (isIterableArray(selectedDates) && selectedDates.length === 2) ? [moment(selectedDates[0], 'DD.MM.YYYY').toISOString(), moment(selectedDates[1], 'DD.MM.YYYY').toISOString()] : [],
            // dateFormat: 'DD.MM.YYYY',
            onSelect: ({ formattedDate }: { formattedDate: any }) => {
                console.log(formattedDate)
                if (isIterableArray(formattedDate) && formattedDate.length === 2) {
                    onChange(formattedDate)
                }
                if (isIterableArray(formattedDate) && formattedDate.length === 1) {
                    onChange([formattedDate[0], formattedDate[0]])
                }
            }
        });

    }, []);

    useEffect(() => {
        if (selectedDates?.length === 0) dp.current.update({ selectedDates: [] });
    }, [selectedDates]);

    return <input ref={$input} type='hidden' />;
}

export default AirDatepickerReact;