import React, { useState } from 'react'
import NotificationIcon from '../../images/icons/NotificationIcon'
import DrawerNotification from './DrawerNotification'

const Notifications = ({ count = 0 }: { count?: number }) => {

    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)

    const handleClose = () => {
        setIsOpenDrawer(false)
    }
    const handleOpen = () => {
        setIsOpenDrawer(true)
    }
    return (
        <div className='hidden lap:block'>
            <DrawerNotification
                open={isOpenDrawer}
                handleClose={handleClose}
            />
            <div className='group cursor-pointer bg-gray-200 rounded-full py-[8px] px-[16px] flex items-center gap-[8px] ' onClick={handleOpen}>
                <NotificationIcon />
                <span className='flex items-center justify-center leading-none w-[24px] h-[24px] rounded-full text-[14px] text-gray-700 font-bold group-hover:bg-gray-800 group-hover:text-white transition-all'>
                    {count}
                </span>
            </div>
        </div>
    )
}

export default Notifications
