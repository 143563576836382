import { IconProps } from './Arrow'

const FilterIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M0.541571 1.67498C2.4749 4.15831 5.33324 7.83331 5.33324 7.83331V12C5.33324 12.9166 6.08324 13.6666 6.9999 13.6666C7.91657 13.6666 8.66657 12.9166 8.66657 12V7.83331C8.66657 7.83331 11.5249 4.15831 13.4582 1.67498C13.8832 1.12498 13.4916 0.333313 12.7916 0.333313H1.1999C0.508238 0.333313 0.116571 1.12498 0.541571 1.67498Z" fill="#444248" />
        </svg>
    )
}

export default FilterIcon
