import { Button, Drawer, IconButton, Menu, MenuHandler, MenuItem, MenuList, Typography, } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import { isIterableArray } from '../../../utils/utils';
import SelectIcon from '../../../images/icons/SelectIcon';
import UserIcon from '../../../images/icons/UserIcon';
import NotificationIcon from '../../../images/icons/NotificationIcon';
import PlanIventIcon from '../../../images/icons/PlanIventIcon';
import FinalIventIcon from '../../../images/icons/FinalIventIcon';
import ActIventIcon from '../../../images/icons/ActIventIcon';
import PaymentIventIcon from '../../../images/icons/PaymentIventIcon';
import ChangeIventIcon from '../../../images/icons/ChangeIventIcon';
import { ActiveDateType, CalendarIventType, CalendarType, IventType } from '../ContractPage.types';
import { Skeleton } from '@mui/material';
import CalendarDrawer from './CalendarDrawer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { validateHeaderValue } from 'http';

// type IventType = {
//     type: 'plan' | 'final' | 'act' | 'payment' | 'change_condition',
//     text: string,
//     target_text: string
// }

// type CalendarType = {
//     day: string,
//     active: boolean,
//     data: IventType[] | []
// }

export const IVENTS_DATA
// : {
//     [key: IventType]: {
//         icon: any,
//         name: string,
//         bg: string,
//     }
// } 
= {
    plan: {
        icon: <PlanIventIcon className='h-full w-full' />,
        name: 'contract_page.plan',
        bg: 'bg-ivent-plan'
    },
    final: {
        icon: <FinalIventIcon className='h-full w-full' />,
        name: 'contract_page.final',
        bg: 'bg-ivent-final'
    },
    act: {
        icon: <ActIventIcon className='h-full w-full' />,
        name: 'contract_page.act',
        bg: 'bg-ivent-act'
    },
    payment: {
        icon: <PaymentIventIcon className='h-full w-full' />,
        name: 'contract_page.payment',
        bg: 'bg-ivent-payment'

    },
    change_condition: {
        icon: <ChangeIventIcon className='h-full w-full' />,
        name: 'contract_page.change_condition',
        bg: 'bg-ivent-change'
    }
}

// const sampleIvent: IventType[] = [
//     {
//         type: 'plan',
//         text: 'PLAN',
//         target_text: 'acts №123998284'
//     },
//     {
//         type: 'final',
//         text: 'FINAL',
//         target_text: 'acts №123998284'
//     },
//     {
//         type: 'act',
//         text: 'ACTS',
//         target_text: 'acts №123998284'
//     },
// ]
// const sampleIvent2: IventType[] = [
//     {
//         type: 'payment',
//         text: 'PAYMENT',
//         target_text: 'acts №123998284'
//     },
//     {
//         type: 'change_condition',
//         text: 'CONDITION CHANGE',
//         target_text: 'acts №123998284'
//     },
// ]

// const getCalendarByMonth = (date: moment.Moment) => {

//     const dayOffset = moment(date).day() || 7
//     const lengthPrev = moment(date).subtract(1, 'months').daysInMonth()
//     const length = moment(date).daysInMonth()

//     const res = []

//     // добавляем дни прошлого месяца
//     if (dayOffset) {
//         for (let i = 0; i < dayOffset - 1; i++) {
//             res.unshift({
//                 day: String(lengthPrev - i),
//                 active: false,
//                 data: []
//             })
//         }
//     }

//     // добавляем дни текущего месяца
//     if (length > 0) {
//         for (let i = 1; i <= length; i++) {
//             res.push({
//                 day: String(i),
//                 active: true,
//                 data: i === 12 ? sampleIvent : i === 11 ? sampleIvent2 : []
//             })
//         }
//     }

//     // добавляем дни будущего месяца
//     for (let i = 1; i <= res.length % 7; i++) {
//         res.push({
//             day: String(i),
//             active: false,
//             data: []
//         })
//     }


//     return res
// }

const Calendar = ({ data, isFetching, activeDate }: { data: CalendarType[] | undefined, isFetching: boolean, activeDate: ActiveDateType }) => {

    const { t } = useTranslation()
    // const [calendar, setCalendar] = useState<CalendarType[]>([]);

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const [open, setOpen] = React.useState(false);
    const [drawerValue, setDrawerValue] = useState<CalendarType>();


    const handleClick = (event: React.MouseEvent<unknown>, data: CalendarType) => {
        setDrawerValue(data)
        openDrawer()
    };

    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    const isToday = (day: string) => {
        const today = moment().format('DD-MM-YYYY')
        const checked = `${day}-${activeDate.month}-${activeDate.year}`
        return today === checked
    }

    const todayService = service_id === 1 ? '!text-orange-500 !border-orange-100' : service_id === 2 ? '!text-blue-500 !border-blue-100' : ''

    // const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>

    const DayEvents = ({ data }: { data: CalendarIventType[] }) => {
        const types: IventType[] = data.map(i => i.type)
        const uniqueSet: any = new Set(types)
        const uniqueArr: IventType[] = Array.from(uniqueSet)
        return (
            <div className='mt-auto flex flex-wrap gap-[1px]'>
                {
                    uniqueArr.length > 0 && uniqueArr.map((val: IventType) => {
                        const { icon, bg } = IVENTS_DATA[val]
                        return (
                            <div key={val} className={`rounded-full w-[12px] h-[12px] p-[2px] border border-white ${bg}`}>
                                {icon}
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <>
            <CalendarDrawer
                activeDate={activeDate}
                open={open}
                closeDrawer={closeDrawer}
                drawerValue={drawerValue}
            />
            <div className='grow bg-white border-2 border-gray-100 rounded-[16px] p-[12px] tab:p-[24px] lap:px-[32px] lap:py-[24px]'>
                <div className='grid grid-cols-7  gap-[4px] tab:gap-[8px] mb-[36px] text-sm pt-[24px] tab:pt-0'>
                    {[1, 2, 3, 4, 5, 6, 7].map(i => <div key={i} className={`${i > 5 ? 'text-gray-500' : 'text-gray-700'}  `}>{t(`short_day_${i}`)}</div>)}
                </div>
                <div className='grid grid-cols-7  gap-[4px] tab:gap-[8px]'>
                    {
                        isFetching ?
                            Array.from(Array(35).keys()).map(i => (
                                <div
                                    key={i}
                                    className={`rounded-[8px] min-h-[64px]`}
                                >
                                    <Skeleton variant="rectangular" animation='pulse' className='!bg-gray-50 rounded-[6px]' height={64} />
                                </div>
                            ))
                            :
                            data?.length ?
                                data.map((day: CalendarType, key) => (
                                    <div
                                        style={{ transitionProperty: 'none !important' }}
                                        key={key}
                                        className={`
                                            ${day.active ? 'bg-gray-50 border-gray-100 font-bold text-gray-700' : 'text-gray-500 border-gray-50'}
                                            border-2 rounded-[8px] text-[14px] tab:text-[16px]
                                            py-[8px] px-[8px] tab:px-[16px] min-h-[58px] tab:min-h-[64px] flex flex-col justify--between
                                            transition-none hover:transition-all
                                            ${(day.active && isToday(day.day)) ? todayService : 'notoday'}
                                            ${isIterableArray(day.data) ? 'hover:bg-gray-100 hover:border-gray-200 hover:cursor-pointer' : ''}
                                        `}
                                        onClick={(event: any) => {
                                            if (isIterableArray(day.data)) {
                                                handleClick(event, day)
                                            }
                                        }}
                                    >
                                        {day.day}
                                        {
                                            isIterableArray(day.data) &&
                                            <DayEvents data={day.data} />

                                        }
                                    </div>
                                ))
                                :
                                <div className='min-h-[400px]' />
                    }
                </div>
                <div className='mt-[45px] flex flex-wrap gap-[15px] items-center justify-between'>
                    <div className='text-gray-800'>
                        {t('contract_page.type_ivent_title')}
                    </div>
                    <div className='flex flex-wrap gap-[5px]'>
                        {
                            Object.entries(IVENTS_DATA).map(([key, value]) => {
                                return (
                                    <div key={key} className={`flex  items-center gap-[5px] rounded px-[5px] py-[4px] ${value.bg} text-sm text-white`}>

                                        <div className={`rounded-full w-[16px] h-[16px] p-[3px] border border-white`}>
                                            {value.icon}
                                        </div>
                                        <span className='lowercase'>
                                            {t(value.name)}
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Calendar
