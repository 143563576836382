
import { useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, Checkbox, ListItem } from '@material-tailwind/react'

import { useTranslation } from 'react-i18next';
import ArrowAccordionIcon from '../../images/icons/ArrowAccordionIcon';
import { useController, useWatch } from 'react-hook-form';
import { FormControlLabel } from '@mui/material';

type FilterItemType = {
    id: number,
    name: string
}
type AccordionType = {
    control: any,
    isOpenDefault?: boolean,
    filterName: string,
    fieldName: string,
    items: FilterItemType[],
}
const AccordionFilter = (props: AccordionType) => {

    const { t } = useTranslation()

    const { items, filterName, fieldName, isOpenDefault = true, control } = props


    const [open, setOpen] = useState<boolean>(isOpenDefault);
    const toggleAccordion = () => setOpen(prev => !prev);

    const {
        field: { ref, value, onChange, ...inputProps },
        formState: { errors }
    } = useController({
        name: fieldName,
        control,
        defaultValue: []
    });

    const checkboxIds = useWatch({ control, name: fieldName });

    const handleChange = (value: any) => {

        const newArray = [...checkboxIds];
        const item = value;

        if (newArray.length > 0) {
            const index = newArray.findIndex((x) => x === item);

            if (index === -1) {
                newArray.push(item);
            } else {
                newArray.splice(index, 1);
            }
        } else {
            newArray.push(item);
        }
        onChange(newArray);
    };

    return (
        <Accordion
            open={open}
            icon={<div className={`${open ? '' : 'rotate-180'}`}><ArrowAccordionIcon /></div>}
            className='mb-[24px]'
        // animate={{
        //     mount: { scale: 1 },
        //     unmount: { scale: 0.95 },
        // }}
        >
            <ListItem className='p-0'>
                <AccordionHeader
                    className='bg-white border-2 border-gray-100 rounded p-[16px]'
                    onClick={toggleAccordion}
                >

                    <p className='text-sm text-gray-800 font-bold'>
                        {t(`filters.${filterName}`)}
                    </p>
                </AccordionHeader>
            </ListItem>
            <AccordionBody
                className='bg-white border-2 border-gray-100 rounded p-0 mt-[8px] tab:mt-[16px]'
            >
                {
                    items.map((item: any) => {
                        return (
                            <ListItem className='p-0' key={item.id} >
                                <FormControlLabel
                                    className='w-full py-[8px]  px-[16px] !m-0'
                                    control={
                                        <Checkbox
                                            checked={value?.some(
                                                (checked: any) => checked === item.id
                                            )}
                                            {...inputProps}
                                            inputRef={ref}
                                            onChange={() => handleChange(item.id)}
                                        />
                                    }
                                    label={t(item.name)}
                                />
                            </ListItem>
                        )
                    })
                }
            </AccordionBody>
        </Accordion>
    )
}

export default AccordionFilter