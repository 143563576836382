import { IconProps } from './Arrow'

const MoreDotsIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.99417 16.0007C1.4425 16.0007 0.972222 15.8043 0.583333 15.4116C0.194444 15.0188 0 14.5466 0 13.9949C0 13.4432 0.196389 12.973 0.589166 12.5841C0.981944 12.1952 1.45417 12.0007 2.00583 12.0007C2.5575 12.0007 3.02778 12.1971 3.41667 12.5899C3.80556 12.9827 4 13.4549 4 14.0066C4 14.5582 3.80361 15.0285 3.41083 15.4174C3.01805 15.8063 2.54583 16.0007 1.99417 16.0007ZM1.99417 10.0007C1.4425 10.0007 0.972222 9.80434 0.583333 9.41157C0.194444 9.01879 0 8.54657 0 7.9949C0 7.44323 0.196389 6.97295 0.589166 6.58407C0.981944 6.19518 1.45417 6.00073 2.00583 6.00073C2.5575 6.00073 3.02778 6.19712 3.41667 6.5899C3.80556 6.98268 4 7.4549 4 8.00657C4 8.55823 3.80361 9.02851 3.41083 9.4174C3.01805 9.80629 2.54583 10.0007 1.99417 10.0007ZM1.99417 4.00073C1.4425 4.00073 0.972222 3.80434 0.583333 3.41157C0.194444 3.01879 0 2.54657 0 1.9949C0 1.44323 0.196389 0.972955 0.589166 0.584066C0.981944 0.195177 1.45417 0.000732422 2.00583 0.000732422C2.5575 0.000732422 3.02778 0.197122 3.41667 0.5899C3.80556 0.982678 4 1.4549 4 2.00657C4 2.55823 3.80361 3.02851 3.41083 3.4174C3.01805 3.80629 2.54583 4.00073 1.99417 4.00073Z" fill="#444248" />
        </svg>
    )
}

export default MoreDotsIcon
