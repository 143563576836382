import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Spinner,
} from "@material-tailwind/react";
import axios from "axios";
import { ReactPortal, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import CloseDrawerIcon from "../../images/icons/CloseDrawerIcon";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import SelectContract from "../../pages/support/SelectContract";
import { useProfileInfoViewQuery, useRequestGetContractsViewQuery } from "../../store/auth/authService";
import SelectService from "../../pages/support/SelectService";
import { errorToast, successToast } from "../toasts/toasts";
import { baseApi } from "../../ConfigApi";

const ModalNewConnnection = ({ open, handleClose }: {
  open: boolean,
  handleClose: () => void,
  // defaultService?: number
}): ReactPortal | null => {


  const { t } = useTranslation()


  const dispatch = useDispatch<AppDispatch>()

  const { token } = useSelector((state: RootState) => state.auth)
  const { service_id, contract_id } = useSelector((state: RootState) => state.contract)

  const currentServiceId = service_id === 1 ? 2 : 1

  const [isLoadingSave, setIsLoadingSave] = useState(false)


  const { isFetching, currentData, error, refetch } = useProfileInfoViewQuery({}, {
    skip: !open
  })


  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    resetField
  } = useForm<{
    name: string,
    email: string,
    phones: string,
    service_id: number
  }>({
    defaultValues: {},
  })


  useEffect(() => {
    if (currentData) {
      setValue('email', currentData?.email || '')
      setValue('phones', currentData?.phones ? currentData.phones[0] : '')
      setValue('name', `${currentData?.last_name} ${currentData?.name} ${currentData?.middle_name}` || '')
    }
  }, [currentData])

  useEffect(() => {
    if (currentServiceId) {
      setValue('service_id', currentServiceId)
    }
  }, [currentServiceId])





  const onSubmit: SubmitHandler<{
    name: string,
    email: string,
    phones: string,
    service_id: number
  }> = (data) => {

    setIsLoadingSave(true)
    setIsLoadingSave(true)
    const formSend = {
      "type": 2,
      "contract_id": contract_id || 0,
      service_id: data.service_id
    }
    axios.post(`${baseApi}/api/cabinet/profile-requestconnect-create`, formSend,
      {
        headers: {
          'Authorization': token,
        }
      })
      .then((response: any) => {
        if (response.data.res === 'Error') {
          errorToast({
            title: t('new_connection.modal_title'),
            text: t('new_connection.error_on_request')
          })
        }
        else {
          successToast({
            title: t('new_connection.modal_title'),
            text: t('new_connection.success_on_request')
          })
          handleClose()
        }
      })
      .catch(error => {
        console.error(error);
        errorToast({
          title: t('new_connection.modal_title'),
          text: t('new_connection.error_on_request')
        })
      })
      .finally(() => {
        setIsLoadingSave(false)
      })
  }



  const textService = currentServiceId === 2 ? 'hover:bg-blue-500' : 'hover:bg-orange-600'

  return (
    createPortal(
      <>
        <Dialog open={open} handler={handleClose} className='!min-w-0 !max-w-[480px] bg-white p-[16px] lap:p-[32px] rounded-[16px] '>

          <DialogBody className="p-0">
            <div className="flex items-center justify-between mb-[32px]">
              <Typography variant="lead" className="text-[18px] lap:text-[24px] text-gray-800 font-medium ">
                {t('new_connection.modal_title')}
              </Typography>
              <div onClick={handleClose} className="rounded-full w-[32px] h-[32px] transition-all flex items-center justify-center active:bg-gray-200 hover:bg-gray-100 cursor-pointer">
                <CloseDrawerIcon />
              </div>
            </div>
            {
              isFetching ?
                <div className="min-h-[400px] flex items-center justify-center">
                  <Spinner />
                </div>
                :
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col gap-[16px]">
                    <div className="flex flex-col gap-[8px]">
                      <label>
                        {t('new_connection.name_user')}
                      </label>
                      <input
                        readOnly
                        {...register('name')}
                        className='border-2 border-gray-200 rounded w-full h-[50px] px-[16px] text-gray-700
                                hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                                focus:bg-white focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                            '
                        placeholder={t('new_connection.name_user')}
                      />
                    </div>
                    <div className="flex flex-col gap-[8px]">
                      <label>
                        {t('new_connection.email')}
                      </label>
                      <input
                        readOnly
                        {...register('email')}
                        className='border-2 border-gray-200 rounded w-full h-[50px] px-[16px] text-gray-700
                                hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                                focus:bg-white focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                            '
                        placeholder={t('new_connection.email')}
                      />
                    </div>
                    <div className="flex flex-col gap-[8px]">
                      <Typography variant='lead' className='text-gray-700 text-[14px]'>
                        {t('new_connection.select_service')}
                      </Typography>
                      <SelectService
                        setValue={setValue}
                        fieldName='service_id'
                        control={control}
                        defaultValue={currentServiceId || 1}
                      />
                    </div>
                    <div className="flex flex-col gap-[8px]">
                      <label>
                        {t('new_connection.phone')}
                      </label>
                      <input
                        readOnly
                        {...register('phones')}
                        className='border-2 border-gray-200 rounded w-full h-[50px] px-[16px] text-gray-700
                                hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                                focus:bg-white focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                            '
                        placeholder={t('new_connection.phone')}
                      />
                    </div>
                  </div>
                  <Button
                    className={`mt-[32px] w-full ${textService} flex justify-center items-center`}
                    color='black'
                    type='submit'
                    loading={isLoadingSave}
                  >
                    {t('new_connection.modal_confirm')}
                  </Button>
                </form>
            }
          </DialogBody>
        </Dialog>
      </>,
      document.getElementById('root')!
    )
  )
}

export default ModalNewConnnection