import { Button, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MoreDotsIcon from '../../images/icons/MoreDotsIcon';
import { RootState } from '../../store/store';
import { numWord } from '../../utils/utils';


interface TableToolbarProps {
    children: any,
    isOpen: boolean
}

const TableToolbar = (props: TableToolbarProps) => {

    const { children, isOpen } = props;
    const service_id = useSelector((state: RootState) => state.contract.service_id)

    return (
        <>
            <div
                className={`
                fixed z-11 left-0 right-[-6px] z-10 
                pt-[24px] pb-[48px]  tab:py-[36px] w-full bottom-0  
                flex flex-col tab:flex-row gap-[24px] tab:gap-[40px] items-center justify-center
                text-white
                easy duration-300 transition-all
                translate-y-[100%] ${isOpen && '!translate-y-0'}
                ${service_id === 1 && 'bg-orange-500'}
                ${service_id === 2 && 'bg-blue-600'}
                ${!service_id && 'bg-white text-gray-700'}
                rounded-t-[32px]
            `}
            >
                {children}
            </div>
        </>
    );
}


export default TableToolbar
