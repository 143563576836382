import toast from "react-hot-toast";
import CheckFilledIcon from "../../images/icons/CheckFilledIcon";
import CloseDrawerIcon from "../../images/icons/CloseDrawerIcon";

export const errorToast = ({ title, text }: { title: string, text?: string }) => (
    toast.custom(
        (t) => (
            <ToastItem
                title={title}
                text={text}
                status="error"
                close={() => toast.dismiss(t.id)}
            />
        ), {
        duration: 5000,
    }
    )
)
export const successToast = ({ title, text }: { title: string, text?: string }) => (
    toast.custom(
        (t) => (
            <ToastItem
                title={title}
                text={text}
                status="success"
                close={() => toast.dismiss(t.id)}
            />
        ),
        {
            duration: 2500
        }
    )
)

const ToastItem = ({ title, text, status, close }:
    {
        title: string,
        text?: string,
        close: () => void,
        status: 'error' | 'success'

    }) => (
    <div style={{ backdropFilter: 'blur(2px)', background: '#ffffff' }} className='transition-all min-w-[320px] max-w-[480px] flex items-center gap-[24px] border-2 border-gray-50 rounded-[16px] pl-[24px] pr-[36px] py-[20px]'>

        {
            status === 'error'
            &&
            <div className="flex items-center justify-center rounded-full min-w-[20px] h-[20px] bg-red-500">
                <CloseDrawerIcon className='stroke-white w-[10px] h-[10px] ' />
            </div>
        }
        {
            status === 'success'
            &&
            <CheckFilledIcon className='fill-green-500  w-[20px] h-[20px] ' />
        }
        <div className='flex flex-col gap-[4px] '>
            <span className='text-gray-800 text-[18px] font-medium'>{title}</span>
            {text && <span className='text-gray-800 text-[16px]'>{text}</span>}
        </div>
        {
            status === 'error'
            &&
            <button onClick={close}>X</button>
        }
    </div>
)