import { Button, Typography } from '@material-tailwind/react'
import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PageLayout from '../../../layouts/pagesLayouts/PageLayout'
import { useViewDetailingInfoQuery, useViewDetailingMatrixQuery } from '../../../store/auth/authService'
import { setContractId, setServiceId } from '../../../store/service/contractSlice'
import { AppDispatch } from '../../../store/store'
import { isIterableArray } from '../../../utils/utils'
import AmountTitle from '../AmountTitle'
import TkoInfoCard from '../TkoInfoCard'
import MatrixCreator from './MatrixCreator'
import MatrixDays from '../matrix/MatrixDays'
import ConsumptionsPage from '../../../trash/ConsumptionsPage'
import MatrixMonth from '../matrix/MatrixMonth2'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'

const ViewDetailing = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { param_contract_id, param_cons_id, param_year, param_month } = useParams()


    const dispatch = useDispatch<AppDispatch>()

    const { data, isLoading, error } = useViewDetailingInfoQuery({
        contract_id: Number(param_contract_id),
        id: Number(param_cons_id)
    })

    // console.log(infoTko)
    // const { data, isLoading, error } = useViewDetailingMatrixQuery({
    //     tko_id: Number(param_tko_id),
    //     month: param_month || '',
    //     year: param_year || '',
    // })


    // console.log(matrixData)

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
    }, [data]);

    console.log(data?.matrix_detailing);
    console.log(data?.matrix)

    const DownloadExcelButton = () => (
        <Button color='black'>
            {t('consumption.download_excel')}
        </Button>
    )

    const {
        control,
    } = useForm<any>()


    const handleNavigateBack = () => {
        if (location.state?.pathname) navigate(location.state.pathname + (location.state?.search ? location.state.search : ''))
        else navigate(`/consumptions/${param_contract_id}`)
    }

    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[32px]'>
                {t('detailing.view_detailing_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('detailing.view_detailing_title')}
                </title>
            </Helmet>
            {
                data?.tko && <TkoInfoCard tko={data.tko} />
            }

            {/* <div className='my-[32px] flex items-center justify-between'>
                <div className="flex flex-col gap-[32px]">
                    <AmountTitle
                        text={t('detailing.amount_total_month')}
                        amount={0}
                        amountTitle={t('kvHour')}
                        isFetching={false}
                    />
                </div>
                <div className="flex items-center gap-[8px]">
                    <Button
                        // disabled={!computedLink}
                        color='white'
                        className={`relative`}
                        onClick={() => {
                            navigate(`/consumptions/${param_contract_id}`)
                        }}
                    >
                        {t('to_back')}
                    </Button>
                    <Button color='black'>
                        {t('consumption.download_excel')}
                    </Button>
                </div>
            </div> */}

            {
                data?.matrix_detailing === 1 &&
                <MatrixDays
                    control={control}
                    data={data.matrix}
                    isEditAccess={false}
                    DownloadExcelButton={DownloadExcelButton}
                    amount={data?.amount}
                    handleNavigateBack={handleNavigateBack}
                />
            }
            {
                data?.matrix_detailing === 2 &&
                isIterableArray(data?.matrix) &&
                <MatrixMonth
                    amount={data?.amount}
                    daysLength={data.matrix[0].length}
                    isEditAccess={false}
                    detailing={data.matrix}
                    DownloadExcelButton={DownloadExcelButton}
                    handleNavigateBack={handleNavigateBack}
                />
                // <MatrixCreator
                //     isEditAccess={false}
                //     detailing={data.matrix}
                //     type_detailing={data.matrix_detailing}
                // />
            }
            {/* <ConsumptionsPage /> */}
        </PageLayout>
    )
}

export default ViewDetailing
