import { IconProps } from './Arrow'

const EyeIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 9.50012C9.97222 9.50012 10.7986 9.15984 11.4792 8.47929C12.1597 7.79873 12.5 6.97234 12.5 6.00012C12.5 5.0279 12.1597 4.20151 11.4792 3.52096C10.7986 2.8404 9.97222 2.50012 9 2.50012C8.02778 2.50012 7.20139 2.8404 6.52083 3.52096C5.84028 4.20151 5.5 5.0279 5.5 6.00012C5.5 6.97234 5.84028 7.79873 6.52083 8.47929C7.20139 9.15984 8.02778 9.50012 9 9.50012ZM9 8.00012C8.44444 8.00012 7.97222 7.80568 7.58333 7.41679C7.19444 7.0279 7 6.55568 7 6.00012C7 5.44457 7.19444 4.97234 7.58333 4.58346C7.97222 4.19457 8.44444 4.00012 9 4.00012C9.55556 4.00012 10.0278 4.19457 10.4167 4.58346C10.8056 4.97234 11 5.44457 11 6.00012C11 6.55568 10.8056 7.0279 10.4167 7.41679C10.0278 7.80568 9.55556 8.00012 9 8.00012ZM9 12.0001C7.01944 12.0001 5.21528 11.455 3.5875 10.3647C1.95972 9.27443 0.763889 7.81957 0 6.00012C0.763889 4.18068 1.95972 2.72582 3.5875 1.63554C5.21528 0.545261 7.01944 0.00012207 9 0.00012207C10.9806 0.00012207 12.7847 0.545261 14.4125 1.63554C16.0403 2.72582 17.2361 4.18068 18 6.00012C17.2361 7.81957 16.0403 9.27443 14.4125 10.3647C12.7847 11.455 10.9806 12.0001 9 12.0001ZM9 10.5001C10.5556 10.5001 11.9931 10.0973 13.3125 9.29179C14.6319 8.48623 15.6458 7.38901 16.3542 6.00012C15.6458 4.61123 14.6319 3.51401 13.3125 2.70846C11.9931 1.9029 10.5556 1.50012 9 1.50012C7.44444 1.50012 6.00694 1.9029 4.6875 2.70846C3.36806 3.51401 2.35417 4.61123 1.64583 6.00012C2.35417 7.38901 3.36806 8.48623 4.6875 9.29179C6.00694 10.0973 7.44444 10.5001 9 10.5001Z" fill="#444248" />
        </svg>
    )
}

export default EyeIcon
