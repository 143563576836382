import React from 'react'
import UserIcon from '../../images/icons/UserIcon'
import { useProfileInfoViewQuery } from '../../store/auth/authService'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

const UserAvatar = ({ handleOpenDrawer }: any) => {

    const { userInfo } = useSelector((state: RootState) => state.auth)

    return (
        <div className='cursor-pointer' onClick={handleOpenDrawer}>
            {
                userInfo?.user_avatar ?
                    <div className='flex items-center justify-center w-[32px] h-[32px] des:w-[40px] des:h-[40px] rounded-full border-2 border-gray-100 overflow-hidden'>
                        <img src={userInfo?.user_avatar} alt='' className='w-full ' />
                    </div>
                    :
                    <div className='flex items-center justify-center bg-gray-800 w-[32px] h-[32px] des:w-[40px] des:h-[40px] rounded-full'>
                        <UserIcon />
                    </div>
            }
        </div>
    )
}

export default UserAvatar