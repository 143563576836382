import { IconProps } from "./Arrow"

const SelectIcon = (props: IconProps) => {

    return (
        <svg className={props.className ? props.className : ''} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.16667 10.0001H8.83333C9.29167 10.0001 9.66667 9.62512 9.66667 9.16679C9.66667 8.70846 9.29167 8.33346 8.83333 8.33346H7.16667C6.70833 8.33346 6.33333 8.70846 6.33333 9.16679C6.33333 9.62512 6.70833 10.0001 7.16667 10.0001ZM0.5 0.833455C0.5 1.29179 0.875 1.66679 1.33333 1.66679H14.6667C15.125 1.66679 15.5 1.29179 15.5 0.833455C15.5 0.375122 15.125 0.00012207 14.6667 0.00012207H1.33333C0.875 0.00012207 0.5 0.375122 0.5 0.833455ZM3.83333 5.83346H12.1667C12.625 5.83346 13 5.45846 13 5.00012C13 4.54179 12.625 4.16679 12.1667 4.16679H3.83333C3.375 4.16679 3 4.54179 3 5.00012C3 5.45846 3.375 5.83346 3.83333 5.83346Z" fill="#444248" />
        </svg>

    )
}

export default SelectIcon