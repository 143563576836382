import { IconProps } from './Arrow'

const SwitchGasIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M6.54719 0.0396672C6.58367 0.015688 6.61551 0.0349212 6.6164 0.0785681C6.69088 3.43577 7.66925 5.95938 10.0345 8.27936C11.0393 9.26489 11.8889 10.5739 11.9424 12.0231C11.9452 12.0839 11.9452 12.0839 11.948 12.146C11.9826 13.5852 11.3831 14.8919 10.4305 15.9468C10.4129 15.9666 10.3952 15.9864 10.3771 16.0068C9.73025 16.7322 8.95715 17.2941 8.12219 17.7849C8.08015 17.8098 8.02596 17.8363 7.96885 17.8618C7.87552 17.9036 7.78016 17.78 7.8321 17.6919C8.12458 17.196 8.58384 16.122 8.24734 14.6423C8.17433 14.3212 7.97687 14.0295 7.85499 13.8724C7.80994 13.8143 7.72155 13.8289 7.6909 13.8958C7.53825 14.2285 7.1222 15.0289 6.60191 15.1548C6.82527 14.038 6.93245 12.9066 6.39882 11.8791C6.37676 11.8348 6.35473 11.7905 6.33274 11.7462C6.15645 11.3976 5.94203 11.0803 5.71488 10.7632C5.68915 10.7271 5.68915 10.7271 5.6629 10.6903C5.63687 10.6538 5.61081 10.6174 5.58475 10.5809C5.56772 10.5571 5.53024 10.5674 5.52783 10.5966C5.41075 11.9738 5.18562 13.3478 4.14478 14.3478C3.70776 14.7913 3.4396 15.3585 3.43355 15.9805C3.44156 16.663 3.75934 17.3218 4.20074 17.8389C4.21649 17.8546 4.35208 18.0166 4.27499 17.9986C2.1695 17.1918 0.120573 15.072 0.0506592 12.7396C0.0506599 9.61126 2.25405 7.72097 2.28839 7.84514C2.46539 8.46042 2.9442 9.29193 3.58935 9.56111C3.65621 9.58901 3.723 9.53203 3.71276 9.46031C3.62627 8.85491 3.32844 6.57715 3.55504 5.36358C3.90034 3.40278 4.91974 1.62754 6.31333 0.220483C6.36954 0.163416 6.47677 0.0859541 6.54719 0.0396672Z" fill="#FDFDFD" />
        </svg>
    )
}

export default SwitchGasIcon
