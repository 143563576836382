import { Button } from '@material-tailwind/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import AmountTitle from '../AmountTitle'

const MatrixAmountButtons = ({ DownloadExcelButton, isEditAccess, handleSave, amount, isLoadingSave, handleNavigateBack }: any) => {

    const { param_contract_id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (

        <div className='my-[32px] flex items-center justify-between'>
            <div className="flex flex-col gap-[32px]">
                {
                    amount !== false &&
                    <AmountTitle
                        text={t('consumption.amount_total_month')}
                        amount={amount}
                        amountTitle={t('kvHour')}
                        isFetching={false}
                    />
                }
            </div>
            <div className="flex items-center gap-[8px]">
                <Button
                    // disabled={!computedLink}
                    color='white'
                    className={`relative`}
                    onClick={handleNavigateBack}
                >
                    {t('to_back')}
                </Button>
                {
                    DownloadExcelButton &&
                    DownloadExcelButton()
                }
                {
                    isEditAccess &&
                    <Button color='black' onClick={handleSave} loading={isLoadingSave}>
                        {t('consumption.save_button')}
                    </Button>
                }
            </div>
        </div>
    )
}

export default MatrixAmountButtons
