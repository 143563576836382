import React from 'react'
import Chart, { CategoryScale, ChartOptions } from "chart.js/auto";


import { Line } from "react-chartjs-2";

Chart.register(CategoryScale);
 
const Graph = ({ data_declared, data_cons }) => {
    const dataGraph = {
        labels: [
            '', '', '', '', '', '', '', '', '', '',
            '', '', '', '', '', '', '', '', '', '', '', '', '',
        ],
        datasets: [
            {
                label: '',
                data: data_cons,
                borderColor: '#FF7340', // orange-500
                borderWidth: 1
            },
            {
                label: '',
                data: data_declared,
                borderColor: '#bfbfc1', // gray-400
                borderWidth: 1
            }
        ]
    }

    const options = {
        aspectRatio: 2,
        animation: false,
        layout: {
            // padding: -10,
            autoPadding: false,
            label :{
                boxHeight: 100
            }
        },
        elements: {
            point: {
                pointStyle: false,
            },
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
        },
        scales: {
            x: {
                offset: true,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                border: {
                    // display: false
                    width: 2,
                    color: '#e4e4e4',
                }
            },
            y: {
                min: 0,
                offset: true,
                ticks: {
                    display: false,
                    beginAtZero: false,
                },
                grid: {
                    display: false
                },
                border: {
                    width: 2,
                    color: '#e4e4e4',
                    // display: false
                }
            },
        },
    };
    return (
        // style={{ position: 'relative', height: 40, width: 'content-box', marginBottom: -10 }}
        <div className='mb-[-15px]'>
            <Line data={dataGraph} options={options} />
        </div>
    )
}

export default Graph
