

import { visuallyHidden } from '@mui/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, TableSortLabel } from '@mui/material';

import { Spinner, Typography } from '@material-tailwind/react';


import { useTranslation } from 'react-i18next';



import { isIterableArray, toFormatPrice, } from '../../utils/utils';
import { OrderType } from '../tableHelpers/tableConfig';
import { PaymentHeadCell, PaymentRowType } from './PaymentsPage.types';
import TableEmptyRows from '../tableHelpers/TableEmptyRows';

type PropsType = {
    isLoadingTable: boolean,
    rows: PaymentRowType[],
    visibleRows: PaymentRowType[],
    orderBy: keyof PaymentRowType,
    order: OrderType,
    service_id: any,
    handleRequestSort: any,
}
const PaymentsTableDumb = (props: PropsType): JSX.Element => {
 
    const { t } = useTranslation()
    const {
        isLoadingTable,
        rows,
        visibleRows,
        orderBy,
        order,
        service_id,
        handleRequestSort
    } = props

    // ______SERVICE COLORS

    const hoverRowService = service_id === 1 ? 'hover:!bg-orange-50' : service_id === 2 ? 'hover:!bg-blue-100' : 'hover:!bg-gray-200'
    const sortIconService = service_id === 1 ? 'svg-sort-orange' : service_id === 2 ? 'svg-sort-blue' : ''


    function TableHeadComponent() {
        const createSortHandler = (property: keyof PaymentRowType) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };
        return (
            <TableHead className='table-head-border border-none font-sans'>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={'normal'}
                            sx={{ paddingBottom: '0px !important', paddingTop: '0px !important', border: 'none' }}
                            className={`!font-sans ${headCell.className}`}
                        >
                            {
                                headCell.sorting ?
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                        className={`${sortIconService} text-base !text-gray text-nowrap`}
                                    >
                                        {t(headCell.label)}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden} >
                                                <>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </>
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    :
                                    <span className='text-base font-normal !text-gray-600 whitespace-nowrap'>
                                        {t(headCell.label)}
                                    </span>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    function TableRowsRender({ cells, row }: { cells: PaymentHeadCell[], row: PaymentRowType }) {
        return (
            <>
                {
                    cells.map((cell, index) => {
                        const key = cell.dataIndex
                        return (
                            <TableCell
                                key={cell.dataIndex}
                                align={cell.align}
                                sx={{
                                    border: 'none',
                                    lineHeight: '120%',
                                    borderTopLeftRadius: index === 0 ? 16 : 0,
                                    borderBottomLeftRadius: index === 0 ? 16 : 0,
                                    borderTopRightRadius: index === cells.length - 1 ? 16 : 0,
                                    borderBottomRightRadius: index === cells.length - 1 ? 16 : 0
                                }}
                                padding={'normal'}
                                className='!font-sans !text-gray-700 !text-[16px]'
                            >
                                {
                                    cell.render ?
                                        cell.render(row[key], row)
                                        :
                                        row[key]
                                }
                            </TableCell>
                        )
                    })
                }
            </>
        )
    }

    const headCells: PaymentHeadCell[] = [
        {
            dataIndex: 'period', // какое поле в таблице
            id: 'period_sorter', // какое поле в сортировке
            align: 'left',
            sorting: true,
            label: 'payments_page.period',
            className: 'min-w-[80px]',
            render: (item: any, record?: any) => (
                <span className='text-gray-700'>
                    {item}
                </span>
            )
        },
        {
            dataIndex: 'invoice_number',
            id: 'invoice_number',
            sorting: true,
            align: 'left',
            label: 'payments_page.number',
            className: 'min-w-[120px]',
            render: (item: any, record?: any) => (
                <span className='text-gray-700'>
                    {item}
                </span>
            )
        },

        {
            dataIndex: 'date',
            id: 'date_sorter',
            sorting: true,
            align: 'left',
            label: 'payments_page.date',
            className: 'min-w-[120px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700 whitespace-nowrap`}>
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'row_type',
            id: 'row_type',
            sorting: true,
            align: 'left',
            label: 'payments_page.row_type',
            className: 'min-w-[170px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'invoice_type',
            id: 'invoice_type',
            sorting: true,
            align: 'left',
            label: 'payments_page.invoice_type',
            className: 'min-w-[170px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'balance_id',
            id: 'balance_id',
            sorting: true,
            align: 'left',
            label: `payments_page.balance_id`,
            className: 'min-w-[170px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'order_pay',
            id: 'order_pay',
            sorting: true,
            align: 'left',
            label: `payments_page.order_pay`,
            className: 'min-w-[150px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700`}>
                    {item}
                </div>
            )
        },
        {
            dataIndex: 'amount',
            id: 'amount',
            sorting: true,
            align: 'left',
            label: `payments_page.amount`,
            className: 'min-w-[170px]',
            render: (item: any, record: any) => (
                <div className={`text-gray-700 font-bold`}>
                    {item ? toFormatPrice(item) : '—'}
                </div>
            )
        },
        {
            dataIndex: 'pay_purpose',
            id: 'pay_purpose',
            align: 'left',
            label: `payments_page.pay_purpose`,
            className: 'w-[25%]',
            render: (item: any, record: any) => (
                <div className={`text-gray-600 text-[14px]`}>
                    {item}
                </div>
            )
        }
    ];

    return (
        <>
            <TableContainer>
                <Table
                    sx={{
                        minWidth: 750,
                        borderCollapse: 'separate',
                        borderSpacing: '0 16px',
                    }}
                    size={'medium'}
                >
                    <TableHeadComponent />
                    <TableBody>
                        {
                            isIterableArray(visibleRows) ?
                                visibleRows.map((row, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            className={`  
                                                table-row-border
                                                service_id_${service_id}
                                                ${hoverRowService}
                                            `}
                                        >
                                            <TableRowsRender cells={headCells} row={row} />
                                        </TableRow>
                                    );
                                })
                                :
                                <TableEmptyRows isLoadingTable={isLoadingTable} />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default PaymentsTableDumb
