import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { IconButton, Typography } from '@material-tailwind/react';

import { isIterableArray, toFormatPrice, toDate } from '../../utils/utils';

import { ContractRowType, ContractType } from './types';
import BalanceBadges from '../../components/BalanceBadges';
import CompanyCard from './mobile/CompanyCard';
import MoreDotsIcon from '../../images/icons/MoreDotsIcon';

// столбец таблицы
interface HeadCell {
    id: keyof ContractRowType;
    dataIndex: keyof ContractRowType,
    label: string;
    align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    sorting?: boolean,
    className?: string,
    render?: any
}


function createDataService1(data: ContractRowType) {
    return {
        agent_short_name: data.agent_short_name,
        balance_1: data.balance_1,
        balance_2: data.balance_2,
        contract_end: data.contract_end,
        edrpou: data.edrpou,
        id: data.id,
        status: data.status,
        or_number: data.or_number,
        legal_address: data.legal_address,
        number: data.number,
        service_id: data.service_id
    };
}
function createDataService2(data: ContractRowType) {
    return {
        agent_short_name: data.agent_short_name,
        balance_3: data.balance_3,
        balance_4: data.balance_4,
        balance_5: data.balance_5,
        balance_6: data.balance_6,
        contract_end: data.contract_end,
        edrpou: data.edrpou,
        id: data.id,
        status: data.status,
        or_number: data.or_number,
        legal_address: data.legal_address,
        number: data.number,
        service_id: data.service_id
    };
}


function TableHeadComponent({ cells }: { cells: HeadCell[] }) {
    const { t } = useTranslation()
    return (
        <TableHead className='table-head-border border-none font-sans'>
            <TableRow>
                <TableCell className='font-sans' padding="checkbox" sx={{ paddingBottom: '16px', border: 'none' }} />
                {cells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={'normal'}
                        sx={{ paddingBottom: '32px', border: 'none' }}
                        className={`!font-sans ${headCell.className}`}
                    >
                        <span className='text-[14px] des:text-base font-normal !text-gray-600'>
                            {t(headCell.label)}
                        </span>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function TableRowsRender({ cells, row }: { cells: HeadCell[], row: ContractRowType }) {
    return (
        <>
            {
                cells.map((cell, index) => {
                    const key = cell.dataIndex
                    return (
                        <TableCell
                            key={cell.dataIndex}
                            align={cell.align}
                            sx={{
                                border: 'none', lineHeight: '120%',
                                borderTopRightRadius: index === cells.length - 1 ? 16 : 0,
                                borderBottomRightRadius: index === cells.length - 1 ? 16 : 0
                            }}
                            padding={'normal'}
                            className='!font-sans !text-gray-900'
                        >
                            {
                                cell.render ?
                                    cell.render(row[key], row)
                                    :
                                    row[key]
                            }
                        </TableCell>
                    )
                })
            }
        </>
    )
}
export default function CompanyTable(
    { contract, service_id, handleSetSelected, handleResetSelected, filterStatus }:
        { contract: ContractType, service_id: number, handleSetSelected: any, handleResetSelected: any, filterStatus: number[] }
) {


    const { t } = useTranslation()

    const [rows, setRows] = useState<ContractRowType[]>([]);
    const [rowsActive, setRowsActive] = useState<readonly number[]>([]);
    const [selected, setSelected] = React.useState<readonly number[]>([]);

    useEffect(() => {
        if (isIterableArray(contract.items)) {

            const items =
                isIterableArray(filterStatus) ?
                    filterStatus.reduce((acc: ContractRowType[], status: number) => {
                        contract.items.map((item: ContractRowType) => {
                            if (item.status === status) {
                                acc.push(item)
                                return acc
                            }
                        })
                        return acc
                    }, [])
                    :
                    contract.items

            const res: any[] = service_id === 1 ?
                items.map(item => createDataService1(item))
                :
                items.map(item => createDataService2(item))

            const onlyStatusActive = res?.filter((item: ContractRowType) => item.status === 1)
            setRowsActive(onlyStatusActive)
            setRows(res)
        }
        else {
            setRows([])
        }
    }, [contract, service_id, filterStatus]);




    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rowsActive.map((item: any) => item.id);
            setSelected(newSelected);
            handleSetSelected({
                [`contract_${contract.id}`]: newSelected
            })
        }
        else {
            setSelected([]);
            handleSetSelected({
                [`contract_${contract.id}`]: []
            })
        }

    };
    const handleSelectAllClickMobile = () => {
        const newSelected = rowsActive.map((item: any) => item.id);
        setSelected(newSelected);
        handleResetSelected({
            [`contract_${contract.id}`]: newSelected
        })

    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);

        handleSetSelected({
            [`contract_${contract.id}`]: newSelected
        })
    };
    const handleClickMobile = (event: React.MouseEvent<unknown>, id: number) => {
        handleResetSelected({
            [`contract_${contract.id}`]: [id]
        })
    }

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const bgService = service_id === 1 ? 'hover:!bg-orange-50' : service_id === 2 ? 'hover:!bg-blue-100' : 'hover:!bg-gray-200'

    const headCellsService1: HeadCell[] = [
        {
            dataIndex: 'legal_address',
            id: 'legal_address',
            align: 'left',
            label: 'companies.legal_address',
            className: 'min-w-[200px]  tab:w-[60%] lap:w-[50%] des:w-[25%]',
            render: (item: any, record?: any) => (
                <span className='text-[14px] des:text-[18px] !text-gray-800'>
                    {t('or')} {record.or_number}: {item}
                </span>
            )
        },
        {
            dataIndex: 'number',
            id: 'number',
            align: 'left',
            label: 'companies.number',
            className: 'w-[10%]',
            render: (item: any, record?: any) => (
                <div className={`
                        ${record.status === 1 && record.service_id === 1 && 'bg-orange-50 text-orange-500'}
                        ${record.status === 1 && record.service_id === 2 && 'bg-blue-100 text-blue-600'}
                        ${record.status !== 1 && 'bg-gray-100 text-gray-500'}
                        ${record.status === 1 && 'table-row-number '} service_id_${record.service_id}
                        ease transition-all duration-250 
                        text-[14px] des:text-[16px] font-bold whitespace-nowrap text-center
                        py-[5px] px-[32px] min-w-[160px] rounded-full
                    `}>
                    {item}
                </div>
            )
        },

        {
            dataIndex: 'contract_end',
            id: 'contract_end',
            align: 'left',
            label: 'companies.contract_end',
            className: 'w-auto',
            render: (item: any, record: any) => (
                <div className={` 
                        ${record.status !== 1 && 'text-gray-500'}
                        text-[14px] des:text-[18px] font-normal whitespace-nowrap
                    `}>
                    {t('companies.contract_until')} {toDate(item)}
                </div>
            )
        },
        {
            dataIndex: 'balance_1',
            id: 'balance_1',
            align: 'center',
            label: `balance_1`,
            className: 'w-[18%]',
            render: (item: any, record: any) => (
                <span className={`
                    ${Number(item) >= 0 ?
                        (record.status === 1) ? 'text-gray-700' : 'text-gray-500'
                        :
                        (record.status === 1) ? 'text-red-500' : 'text-red-300'
                    } 
                    text-[14px] des:text-[18px] font-bold `}
                >
                    {toFormatPrice(item)}
                </span>
            )
        },
        {
            dataIndex: 'balance_2',
            id: 'balance_2',
            align: 'center',
            label: `balance_2`,
            className: 'w-[18%]',
            render: (item: any, record: any) => (
                <span className={`
                    ${Number(item) >= 0 ?
                        (record.status === 1) ? 'text-gray-700' : 'text-gray-500'
                        :
                        (record.status === 1) ? 'text-red-500' : 'text-red-300'
                    } 
                    text-[14px] des:text-[18px] font-bold `}
                >
                    {toFormatPrice(item)}
                </span>
            )
        },
    ];
    const headCellsService2: HeadCell[] = [
        {
            dataIndex: 'legal_address',
            id: 'legal_address',
            align: 'left',
            label: 'companies.legal_address',
            className: 'w-[25%]',
            render: (item: any, record?: any) => (
                <span className='text-[14px] des:text-[18px] !text-gray-800'>
                    {t('or')} {record.or_number}: {item}
                </span>
            )
        },
        {
            dataIndex: 'number',
            id: 'number',
            align: 'left',
            label: 'companies.number',
            className: 'w-[10%]',
            render: (item: any, record?: any) => (
                <div className={`
                        ${record.status === 1 && record.service_id === 1 && 'bg-orange-50 text-orange-500'}
                        ${record.status === 1 && record.service_id === 2 && 'bg-blue-100 text-blue-600'}
                        ${record.status !== 1 && 'bg-gray-100 text-gray-500'}
                        ${record.status === 1 && 'table-row-number '} service_id_${record.service_id}
                        ease transition-all duration-250 
                        text-[14px] des:text-[16px] font-bold whitespace-nowrap text-center
                        py-[5px] px-[32px] min-w-[160px] rounded-full
                    `}>
                    {item}
                </div>
            )
        },

        {
            dataIndex: 'contract_end',
            id: 'contract_end',
            align: 'left',
            label: 'companies.contract_end',
            // className: 'w-auto',
            render: (item: any, record: any) => (
                <div className={` 
                        ${record.status !== 1 && 'text-gray-500'}
                        text-[14px] des:text-[18px] font-normal whitespace-nowrap
                    `}>
                    {t('companies.contract_until')} {toDate(item)}
                </div>
            )
        },
        {
            dataIndex: 'balance_3',
            id: 'balance_3',
            align: 'center',
            label: `balance_3`,
            className: 'w-[14%]',
            render: (item: any, record: any) => (
                <span className={`
                    ${Number(item) >= 0 ?
                        (record.status === 1) ? 'text-gray-700' : 'text-gray-500'
                        :
                        (record.status === 1) ? 'text-red-500' : 'text-red-300'
                    } 
                    text-[14px] des:text-[18px] font-bold `}
                >
                    {toFormatPrice(item)}
                </span>
            )
        },
        {
            dataIndex: 'balance_4',
            id: 'balance_4',
            align: 'center',
            label: `balance_4`,
            className: 'w-[14%]',
            render: (item: any, record: any) => (
                <span className={`
                    ${Number(item) >= 0 ?
                        (record.status === 1) ? 'text-gray-700' : 'text-gray-500'
                        :
                        (record.status === 1) ? 'text-red-500' : 'text-red-300'
                    } 
                    text-[14px] des:text-[18px] font-bold `}
                >
                    {toFormatPrice(item)}
                </span>
            )
        },
        {
            dataIndex: 'balance_5',
            id: 'balance_5',
            align: 'center',
            label: `balance_5`,
            className: 'w-[14%]',
            render: (item: any, record: any) => (
                <span className={`
                    ${Number(item) >= 0 ?
                        (record.status === 1) ? 'text-gray-700' : 'text-gray-500'
                        :
                        (record.status === 1) ? 'text-red-500' : 'text-red-300'
                    } 
                    text-[14px] des:text-[18px] font-bold `}
                >
                    {toFormatPrice(item)}
                </span>
            )
        },
        {
            dataIndex: 'balance_6',
            id: 'balance_6',
            align: 'center',
            label: `balance_6`,
            className: 'w-[14%]',
            render: (item: any, record: any) => (
                <span className={`
                    ${Number(item) >= 0 ?
                        (record.status === 1) ? 'text-gray-700' : 'text-gray-500'
                        :
                        (record.status === 1) ? 'text-red-500' : 'text-red-300'
                    } 
                    text-[14px] des:text-[18px] font-bold `}
                >
                    {toFormatPrice(item)}
                </span>
            )
        },
    ];

    return (
        <div className='animate-appear'>
            <div className='flex flex-col items-start lap:flex-row lap:items-center justify-between tab:py-[15px]'>
                <div className='flex items-center  justify-between  tab:justify-start w-full pb-[16px] tab:pb-0'>
                    <div className="hidden tab:block">
                        <Checkbox
                            id={String(contract.id || 'id-common')}
                            disableRipple={true}
                            className={`svg-checkbox-dark hover:bg-white `}
                            indeterminate={selected.length > 0 && selected.length < rowsActive.length}
                            checked={rowsActive.length > 0 && selected.length === rowsActive.length}
                            onChange={handleSelectAllClick}
                        />
                    </div>
                    <label htmlFor={String(contract.id || 'id-common')}>
                        <Typography variant='h5' className='tab:px-[16px] font-normal text-[16px] lap:text-[24px]'>
                            {contract.name}
                        </Typography>
                    </label>
                </div>
                <div className='w-full tab:w-auto'>
                    <BalanceBadges
                        service_id={service_id}
                        className='!px-[16px] !py-[6px] tab:!py-[10px] rounded-full tab:rounded !gap-[8px] !bg-gray-100 text-[16px]'
                        contClassName='!grid w-full tab:!flex'
                        items={
                            service_id === 1 ?
                                {
                                    balance_1: contract?.balance_1,
                                    balance_2: contract?.balance_2,
                                } :
                                {

                                    balance_3: contract?.balance_3,
                                    balance_4: contract?.balance_4,
                                    balance_5: contract?.balance_5,
                                    balance_6: contract?.balance_6,
                                }}
                    />
                </div>
            </div>
            <CompanyCard rows={rows} handleClick={handleClick} isSelected={isSelected} />
            <div >
                <TableContainer className='hidden tab:block'>
                    <Table
                        sx={{
                            minWidth: 750,
                            borderCollapse: 'separate',
                            borderSpacing: '0 16px',
                        }}
                        className='border-t border-gray-100 mt-[8px]'
                        size={'medium'}
                    >
                        {
                            service_id === 1 &&
                            <TableHeadComponent cells={headCellsService1} />
                        }
                        {
                            service_id === 2 &&
                            <TableHeadComponent cells={headCellsService2} />
                        }
                        <TableBody>
                            {
                                isIterableArray(rows) &&
                                rows.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    return (
                                        <TableRow
                                            component={row.status === 1 ? NavLink : 'tr'}
                                            to={`/contract/${row.id}`}
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            className={`  
                                            table-row-border
                                            service_id_${service_id} ${row.status === 1 ? 'cursor-pointer' : ''}
                                            ${row.status === 1 && bgService}
                                        `}
                                        >
                                            <TableCell
                                                padding="checkbox"
                                                sx={{
                                                    border: 'none', lineHeight: '120%',
                                                    borderTopLeftRadius: 16,
                                                    borderBottomLeftRadius: 16,

                                                }}
                                                onClick={(event: any) => {
                                                    event.stopPropagation()
                                                    row.status === 1 && handleClick(event, row.id)
                                                }}
                                            >
                                                <div className='flex items-center min-h-[64px]'>
                                                    <Checkbox
                                                        disabled={row.status !== 1}
                                                        disableRipple={true}
                                                        color="default"
                                                        className={`svg-checkbox-dark hover:!bg-white disabled:  ${row.status !== 1 ? ' opacity-25' : ''}`}
                                                        checked={isItemSelected}
                                                    />
                                                </div>
                                            </TableCell>
                                            {
                                                service_id === 1 &&
                                                <TableRowsRender cells={headCellsService1} row={row} />
                                            }
                                            {
                                                service_id === 2 &&
                                                <TableRowsRender cells={headCellsService2} row={row} />
                                            }
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}