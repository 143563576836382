import { IconProps } from './Arrow'

const ArrowTopRight = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M20.8147 5.41461L2.6913 23.5385C2.38329 23.8462 2.01175 24 1.57665 24C1.14124 24 0.769534 23.8462 0.46153 23.5385C0.153843 23.2305 0 22.8588 0 22.4233C0 21.9883 0.153843 21.6167 0.46153 21.3087L18.5854 3.18532H2.65929C2.20827 3.18532 1.83003 3.03211 1.52458 2.7257C1.21944 2.41929 1.06687 2.03977 1.06687 1.58716C1.06687 1.13423 1.21944 0.756475 1.52458 0.453885C1.83003 0.151295 2.20827 0 2.65929 0H22.4076C22.8586 0 23.2368 0.15257 23.5423 0.457708C23.8474 0.763165 24 1.1414 24 1.59242V21.3407C24 21.7917 23.8468 22.17 23.5404 22.4754C23.2343 22.7806 22.8548 22.9331 22.4019 22.9331C21.9489 22.9331 21.5712 22.7806 21.2686 22.4754C20.966 22.17 20.8147 21.7917 20.8147 21.3407V5.41461Z" fill="#2D2B32" />
        </svg>

    )
}

export default ArrowTopRight
