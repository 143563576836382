import axios, { AxiosError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseApi } from '../../ConfigApi'
import { AppDispatch, RootState } from '../store'

type AuthBodyType = {
    email: string,
    password: string,
    lang: string
}

type RefreshBodyType = {
    email: string,
    lang: string
}

type RefreshBodyType_2 = {
    code: string,
    lang: string
}

createAsyncThunk.withTypes<{
    state: RootState
    dispatch: AppDispatch
    rejectValue: string
    extra: { s: string; n: number }
}>()

export const loginUser = createAsyncThunk(
    'auth/login',
    async (body: AuthBodyType, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const { data } = await axios.post(
                `${baseApi}/api/auth/login`,
                body,
                config
            )
            return data
        } catch (error: AxiosError | any) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const checkToken = createAsyncThunk(
    'auth/check-token',
    async (token: any, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const { data } = await axios.post(
                `${baseApi}/api/auth/check-token`,
                {
                    jwt: token
                },
                config
            )
            return data
        } catch (error: AxiosError | any) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const sendRefresh = createAsyncThunk(
    'auth/forgot-password',
    async (body: RefreshBodyType, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const { data } = await axios.post(
                `${baseApi}/api/auth/send-refresh-password`,
                body,
                config
            )
            return data
        } catch (error: AxiosError | any) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const sendRefreshFinish = createAsyncThunk(
    'auth/forgot-password',
    async (body: RefreshBodyType_2, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const { data } = await axios.post(
                `${baseApi}/api/auth/refresh-password`,
                body,
                config
            )
            return data
        } catch (error: AxiosError | any) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)