import { Typography } from '@material-tailwind/react'
import { Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import OverlayUnTouchable from '../../../layouts/pagesLayouts/OverlayUnTouchable'
import PageLayout from '../../../layouts/pagesLayouts/PageLayout'
import TableLayout from '../../../layouts/pagesLayouts/TableLayout'
import { useInvoiceHistoryViewQuery } from '../../../store/auth/authService'
import { setContractId, setServiceId } from '../../../store/service/contractSlice'
import { AppDispatch } from '../../../store/store'
import { isIterableArray, toFormatPrice } from '../../../utils/utils'
import TableSortingCreator from '../../tableHelpers/TableSortingCreator'
import { InvoiceHistoryRowType } from '../InvoicePage.types'
import InvoiceHistoryFilters from './InvoiceHistoryFilters'
import InvoiceHistoryTableDumb from './InvoiceHistoryTableDumb'
import { Helmet } from 'react-helmet'


type FilterFormType = { invoiceId: string | undefined, action: string }

const InvoiceHistory = () => {

    const { t } = useTranslation()

    const { invoiceId } = useParams()

    const dispatch = useDispatch<AppDispatch>()



    const form: FilterFormType = {
        invoiceId: invoiceId,
        action: ''
    }

    const [sendForm, setSendForm] = useState<FilterFormType>(form);


    const { data, isLoading, isFetching, error } = useInvoiceHistoryViewQuery(sendForm)

    // array all rows
    const [rows, setRows] = useState<InvoiceHistoryRowType[]>([]);

    const [tableRows, setTableRows] = useState<InvoiceHistoryRowType[]>([]);
    const [search, setSearch] = useState<string>('');

    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(true);




    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
        if (data?.contract_id) dispatch(setContractId(Number(data?.contract_id)))
    }, [data]);


    useEffect(() => {
        if (
            !isLoading && !isFetching && isIterableArray(tableRows) ||
            !isLoading && !isFetching && Array.isArray(data?.rows) && !isIterableArray(data?.rows)
        ) {
            setIsLoadingTable(false)
        } else if (!isLoading && !isFetching && !isIterableArray(tableRows)) {
            setTimeout(() => {
                setIsLoadingTable(false)
            }, 500);
        }
    }, [isLoading, isFetching, tableRows, data?.rows]);


    // ______CREATE_DATA
    useEffect(() => {
        if (isIterableArray(data?.data)) {
            const res: InvoiceHistoryRowType[] = data.data.map((item: InvoiceHistoryRowType) => createData(item))
            setRows(res)
        }
        else setRows([])
    }, [data]);

    useEffect(() => {
        onSearch(search)
    }, [rows]);


    const onSearch = (searchValue: string) => {
        setSearch(searchValue);
        const reg = new RegExp(searchValue.replaceAll(' ', ''), "gi");
        if (searchValue === "") {
            setTableRows(rows)
            return
        }

        if (isIterableArray(rows)) {
            const filteredData: InvoiceHistoryRowType[] = rows.filter((item: any) => {
                const keys = Object.keys(item);
                let matches = 0;
                keys.forEach((key) => {
                    let val
                    val = String(item[key]).replaceAll(' ', '')

                    if (reg.test(val)) matches++;
                });
                return matches > 0;
            });
            if (searchValue !== "" && filteredData.length > 0) {
                setTableRows(filteredData);
            }
            if (searchValue !== "" && filteredData.length === 0) {
                setTableRows([]);
            }
        }


    };

    function createData(data: InvoiceHistoryRowType) {
        return {
            key: data.key,

            date: data.date,
            date_sort: data.date_sort,
            amount: data.amount,
            debt: data.debt,
            saldo: data.saldo,
            overpay_unconfirm: data.overpay_unconfirm,
            action: t(`invoices_page.history.${data.action}`),
            info: data?.info ?
                `№${data?.info?.order_pay} ${t('invoices_page.history.info_from')} ${data?.info?.date}, ${t('invoices_page.history.info_sum')} ${data?.info?.amount ? toFormatPrice(data.info.amount) : ''}`
                :
                ''
        }
    }
    const setFormForFetch = (values: any) => {
        setIsLoadingTable(true)
        setTableRows([])
        setSendForm(prev => ({
            ...prev, ...{
                ...values,
                invoiceId: invoiceId
            }
        }))
    }



    return (
        <PageLayout title={
            <>
                <Typography variant='h3' className='text-gray-800 text-center'>
                    {t('invoices_page.history.invoice_title')}
                </Typography>
                {
                    !error &&
                    <Typography variant='h3' className='text-gray-800 text-center'>
                        {data?.invoice_number ? `#${data.invoice_number}` : <Skeleton className='w-[270px] mx-auto opacity-50' />}
                    </Typography>
                }
            </>
        }>
            <Helmet>
                <title>
                    {t('invoices_page.history.invoice_title')} {data?.invoice_number ? `#${data.invoice_number}` : ''}
                </title>
            </Helmet>
            <>
                <OverlayUnTouchable isOverlay={(isFetching || isLoading || !!error)}>
                    <InvoiceHistoryFilters
                        isLoadingFetch={isFetching}
                        fetchPage={setFormForFetch}
                        onSearch={onSearch}
                    />
                </OverlayUnTouchable>
                {
                    error ?
                        <TableLayout>
                            <Typography variant='lead' className='text-[20px] font-medium text-center py-[40px]'>
                                {t('tables.error_on_loading')}
                            </Typography>
                        </TableLayout>
                        :
                        <TableSortingCreator
                            isLoadingTable={isLoadingTable}
                            Component={InvoiceHistoryTableDumb}
                            rows={tableRows}
                        />
                }
            </>
        </PageLayout>
    )
}

export default InvoiceHistory
