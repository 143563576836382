import { Typography } from '@material-tailwind/react'
import { Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isIterableArray } from '../../../utils/utils'
import { InfoType } from '../ContractPage.types'

const InfoContract = ({ data, isFetching }: { data: InfoType | undefined, isFetching?: boolean }) => {

    const { t } = useTranslation()

    return (
        <>
            <Typography variant='h3' className='text-center text-gray-800 mb-[24px] tab:mb-[48px] lap:mb-[64px]'>
                {t('contract_page.info_title')}
            </Typography>
            <div className='bg-white border-2 border-gray-100 p-[24px] tab:p-[32px] rounded-[16px] mb-[96px] lap:mb-[128px]'>
                <div className='grid tab:grid-cols-2 lap:grid-cols-4 gap-[16px] tab:gap-[24px] lap:gap-[96px]'>

                    <div className='flex flex-col gap-[4px] tab:gap-[8px] '>
                        <p className=' text-[14px] lap:text-[16px] text-gray-700'>
                            {t('contract_page.info_name')}
                        </p>
                        <p className=' text-[14px] lap:text-[18px] font-medium text-gray-800'>
                            {
                                isFetching ?
                                    <Skeleton />
                                    :
                                    data?.name
                            }
                        </p>
                    </div>
                    <div className='flex flex-col gap-[4px] tab:gap-[8px] '>
                        <p className=' text-[14px] lap:text-[16px] text-gray-700'>
                            {t('contract_page.info_address')}
                        </p>
                        <p className=' text-[14px] lap:text-[18px] font-medium text-gray-800'>
                            {
                                isFetching ?
                                    <Skeleton />
                                    :
                                    data?.address
                            }
                        </p>
                    </div>
                    <div className='flex flex-col gap-[4px] tab:gap-[8px] '>
                        <p className=' text-[14px] lap:text-[16px] text-gray-700'>
                            {t('contract_page.info_edrpou')}
                        </p>
                        <p className=' text-[14px] lap:text-[18px] font-medium text-gray-800'>
                            {
                                isFetching ?
                                    <Skeleton />
                                    :
                                    data?.edrpou
                            }
                        </p>
                    </div>
                    <div className='flex flex-col gap-[4px] tab:gap-[8px] '>
                        <p className=' text-[14px] lap:text-[16px] text-gray-700'>
                            {t('contract_page.info_singers')}
                        </p>
                        <p className=' text-[14px] lap:text-[18px] font-medium text-gray-800'>
                            {
                                isFetching ?
                                    <Skeleton />
                                    :
                                    data?.signers?.fullname ?
                                        <>
                                            {data?.signers?.fullname} {t('contract_page.info_singers_action')} {data?.signers?.action}
                                        </>
                                        :
                                        ' - '
                            }
                        </p>
                    </div>
                </div>
                <hr className='border-1 border-gray-100 my-[24px]' />
                <div className='grid tab:grid-cols-2 lap:grid-cols-4 gap-[16px] tab:gap-[24px] lap:gap-[96px]'>

                    <div className='flex flex-col gap-[4px] tab:gap-[8px] '>
                        <p className=' text-[14px] lap:text-[16px] text-gray-700'>
                            {t('contract_page.info_emails')}
                        </p>
                        <div className=' text-[14px] lap:text-[18px] font-medium text-gray-800'>
                            {
                                isFetching ?
                                    <Skeleton />
                                    :
                                    data?.emails?.length ?
                                        data.emails.map((email, key) => (
                                            <p key={key} className='mb-[8px] last:mb-0'>
                                                {email}
                                            </p>
                                        ))
                                        :
                                        ' - '
                            }
                        </div>
                    </div>
                    <div className='flex flex-col gap-[4px] tab:gap-[8px] '>
                        <p className=' text-[14px] lap:text-[16px] text-gray-700'>
                            {t('contract_page.info_phones')}
                        </p>
                        <div className=' text-[14px] lap:text-[18px] font-medium text-gray-800'>
                            {
                                isFetching ?
                                    <Skeleton />
                                    :
                                    data?.phones?.length ?
                                        data.phones.map((phone, key) => (
                                            <p key={key} className='mb-[8px] last:mb-0'>
                                                {phone}
                                            </p>
                                        ))
                                        :
                                        ' - '
                            }
                        </div>
                    </div>
                    <div className='flex flex-col gap-[4px] tab:gap-[8px] '>
                        <p className=' text-[14px] lap:text-[16px] text-gray-700'>
                            {t('contract_page.info_pay_nds')}
                        </p>
                        <p className=' text-[14px] lap:text-[18px] font-medium text-gray-800'>
                            {
                                isFetching ?
                                    <Skeleton />
                                    :
                                    data?.pay_nds ? t('yes') : t('no')
                            }
                        </p>
                    </div>
                    <div className='flex flex-col gap-[4px] tab:gap-[8px] '>
                        <p className=' text-[14px] lap:text-[16px] text-gray-700'>
                            {t('contract_page.info_job')}
                        </p>
                        <p className=' text-[14px] lap:text-[18px] font-medium text-gray-800'>
                            {
                                isFetching ?
                                    <Skeleton />
                                    :
                                    data?.job ? data.job : ' - '
                            }
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfoContract