import { Button, Typography } from '@material-tailwind/react'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PageLayout from '../../../layouts/pagesLayouts/PageLayout'
import { useViewDetailingInfoQuery, useViewDetailingMatrixQuery } from '../../../store/auth/authService'
import { setContractId, setServiceId } from '../../../store/service/contractSlice'
import { AppDispatch } from '../../../store/store'
import { isIterableArray } from '../../../utils/utils'
import AmountTitle from '../AmountTitle'
import TkoInfoCard from '../TkoInfoCard'
import MatrixCreator from './MatrixCreator'
import MatrixDays from '../matrix/MatrixDays'
import { SubmitHandler, useForm } from 'react-hook-form'
import MatrixMonth from '../matrix/MatrixMonth2'
import axios from 'axios'
import { baseApi } from '../../../ConfigApi'
import { errorToast, successToast } from '../../../components/toasts/toasts'
import { Helmet } from 'react-helmet'

const CreateDetailing = () => {

  const { t } = useTranslation()
  const { param_contract_id, param_tko_id, param_year, param_month } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const dispatch = useDispatch<AppDispatch>()


  // console.log(infoTko)
  const { data, isLoading, error } = useViewDetailingMatrixQuery({
    tko_id: Number(param_tko_id),
    month: param_month || '',
    year: Number(param_year) || 0,
  })


  console.log(data)

  useEffect(() => {
    dispatch(setContractId(Number(param_contract_id)))
  }, [param_contract_id]);

  useEffect(() => {
    if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
  }, [data]);



  // for days
  const {
    handleSubmit,
    register,
    setValue,
    control,
  } = useForm<any>({
    defaultValues: [],
  })
  useEffect(() => {
    if (data?.matrix_detailing === 1) setValue('data', data.matrix)
  }, [data])


  const [isLoadingSave, setIsLoadingSave] = useState(false);


  const saveMatrixDays = async () => {
    handleSubmit(onSubmit)()
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    const matrix = isIterableArray(data.data) ? data.data.map((i: any) => Number(i)) : []
    saveDetailing({
      matrix: matrix,
      type_detailing: 1
    })
  }

  const saveMatrixMonth = (data: any) => {
    saveDetailing({
      matrix: data,
      type_detailing: 2
    })
  }


  const saveDetailing = (val: any) => {
    setIsLoadingSave(true)
    axios.post(`${baseApi}/api/ca/consumption-detailing/create`, {
      type_detailing: val.type_detailing,
      detailing: val.matrix,
      tko_id: Number(param_tko_id),
      month: data?.tko?.month || '',
      year: data?.tko?.year || ''
    })
      .then((response: any) => {
        if (response.data.res === 'error') {
          errorToast({
            title: t('detailing.edit_detailing_title'),
            text: t('detailing.error_on_save')
          })
        }
        else {
          successToast({
            title: t('detailing.success_on_save')
          })
          handleNavigateBack()
        }
      })
      .catch(error => {
        console.error(error);
        errorToast({
          title: t('detailing.edit_detailing_title'),
          text: t('detailing.error_on_save')
        })
      })
      .finally(() => {
        setIsLoadingSave(false)
      })
  }


  const handleNavigateBack = () => {
    if (location.state?.pathname) navigate(location.state.pathname + (location.state?.search ? location.state.search : ''))
    else navigate(`/consumptions/${param_contract_id}`)
  }

  return (
    <PageLayout title={
      <Typography variant='h3' className='text-gray-800 text-center mb-[32px]'>
        {t('detailing.edit_detailing_title')}
      </Typography>
    }>
      <Helmet>
        <title>
          {t('detailing.edit_detailing_title')}
        </title>
      </Helmet>

      {data?.tko && <TkoInfoCard tko={data.tko} />}

      {
        data?.detailing === 1 &&
        <MatrixDays
          control={control}
          data={data.matrix_type_detailing1}
          register={register}
          setValue={setValue}
          isEditAccess={true}
          saveMatrixDays={saveMatrixDays}
          amount={false}
          isLoadingSave={isLoadingSave}
          handleNavigateBack={handleNavigateBack}
        />
      }
      {
        data?.detailing === 2 &&
        isIterableArray(data.matrix_type_detailing2) &&
        <MatrixMonth
          saveMatrixMonth={saveMatrixMonth}
          amount={false}
          daysLength={data.matrix_type_detailing2[0].length}
          isEditAccess={true}
          detailing={data.matrix_type_detailing2}
          isLoadingSave={isLoadingSave}
          handleNavigateBack={handleNavigateBack}
        />
      }
      {
        data?.detailing === 3 &&
        <Typography className='text-center text-gray-700 text-[20px] my-[32px]'>
          {t('detailing.no_consumption_for_create')}
        </Typography>
      }
    </PageLayout>
  )
}

export default CreateDetailing
