import { IconProps } from "./Arrow"

const CalendarIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 16.0001C1.0875 16.0001 0.734375 15.8508 0.440625 15.5522C0.146875 15.2536 0 14.9029 0 14.5001V3.50012C0 3.09734 0.146875 2.74665 0.440625 2.44804C0.734375 2.14943 1.0875 2.00012 1.5 2.00012H3V0.00012207H4.5V2.00012H9.5V0.00012207H11V2.00012H12.5C12.9125 2.00012 13.2656 2.14943 13.5594 2.44804C13.8531 2.74665 14 3.09734 14 3.50012V14.5001C14 14.9029 13.8531 15.2536 13.5594 15.5522C13.2656 15.8508 12.9125 16.0001 12.5 16.0001H1.5ZM1.5 14.5001H12.5V7.00012H1.5V14.5001ZM1.5 5.50012H12.5V3.50012H1.5V5.50012Z" fill="#444248" />
        </svg>

    )
}

export default CalendarIcon