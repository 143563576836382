import { IconProps } from './Arrow'

const LightBulbIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3261_4545)">
                <path d="M10 22.5C9.45 22.5 9 22.05 9 21.5V20.5H15V21.5C15 22.05 14.55 22.5 14 22.5H10Z" fill="#FDFDFD" />
                <path d="M5 9.5C5 5.64 8.14 2.5 12 2.5C15.86 2.5 19 5.64 19 9.5C19 11.88 17.81 13.97 16 15.24V17.5C16 18.05 15.55 18.5 15 18.5H9C8.45 18.5 8 18.05 8 17.5V15.24C6.19 13.97 5 11.88 5 9.5Z" fill="#FDFDFD" />
            </g>
            <defs>
                <clipPath id="clip0_3261_4545">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default LightBulbIcon
