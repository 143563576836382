import { IconProps } from './Arrow'

const PencilIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.16667 13.8335H3.35417L11.5 5.68762L10.3125 4.50012L2.16667 12.646V13.8335ZM1.33333 15.5001C1.09722 15.5001 0.899306 15.4203 0.739583 15.2605C0.579861 15.1008 0.5 14.9029 0.5 14.6668V12.646C0.5 12.4237 0.541667 12.2119 0.625 12.0105C0.708333 11.8091 0.826389 11.6321 0.979167 11.4793L11.5 0.979289C11.6667 0.826511 11.8507 0.708455 12.0521 0.625122C12.2535 0.541789 12.4653 0.500122 12.6875 0.500122C12.9097 0.500122 13.125 0.541789 13.3333 0.625122C13.5417 0.708455 13.7222 0.833455 13.875 1.00012L15.0208 2.16679C15.1875 2.31957 15.309 2.50012 15.3854 2.70846C15.4618 2.91679 15.5 3.12512 15.5 3.33346C15.5 3.55568 15.4618 3.76748 15.3854 3.96887C15.309 4.17026 15.1875 4.35429 15.0208 4.52096L4.52083 15.021C4.36806 15.1737 4.19097 15.2918 3.98958 15.3751C3.78819 15.4585 3.57639 15.5001 3.35417 15.5001H1.33333ZM10.8958 5.10429L10.3125 4.50012L11.5 5.68762L10.8958 5.10429Z" fill="#444248" />
        </svg>
    )
}

export default PencilIcon
