
import React, { ReactNode } from 'react'

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { isIterableArray, numWord, toFormatPrice, toStringPrice } from '../../../utils/utils';
import { currency } from '../../../utils/variables';
import { AccountingType } from '../ContractPage.types';
import { Box, Skeleton } from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import { topMenu } from '../../../menu/topMenu';

const Tiles = ({ data, isFetching, month }: { isFetching: boolean, data: AccountingType | undefined, month: string | undefined }) => {

    const { t } = useTranslation()

    const { param_contract_id } = useParams()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const bgService = service_id === 1 ? 'bg-orange-50 group-active:!bg-orange-100 lap:group-hover:!bg-orange-100' : service_id === 2 ? 'bg-blue-100' : 'bg-gray-100'
    const textService = service_id === 1 ? 'text-orange-500 ' : service_id === 2 ? 'text-blue-500 ' : 'text-gray-500'

    const unitPriceService = service_id === 1 ? t('grnKvtGod') : service_id === 2 ? t('grnCubicMeter') : ''
    const unitService = service_id === 1 ? t('mvHour') : service_id === 2 ? t('cubicMeter') : ''

    const textMonth = t(`month_${month}`)

    const TextTitle = ({ children }: { children: any }) => (
        <p className='text-gray-700 text-[12px] tab:text-sm mb-[4px] tab:mb-[8px] min-h-[16px] tab:min-h-[20px]'>
            {children}
        </p>
    )
    const TextValue = ({ children }: { children: any }) => {
        return (
            <p className={`transition-all duration-300 text-gray-800  text-[12px] tab:text-[14px] tab:text-[16px] font-bold group-hover:${textService}`}>
                {children}
            </p>
        )
    }
    const TileCard = ({ title, textMonth, content, isBgTitle = true, to }: { title: string | ReactNode, textMonth?: string, content: any, isBgTitle?: boolean, to?: string }) => (
        <Box component={to ? NavLink : 'div'} to={to} className={`overflow-hidden group h-full rounded-[16px] border-2 border-gray-100 bg-white flex flex-col ${isBgTitle ? 'p-[16px] des:p-[32px] !pr-0' : 'p-[16px] lap:p-[24px] !pr-0'}`}>
            <div className='flex gap-[4px] justify-between items-center mb-[24px] tab:mb-[32px]'>
                <p className={`
                   break-words
                    transition-all duration-300
                    ${isBgTitle ? bgService : ''} ${isBgTitle ? 'px-[12px] tab:px-[16px] py-[5px] lap:py-[9px] rounded-full ' : ''} 
                    ${textService} text-[12px] lap:text-[16px]
                `}>
                    {/* break-all */}
                    {title}
                </p>
                {
                    textMonth ?
                        <p className={`hidden mob:block text-gray-800 break-words text-[12px] lap:text-[14px] ${isBgTitle ? 'pr-[16px] tab:pr-[32px]' : 'pr-[16px] tab:pr-[24px]'}`}>
                            {textMonth}
                        </p>
                        :
                        isFetching ?
                            <p className={`hidden mob:block ${isBgTitle ? 'pr-[16px] tab:pr-[32px]' : 'pr-[16px] tab:pr-[24px]'}`}><Skeleton variant='rectangular' className='w-full max-w-[120px]' /></p>
                            :
                            <></>
                }
            </div>

            <div className={`mt-auto ${isBgTitle ? 'pr-[16px] tab:pr-[32px]' : 'pr-[16px] tab:pr-[24px]'}`} >
                {content}
            </div>
        </Box>
    )

    const RenderByBalance = ({ balance }: { balance: any }) => (
        <>
            {
                balance?.type === 1 &&
                isIterableArray(balance?.parts) &&
                <div className='grid grid-cols-4 gap-[6px] items-end'>
                    {
                        balance.parts.map((item: any, key: any) => (
                            <div key={key}>
                                <TextTitle>
                                    {item?.day_of_month && <> {t('to')}{item.day_of_month} </>}
                                    {item.month}
                                </TextTitle>
                                <TextValue>
                                    {item.part}%
                                </TextValue>
                            </div>
                        ))
                    }
                </div>
            }
            {
                balance?.type === 1 &&
                isIterableArray(balance?.part_fact) &&
                balance?.part_fact.map((item: any, key: any) => (
                    <div key={key}>
                        <TextTitle>
                            {item.month}
                            {t('contract_page.askoe')}
                        </TextTitle>
                        <TextValue>
                            {
                                item.day_of_month &&
                                <>{t('to')} {item.day_of_month}{t('contract_page.day_of_month_postfix')}</>
                            }
                        </TextValue>
                    </div>
                ))
            }
            {
                balance?.type === 2 &&
                isIterableArray(balance?.parts) &&
                <div>
                    <TextTitle>
                        {balance?.parts[0]?.day_of_month && <> {t('to')}{balance?.parts[0].day_of_month} </>}
                        {balance?.parts[0].month}
                    </TextTitle>
                    <TextValue>
                        {100}%
                    </TextValue>
                </div>
            }
            {
                balance?.type === 3 &&
                <TextValue>
                    {t('by_fact')}
                </TextValue>
            }
        </>
    )

    return (
        <div className={`h-full flex flex-col`}>
            <div className='grid grid-cols-2 tab:grid-rows-2 gap-[8px] lap:gap-[10px] mb-[8px] tab:mb-[24px] grow-2.5 '>
                {/* <NavLink className={'group'} to={`${topMenu.invoices.menuUrl}/${param_contract_id}`}> */}
                <TileCard
                    to={`${topMenu.invoices.menuUrl}/${param_contract_id}`}
                    title={t('contract_page.invoices')}
                    content={
                        <div className='flex flex-col'>
                            <TextTitle>
                                {
                                    typeof data?.unpaid === 'number' &&
                                    <>{data?.unpaid} {t(`contract_page.unpaid_invoices_${numWord(data.unpaid)}`)}</>
                                }
                            </TextTitle>
                            <TextValue>
                                {
                                    isFetching ? <Skeleton className='w-full max-w-[120px]' /> :
                                        <>
                                            {toFormatPrice(data?.invoices || 0)} {t('grn')}
                                        </>
                                }
                            </TextValue>
                        </div>
                    }
                />
                {/* </NavLink> */}
                <TileCard
                    to={`${topMenu.payments.menuUrl}/${param_contract_id}`}
                    title={t('contract_page.payments')}
                    textMonth={textMonth}
                    content={
                        <>
                            <TextTitle>
                                {t('contract_page.counted')}
                            </TextTitle>
                            <TextValue>
                                {
                                    isFetching ? <Skeleton className='w-full max-w-[120px]' /> :
                                        <>
                                            {toFormatPrice(data?.payments || 0)} {t('grn')}
                                        </>
                                }
                            </TextValue>
                        </>
                    }
                />
                <TileCard
                    to={`${topMenu.consumptions.menuUrl}/${param_contract_id}`}
                    title={t('contract_page.consumptions')}
                    textMonth={textMonth}
                    content={
                        <div className='grid tab:grid-cols-2 grid-rows-1 gap-[8px] items-end'>
                            <div>
                                <TextTitle>
                                    {t('contract_page.declared')}
                                </TextTitle>
                                <TextValue>
                                    {
                                        isFetching ? <Skeleton className='w-full max-w-[120px]' /> :
                                            <>
                                                {toStringPrice(data?.consumption.plan || 0)} {unitService}
                                            </>
                                    }
                                </TextValue>
                            </div>
                            <div>
                                <TextTitle>
                                    {t('contract_page.fact')}
                                </TextTitle>
                                <TextValue>
                                    {
                                        isFetching ? <Skeleton className='w-full max-w-[120px]' /> :
                                            <>
                                                {toStringPrice(data?.consumption.fact || 0)} {unitService}
                                            </>
                                    }
                                </TextValue>
                            </div>
                        </div>
                    }
                />
                <TileCard
                    to={`${topMenu.acts.menuUrl}/${param_contract_id}`}
                    title={t('contract_page.acts')}
                    textMonth={textMonth}
                    content={
                        <div className='grid tab:grid-cols-2 grid-rows-1 gap-[8px] items-end'>
                            <div>
                                <TextTitle>
                                    {t('contract_page.fact_consumptions')}
                                </TextTitle>
                                <TextValue>
                                    {
                                        isFetching ? <Skeleton className='w-full max-w-[120px]' /> :
                                            <>
                                                {toStringPrice(data?.acts.fact || 0)} {unitService}
                                            </>
                                    }
                                </TextValue>
                            </div>
                            <div>
                                <TextTitle>
                                    {t('contract_page.total_pdv')}
                                </TextTitle>
                                <TextValue>
                                    {
                                        isFetching ? <Skeleton className='w-full max-w-[120px]' /> :
                                            <>
                                                {toFormatPrice(data?.acts.sum || 0)} {t('grn')}
                                            </>
                                    }
                                </TextValue>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='grid lap:grid-cols-2 grid-rows-1  gap-[8px] lap:gap-[10px] grow min-h-[190px]'>
                <TileCard
                    isBgTitle={false}
                    title={isFetching ? <Skeleton className='w-full min-w-[120px]' /> : t(`contract_page.payment_schedule_${service_id || ''}`)}
                    // textMonth={textMonth}
                    content={
                        isFetching ?
                            <Skeleton />
                            :
                            <>
                                {data?.pay_plan?.balance_1 && <RenderByBalance balance={data?.pay_plan?.balance_1} />}
                                {data?.pay_plan?.balance_3 && <RenderByBalance balance={data?.pay_plan?.balance_3} />}
                            </>

                    }
                />
                {
                    service_id === 1 ?
                        <div className='grid grid-cols-2 grid-rows-1 gap-[10px]'>
                            <TileCard
                                isBgTitle={false}
                                title={isFetching ? <Skeleton className='w-full min-w-[60px]' /> : t('contract_page.allocation_payment')}
                                content={
                                    isFetching ?
                                        <Skeleton />
                                        :
                                        data?.pay_methods?.allocation ?
                                            <>
                                                <TextTitle>
                                                    {t('contract_page.through')}
                                                </TextTitle>
                                                <TextValue>
                                                    {data?.pay_methods?.allocation_name}
                                                </TextValue>
                                            </>
                                            :
                                            <TextValue>
                                                {t('contract_page.allocation_0')}
                                            </TextValue>
                                }
                            />
                            <TileCard
                                isBgTitle={false}
                                title={isFetching ? <Skeleton className='w-full min-w-[60px] mr-[24px]' /> : t('contract_page.payment_terms')}
                                content={
                                    isFetching ?
                                        <Skeleton />
                                        :
                                        <>
                                            {
                                                data?.pay_methods?.delta_type &&
                                                <div className='!mb-[16px] lap:mb-0'>
                                                    {/* method_type : {data?.pay_methods?.method_type} */}
                                                    <TextTitle>
                                                        {data?.pay_methods?.delta_type === 1 ? t('contract_page.marja') : t('contract_page.sale')}
                                                    </TextTitle>
                                                    <TextValue>
                                                        {toStringPrice(data?.pay_methods?.delta_value)}
                                                        {
                                                            data?.pay_methods?.delta_unit === 1 ?
                                                                unitPriceService
                                                                :
                                                                data?.pay_methods?.delta_unit === 2 ?
                                                                    '%'
                                                                    :
                                                                    ""
                                                        }
                                                        {/* грн/кВт*год */}
                                                    </TextValue>
                                                </div>
                                            }
                                            {
                                                data?.pay_methods?.fix_price &&
                                                <>
                                                    {/* method_type : {data?.pay_methods?.method_type} */}
                                                    <TextTitle>
                                                        {t('contract_page.fix_price')}
                                                    </TextTitle>
                                                    <TextValue>
                                                        {toStringPrice(data?.pay_methods?.fix_price)} {unitPriceService}
                                                        {/* грн/кВт*год */}
                                                    </TextValue>
                                                </>
                                            }

                                        </>
                                }
                            />
                        </div>
                        :
                        // service_id === 2
                        <TileCard
                            isBgTitle={false}
                            title={isFetching ? <Skeleton className='w-full min-w-[60px]' /> : t('contract_page.prices_gas')}
                            content={
                                isFetching ?
                                    <Skeleton />
                                    :
                                    data?.pay_methods?.prices_gas ?
                                        <>
                                            {
                                                isIterableArray(data?.pay_methods?.prices_gas) &&
                                                data?.pay_methods?.prices_gas.map((price: number) => (
                                                    <TextValue>
                                                        {price ? toStringPrice(price) : ''} {t('grnPdvCubicMeter')}
                                                    </TextValue>
                                                ))
                                            }
                                        </>
                                        :
                                        ''
                            }
                        />
                }

            </div>
        </div >
    )
}

export default Tiles
