import { Button, Spinner, Typography } from '@material-tailwind/react'
import { Modal } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorToast } from '../../components/toasts/toasts'
import { baseApi } from '../../ConfigApi'
import { blobToBase64 } from '../../utils/utils'
import { InvoiceRowType } from './InvoicePage.types'


type PropsModal = {
    open: boolean,
    handleClose: any,
    formSend: any
}
const ModalViewPdf = ({
    open,
    handleClose,
    formSend
}: PropsModal) => {

    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [frameFile, setFrameFile] = useState<any>(null);
    const [isError, setIsError] = useState<boolean>(false);

    const handleDownloadFile = (formSend: any) => {
        setIsLoading(true)
        setIsError(false)
        axios.post(`${baseApi}/api/invoice/export-file`, formSend, { responseType: 'blob' })
            .then((response: any) => {
                if (response.data.res === 'error') {
                    setIsError(true)
                }
                else {
                    blobToBase64(response.data).then((res: any) => {
                        setFrameFile(res)
                    });
                }
            })
            .catch(error => {
                console.error(error);
                setIsError(true)
                errorToast({
                    title: t('invoices_page.invoices_title'),
                    text: t('error_on_download')
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (open && formSend) handleDownloadFile(formSend)
        return () => {
            setFrameFile(null)
            setIsError(false)
            setIsLoading(true)
        };
    }, [open, formSend]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='flex items-center justify-center'
            >
                <div className={`${(isError || isLoading) ? 'min-w-[300px] bg-white p-[36px] ' : 'min-h-[95vh] w-[50%] bg-white '}
                    flex justify-center items-center transition-all rounded-[12px]
                `}>
                    {
                        isLoading ?
                            <Spinner />
                            :
                            frameFile ? <iframe src={frameFile} frameBorder="0" className='h-[95vh] w-full' />
                                :
                                isError ?
                                    <div className='flex flex-col items-center gap-[24px]'>
                                        <Typography variant='lead' className='text-[20px] font-bold'>
                                            {t('error_on_load_try_again')}
                                        </Typography>
                                        <Button color='white' onClick={() => handleDownloadFile(formSend)}>
                                            {t('reload')}
                                        </Button>
                                    </div>
                                    :
                                    ''
                    }
                </div>
            </Modal>
        </div>
    )
}

export default ModalViewPdf
