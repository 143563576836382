import { IconProps } from './Arrow'

const DocumentIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path className={props.className ? props.className : ''} d="M2.00016 0.666748C1.0835 0.666748 0.34183 1.41675 0.34183 2.33341L0.333496 15.6667C0.333496 16.5834 1.07516 17.3334 1.99183 17.3334H12.0002C12.9168 17.3334 13.6668 16.5834 13.6668 15.6667V6.35842C13.6668 5.91675 13.4918 5.49175 13.1752 5.18341L9.15016 1.15841C8.84183 0.841748 8.41683 0.666748 7.97516 0.666748H2.00016ZM7.8335 5.66675V1.91675L12.4168 6.50008H8.66683C8.2085 6.50008 7.8335 6.12508 7.8335 5.66675Z" fill="#FDFDFD" />
        </svg >

    )
}

export default DocumentIcon
