import React, { useEffect } from 'react'
import PageLayout from '../../layouts/pagesLayouts/PageLayout'
import { Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store/store'
import { setContractId } from '../../store/service/contractSlice'
import { setCrumbs } from '../../store/service/headerSlice'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { isIterableArray } from '../../utils/utils'
import ChangePassword from './ChangePassword'
import UserAvatar from './UserAvatar'
import UserIcon from '../../images/icons/UserIcon'
import { useProfileInfoViewQuery } from '../../store/auth/authService'
import { setUserInfo } from '../../store/auth/authSlice'

const UserProfilePage = () => {

    const { t } = useTranslation()

    const { isFetching, currentData, error, refetch } = useProfileInfoViewQuery({})

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(setContractId(0))
        dispatch(setCrumbs([{
            text: t('profile.profile_title'),
            to: '#'
        }]))
    }, []);


    // for days
    const {
        handleSubmit,
        register,
        setValue,
    } = useForm<any>({
        defaultValues: {},
    })
    useEffect(() => {
        if (currentData) {
            setValue('data', currentData)
            dispatch(setUserInfo({
                user_avatar: currentData.user_avatar
            }))
        }
    }, [currentData])

    return (
        <PageLayout
            title={
                <Typography variant='h3' className='text-gray-800 text-center mb-[64px]'>
                    {t('profile.profile_title')}
                </Typography>
            }
        >
            <Helmet>
                <title>
                    {t('profile.profile_title')}
                </title>
            </Helmet>
            <div className='max-w-[1024px] mx-auto'>
                <div className="bg-white p-[32px] rounded-[16px] border-2 border-gray-200 mb-[64px]">

                    <Typography variant='lead' className='text-gray-800 font-normal text-[20px] mb-[24px]'>
                        {t('profile.contacts')}
                    </Typography>
                    <div className='grid grid-cols-3 gap-[24px]'>
                        <div className='flex flex-col gap-[8px]'>
                            <label htmlFor='last_name' className='text-[16px] text-gray-700'>
                                {t('profile.last_name')}
                            </label>
                            <input
                                id='last_name'
                                {...register(`data.last_name`)}
                                type='text'
                                readOnly
                                className={`bg-gray-50  border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                                            hover:bg-gray-50 hover:border-gray-300 
                                            focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                                `}
                            />
                        </div>
                        <div className='flex flex-col gap-[8px]'>
                            <label htmlFor='name' className='text-[16px] text-gray-700'>
                                {t('profile.name')}
                            </label>
                            <input
                                id='name'
                                {...register(`data.name`)}
                                type='text'
                                readOnly
                                className={`bg-gray-50  border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                                            hover:bg-gray-50 hover:border-gray-300 
                                            focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                                `}
                            />
                        </div>
                        <div className='flex flex-col gap-[8px]'>
                            <label htmlFor='middle_name' className='text-[16px] text-gray-700'>
                                {t('profile.middle_name')}
                            </label>
                            <input
                                id='middle_name'
                                {...register(`data.middle_name`)}
                                type='text'
                                readOnly
                                className={`bg-gray-50  border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                                            hover:bg-gray-50 hover:border-gray-300 
                                            focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                                `}
                            />
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-2 gap-[24px] mb-[64px] '>
                    <div className="bg-white p-[32px] rounded-[16px] border-2 border-gray-200 h-full">
                        <Typography variant='lead' className='text-gray-800 font-normal text-[20px] mb-[24px]'>
                            {t('profile.account')}
                        </Typography>

                        <div className='flex flex-col gap-[8px] mb-[24px]'>
                            <label htmlFor='email' className='text-[16px] text-gray-700'>
                                {t('profile.email')}
                            </label>
                            <input
                                id='email'
                                {...register(`data.email`)}
                                type='text'
                                readOnly
                                className={`bg-gray-50  border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                                            hover:bg-gray-50 hover:border-gray-300 
                                            focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                                `}
                            />
                        </div>

                        <div className='flex flex-col gap-[8px]'>
                            <label className='text-[16px] text-gray-700'>
                                {t('profile.phones')}
                            </label>
                            {
                                currentData?.phones && isIterableArray(currentData?.phones) &&
                                currentData.phones.map((_: any, key: number) => (
                                    <input
                                        key={key}
                                        {...register(`data.phones[${key}]`)}
                                        type='text'
                                        readOnly
                                        className={`mb-[8px] bg-gray-50 border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                                                hover:bg-gray-50 hover:border-gray-300 
                                                focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                                    `}
                                    />
                                ))
                            }
                        </div>
                    </div>
                    <div className="bg-white p-[32px] pb-0 rounded-[16px] border-2 border-gray-200 h-full">
                        <Typography variant='lead' className='text-gray-800 font-normal text-[20px] mb-[24px]'>
                            {t('profile.change_password')}
                        </Typography>
                        <ChangePassword />
                    </div>
                </div>


                <div className="bg-white p-[32px] rounded-[16px] border-2 border-gray-200 mb-[64px]">

                    <Typography variant='lead' className='text-gray-800 font-normal text-[20px] mb-[24px]'>
                        {t('profile.profile_avatar')}
                    </Typography>

                    <UserAvatar
                        isFetching={isFetching}
                        refetchPage={refetch}
                        user_avatar={currentData?.user_avatar || ''}
                    />
                </div>
            </div>
        </PageLayout>
    )
}

export default UserProfilePage