import { Button, Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LightBulbIcon from '../../images/icons/LightBulbIcon'
import PencilIcon from '../../images/icons/PencilIcon'
import SwitchGasIcon from '../../images/icons/SwitchGasIcon'
import { RootState } from '../../store/store'
import ItemLayout from './ItemLayout'
import ModalEditName from './ModalEditName'
import SummaryConsumpiton from './SummaryConsumpiton'
import { TypeTkoInfo } from './_types'

const ItemTkoInfo = ({ tko, handleChangeName }: { tko: TypeTkoInfo, handleChangeName: (tko: TypeTkoInfo) => void }) => {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)



    const bgService = service_id === 1 ? 'bg-orange-500' : service_id === 2 ? 'bg-blue-600' : 'bg-gray-300'

    // const [open, setOpen] = useState(false);
    // const [modalValue, setModalValue] = useState(null);




    return (
        <div>
            <div className='flex items-center gap-[24px] mb-[24px]'>
                <Button
                    color="white"
                    size='sm'
                    className={`w-[40px] h-[40px] flex items-center justify-center`}
                    onClick={() => handleChangeName(tko)}
                // onClick={(event: any) => {
                //     event.stopPropagation()
                //     handleCopy(copyText)
                // }}
                >
                    {/* <CopyIcon /> */}
                    <PencilIcon />
                </Button>
                <div className='flex flex-col gap-[4px]'>
                    <Typography variant='lead' className='text-gray-700 font-[400]'>
                        {t('consumption.tko_text_title')}
                    </Typography>
                    <Typography variant='lead' className='text-gray-800 font-medium text-[24px]'>
                        {tko.name}
                    </Typography>
                </div>
            </div>
            <div className='flex items-center gap-[24px]'>
                <div className={`${bgService} rounded-full flex items-center justify-center min-w-[40px] h-[40px] `}>
                    {
                        service_id === 1 &&
                        <LightBulbIcon />
                    }
                    {
                        service_id === 2 &&
                        <SwitchGasIcon className='h-[20px] w-[20px]' />
                    }
                </div>
                <div className='flex flex-col gap-[8px]'>
                    <div className='flex items-baseline gap-[8px]'>
                        <Typography variant='lead' className='text-gray-700 font-[400] mb-[4px]'>
                            {t('consumption.eic')}
                        </Typography>
                        <Typography variant='lead' className='text-gray-800 font-medium'>
                            {tko.eic}
                        </Typography>
                    </div>
                    <div className='flex items-baseline gap-[8px]'>
                        <Typography variant='lead' className='text-gray-700 font-[400] mb-[4px]'>
                            {t('consumption.class')}
                        </Typography>
                        <Typography variant='lead' className='text-gray-800 font-medium'>
                            {tko.class}
                        </Typography>
                    </div>
                    <div className='flex items-baseline gap-[8px]'>
                        <Typography variant='lead' className='text-gray-700 font-[400] mb-[4px]'>
                            {t('consumption.group')}
                        </Typography>
                        <Typography variant='lead' className='text-gray-800 font-medium'>
                            {tko.group ? t(`contract_page.group_${tko.group}`) : ''}
                        </Typography>
                    </div>
                    <div className='flex items-baseline gap-[8px]'>
                        <Typography variant='lead' className='text-gray-700 font-[400] mb-[4px]'>
                            {t('consumption.osr_name')}
                        </Typography>
                        <Typography variant='lead' className='text-gray-800 font-medium'>
                            {tko.osr_name}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemTkoInfo
