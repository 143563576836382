import { IconProps } from './Arrow'

const SearchIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9162 9.66676H10.2579L10.0245 9.44176C11.0245 8.27509 11.5412 6.68343 11.2579 4.99176C10.8662 2.67509 8.93288 0.825094 6.59955 0.541761C3.07454 0.108427 0.107878 3.07509 0.541211 6.60009C0.824545 8.93343 2.67455 10.8668 4.99121 11.2584C6.68288 11.5418 8.27454 11.0251 9.44121 10.0251L9.66621 10.2584V10.9168L13.2079 14.4584C13.5495 14.8001 14.1079 14.8001 14.4495 14.4584C14.7912 14.1168 14.7912 13.5584 14.4495 13.2168L10.9162 9.66676ZM5.91621 9.66676C3.84121 9.66676 2.16621 7.99176 2.16621 5.91676C2.16621 3.84176 3.84121 2.16676 5.91621 2.16676C7.99121 2.16676 9.66621 3.84176 9.66621 5.91676C9.66621 7.99176 7.99121 9.66676 5.91621 9.66676Z" fill="#444248" />
        </svg>

    )
}

export default SearchIcon
