import { Typography } from '@material-tailwind/react'
import { Skeleton } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import SwitchElectroIcon from '../../../images/icons/SwitchElectroIcon'
import SwitchGasIcon from '../../../images/icons/SwitchGasIcon'
import { RootState } from '../../../store/store'
import { RequisitesType } from '../ContractPage.types'

const RequisitesContract = ({ data, isFetching }: { data: RequisitesType | undefined, isFetching?: boolean }) => {

    const { t } = useTranslation()
    const service_id = useSelector((state: RootState) => state.contract.service_id)


    const bgService = service_id === 1 ? 'bg-orange-50 text-orange-600' : service_id === 2 ? 'bg-blue-100 text-blue-700' : 'bg-gray-100 text-gray-500'
    const textService1 = service_id === 1 ? 'text-orange-600' : service_id === 2 ? 'text-blue-700' : 'text-gray-600'


    return (
        <>
            <Typography variant='h3' className='text-center text-gray-800 mb-[24px] tab:mb-[48px] lap:mb-[64px]'>
                {t('contract_page.requsite_title')}
            </Typography>
            <div className='flex items-center justify-center gap-[8px] text-gray-700 text-[14px] lap:text-[18px] text-center mb-[24px] lap:mb-[48px]'>
                {
                    isFetching ?
                        <Skeleton className='min-w-[150px]' />
                        :
                        <>
                            {t('contract_page.number')} <span className={`${textService1} font-bold`}>{data?.number ? data?.number : ' - '}</span>
                        </>
                }
            </div>
            <div className={`
                bg-white border-2 border-gray-100 rounded lap:rounded-[100px] p-[24px] tab:p-[16px] mb-[48px]
                grid  tab:grid-cols-2 lap:grid-cols-4 lap:flex lap:flex-row gap-[16px] lap:gap-0 justify-evenly items-start tab:items-center
            `}>
                <div className='flex flex-col items-start tab:items-center gap-[4px] '>
                    <p className='text-[14px] tab:text-[16px] text-gray-700'>
                        {t('contract_page.date')}
                    </p>
                    <p className='text-[16px] tab:text-[18px] font-medium text-gray-800'>
                        {
                            isFetching ?
                                <Skeleton className='min-w-[80px]' />
                                :
                                data?.date_start ? data?.date_start : ' - '
                        }
                    </p>
                </div>
                <div className='hidden lap:block w-[2px] h-[28px] bg-gray-100'></div>
                <div className='flex flex-col items-start tab:items-center gap-[4px]'>
                    <p className='text-[14px] tab:text-[16px] text-gray-700'>
                        {t('contract_page.status')}
                    </p>
                    <p className='text-[14px] tab:text-[18px] font-medium text-gray-800'>
                        {
                            isFetching ?
                                <Skeleton className='min-w-[80px]' />
                                :
                                data?.status ? t(`contract_page.contract_status_${data?.status}`) : ' - '
                        }

                    </p>
                </div>
                <div className='hidden lap:block w-[2px] h-[28px] bg-gray-100'></div>
                <div className='tab:hidden w-full h-[1px] bg-gray-100'></div>
                <div className='flex flex-col items-start tab:items-center gap-[4px]'>
                    <p className='text-[14px] tab:text-[16px] text-gray-700'>
                        {t('contract_page.contract_type_id')}
                    </p>
                    <p className='text-[14px] tab:text-[18px] font-medium text-gray-800'>
                        {
                            isFetching ?
                                <Skeleton className='min-w-[80px]' />
                                :
                                data?.contract_type_id ? t(`contract_page.contract_type_${data?.contract_type_id}`) : ' - '
                        }
                    </p>
                </div>
                <div className='hidden lap:block w-[2px] h-[28px] bg-gray-100'></div>
                <div className='flex flex-col items-start tab:items-center gap-[4px]'>
                    <p className='text-[14px] tab:text-[16px] text-gray-700'>
                        {t('contract_page.doc_movement')}
                    </p>
                    <p className='text-[14px] tab:text-[18px] font-medium text-gray-800'>
                        {
                            isFetching ?
                                <Skeleton className='min-w-[80px]' />
                                :
                                data?.doc_movement ? t(`contract_page.doc_movement_${data?.doc_movement}`) : ' - '
                        }
                    </p>
                </div>
            </div>
            <div className='flex flex-col tab:flex-row justify-between items-stretch lap:items-center gap-[16px] lap:gap-[24px] mb-[96px] lap:mb-[128px]'>
                <div className={`
                    flex flex-col items-center gap-[8px] lap:gap-[16px]
                    w-full h-full lap:w-[25%] rounded-[24px] p-[16px] lap:p-[24px]
                    border-2 ${service_id === 1 && 'border-orange-600'} ${service_id === 2 && 'border-blue-700'}
                    ${!service_id && 'border-gray-200'}
                `}>
                    <p className={` 
                        ${bgService}  px-[16px] py-[8px] rounded-full   
                    `}>
                        {/* break-all */}
                        {t('contract_page.supplier')}
                    </p>
                    <p className='text-gray-800 text-[18px] text-center'>
                        {
                            isFetching ?
                                <Skeleton className='min-w-[80px]' />
                                :
                                data?.supplier ? data?.supplier : ' - '
                        }
                    </p>
                </div>

                <div className={`
                        hidden lap:block h-[2px] grow
                        ${service_id === 1 && 'bg-orange-600'} ${service_id === 2 && 'bg-blue-700'} ${!service_id && 'bg-gray-200'}
                    `}
                />

                {
                    service_id === 1 &&
                    <SwitchElectroIcon className='hidden lap:block stroke-orange-600 h-[50px] w-[50px] overflow-visible ' />
                }
                {
                    service_id === 2 &&
                    <SwitchGasIcon className='hidden lap:block stroke-blue-700 h-[50px] w-[50px] overflow-visible ' />
                }

                <div className={`
                        hidden lap:block h-[2px] grow
                        ${service_id === 1 && 'bg-orange-600'} ${service_id === 2 && 'bg-blue-700'} ${!service_id && 'bg-gray-200'}
                    `}
                />

                <div className={`
                    flex flex-col items-center gap-[8px] lap:gap-[16px]
                    w-full h-full lap:w-[25%] rounded-[24px] p-[16px] lap:p-[24px]
                    border-2 ${service_id === 1 && 'border-orange-600'} ${service_id === 2 && 'border-blue-700'}
                    ${!service_id && 'border-gray-200'}
                `}>
                    <p className={` 
                        ${bgService}  px-[16px] py-[8px] rounded-full   
                    `}>
                        {/* break-all */}
                        {t('contract_page.consumer')}
                    </p>
                    <p className='text-gray-800 text-[18px] text-center'>
                        {
                            isFetching ?
                                <Skeleton className='max-w-[80px]' />
                                :
                                data?.consumer ? data?.consumer : ' - '
                        }
                    </p>
                </div>
            </div>
        </>
    )
}

export default RequisitesContract