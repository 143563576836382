import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ua from './locales/ua.json'
import ru from './locales/ru.json'

const resources = {
    ru: {
        translation: ru
    },
    ua: {
        translation: ua
    }
};

i18n
    .use(initReactI18next) 
    .init({
        resources,
        // lng: "ru",
        lng: "ua",  
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;