import { IconProps } from './Arrow'

const PlanIventIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M7.1998 4.60002H3.9998M3.9998 1.40002H0.799805" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default PlanIventIcon
