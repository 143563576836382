import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FilterSearch from '../../tableHelpers/filters/FilterSearch'
import FilterItem from '../../tableHelpers/filters/FilterItem'
import { filtersElActionTypes } from '../../tableHelpers/tableConfig'

type FilterType = { action: [] }

const InvoiceHistoryFilters = (props: any) => {

    const { t } = useTranslation()

    const { fetchPage, isLoadingFetch, onSearch } = props

    const defaultState: FilterType = {
        action: [],
    }

    const {
        control,
        getValues,
    } = useForm<FilterType>({
        defaultValues: defaultState,
    })

    const [filtersState, setFiltersState] = useState<FilterType>(defaultState);

    const handleCloseFilter = () => {
        const values = getValues()
        setFiltersState(values)
        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }

    return (
        <div className='flex justify-end items-center gap-[4px] mb-[32px]'>
            <FilterItem
                isLoadingFetch={isLoadingFetch}
                control={control}
                label={t('filters.action')}
                fieldName={'action'}
                defaultValue={defaultState.action}
                filtersData={filtersElActionTypes}
                handleCloseFilter={handleCloseFilter}
            />
            <FilterSearch
                onSearch={onSearch}
            />
        </div>
    )
}

export default InvoiceHistoryFilters
