import { Typography } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useActsViewQuery } from '../../store/auth/authService'
import { setContractId, setServiceId } from '../../store/service/contractSlice'
import { AppDispatch } from '../../store/store'
import { isIterableArray } from '../../utils/utils'
import ActsFilters from './ActsFilters'
import { ActRowType } from './ActsPage.types'
import ActTableDumb from './ActTableDumb'
import TableSortingCreator from '../tableHelpers/TableSortingCreator'
import PageLayout from '../../layouts/pagesLayouts/PageLayout'
import OverlayUnTouchable from '../../layouts/pagesLayouts/OverlayUnTouchable'
import TableLayout from '../../layouts/pagesLayouts/TableLayout'
import { Helmet } from 'react-helmet'



type FilterFormType = {
    contract_id: string | undefined,
    periodFrom?: string,
    periodTo?: string,
    type?: number[],
    balance?: number[],
    date_payment?: string[],
}

const ActsPage = () => {


    const { param_contract_id } = useParams()
    const { t } = useTranslation()

    const form: FilterFormType = {
        contract_id: param_contract_id,
        periodFrom: '',
        periodTo: '',
        type: [],
        balance: [],
        date_payment: [],
    }

    const [sendForm, setSendForm] = useState<FilterFormType>(form);
    const dispatch = useDispatch<AppDispatch>()


    const { data, error, isLoading, isFetching } = useActsViewQuery(sendForm)


    // array all rows
    const [rows, setRows] = useState<ActRowType[]>([]);

    const [tableRows, setTableRows] = useState<ActRowType[]>([]);
    const [search, setSearch] = useState<string>('');

    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(true);

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
    }, [data]);

    const setFormForFetch = (values: any) => {
        setIsLoadingTable(true)
        setTableRows([])
        setSendForm(prev => ({
            ...prev, ...{
                ...values,
                contract_id: param_contract_id
            }
        }))
    }

    useEffect(() => {
        if (
            !isLoading && !isFetching && isIterableArray(tableRows) ||
            !isLoading && !isFetching && Array.isArray(data?.rows) && !isIterableArray(data?.rows)
        ) {
            setIsLoadingTable(false)
        }
    }, [isLoading, isFetching, tableRows, data?.rows]);


    // ______CREATE_DATA
    useEffect(() => {
        if (isIterableArray(data?.rows)) {
            const res: ActRowType[] = data.rows.map((item: ActRowType) => createData(item))
            setRows(res)
        }
        else setRows([])
    }, [data]);

    useEffect(() => {
        onSearch(search)
    }, [rows]);

    const onSearch = (searchValue: string) => {
        setSearch(searchValue);
        const reg = new RegExp(searchValue.replaceAll(' ', ''), "gi");
        if (searchValue === "") {
            setTableRows(rows)
            return
        }
        if (isIterableArray(rows)) {
            const filteredData: ActRowType[] = rows.filter((item: any) => {
                const keys = Object.keys(item);
                let matches = 0;
                keys.forEach((key) => {
                    const val = String(item[key]).replaceAll(' ', '')
                    if (reg.test(val)) matches++;
                });
                return matches > 0;
            });
            if (searchValue !== "" && filteredData.length > 0) {
                setTableRows(filteredData);
            }
            if (searchValue !== "" && filteredData.length === 0) {
                setTableRows([]);
            }
        }


    };


    function createData(data: ActRowType) {
        return {
            key: data.key,
            id: data.id,
            period: data.period,
            period_sorter: data.period_sorter,
            number: data.number,
            act_type: data.act_type,
            balance_id: data.balance_id,
            act_type_table: t(`acts_page.act_type_${data.act_type}`),
            balance_id_table: t(`balance_${data.balance_id}`),
            count: data.count,
            price: data.price,
            amount_with_nds: data.amount_with_nds,
            amount_for_payment: data.amount_for_payment,

            // for buttons
            version: data.version,
            filename: data.filename,
            invoiceFilename: data.invoiceFilename
        };
    }

    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[64px]'>
                {t('acts_page.acts_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('acts_page.acts_title')}
                </title>
            </Helmet>
            <ActsFilters
                isLoadingFetch={isFetching}
                fetchPage={setFormForFetch}
                onSearch={onSearch}
            />
            <OverlayUnTouchable isOverlay={(isFetching || isLoading || !!error)}>
                {
                    error ?
                        <TableLayout>
                            <Typography variant='lead' className='text-[20px] font-medium text-center my-[40px]'>
                                {t('tables.error_on_loading')}
                            </Typography>
                        </TableLayout>
                        : <TableSortingCreator
                            isLoadingTable={isLoadingTable}
                            Component={ActTableDumb}
                            rows={tableRows}
                        />
                }
            </OverlayUnTouchable>
        </PageLayout>
    )
}

export default ActsPage
