import React from 'react'

export type IconProps = {
    className?: string,
    width?: number,
    height?: number,
}

const Arrow = (props: IconProps) => {

    return (
        <svg className={props.className ? props.className : ''} width="4" height="9" viewBox="0 0 4 9" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path className={props.className ? props.className : ''} d="M0.5 8.5L3.5 4.5L0.5 0.5" stroke="#6B7280" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default Arrow