import { IconProps } from './Arrow'

const FinalIventIcon = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M0.799805 4.39999H7.1998L5.5998 2.59999L7.1998 0.799988H0.799805V7.19999" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default FinalIventIcon
